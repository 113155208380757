import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import './style.css';
import { useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateNotificationsApi,
  getAccountSettingsApi,
} from '../../../redux/Api/index';
import {
  handleAccountSettingsLoader,
  handleAccountSettingsData,
} from '../../../redux/Slice/user';
import Loader from '../../../components/loader';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'sky-blue',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #000',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    color: '#000', //color change added here
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const EmailNotification = ({
  notificationDataObj,
  notificationsLoader,
  fetchNotificationsData,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  //Redux States
  const { accountSettingsData, accountSettingsLoader } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  //local states
  const [updateLoader, setUpdateLoader] = React.useState(false);
  const [updateError, setUpdateError] = React.useState('');
  const [updateSuccess, setUpdateSuccess] = React.useState('');
  const [notificationData, setNotificationData] =
    React.useState(notificationDataObj);

  useEffect(() => {
    setNotificationData(notificationDataObj);
  }, [notificationDataObj]);
  // Form Submit handler
  const onSubmit = async (data) => {
    let updatedObj = {
      notifications: data,
    };
    console.log('updatedObjupdatedObj', updatedObj);

    //Update Account Settings API
    await updateNotificationsApi(updatedObj)
      .then((res) => {
        if (res.status === 200) {
          setUpdateLoader(false);
          setUpdateSuccess('Updated Successfully.');
          //fetching updated Notifications data to display
          fetchNotificationsData();
        }
      })
      .catch((err) => {
        setUpdateLoader(false);
        if (err.response) {
          setUpdateError(err.response?.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      });
  };

  const fetchAccountSettingsData = async () => {
    dispatch(handleAccountSettingsLoader(true));
    try {
      await getAccountSettingsApi().then((res) => {
        dispatch(handleAccountSettingsData(res?.data));
        dispatch(handleAccountSettingsLoader(false));
      });
    } catch (err) {
      dispatch(handleAccountSettingsLoader(false));
      console.log('fetchAccountSettingsData error', err?.message);
    }
  };

  // On Change Switch updating State
  const handleSwitchChange = (event, val) => {
    if (val === 'dailyReportUpdates') {
      setNotificationData({
        ...notificationData,
        dailyReportUpdates: event.target.checked,
      });
    } else if (val === 'queueStatusUpdates') {
      setNotificationData({
        ...notificationData,
        queueStatusUpdates: event.target.checked,
      });
    } else if (val === 'postFailureUpdates') {
      setNotificationData({
        ...notificationData,
        postFailureUpdates: event.target.checked,
      });
    } else if (val === 'collaborationUpdates') {
      setNotificationData({
        ...notificationData,
        collaborationUpdates: event.target.checked,
      });
    } else if (val === 'productUpdates') {
      setNotificationData({
        ...notificationData,
        productUpdates: event.target.checked,
      });
    } else if (val === 'billingUpdates') {
      setNotificationData({
        ...notificationData,
        billingUpdates: event.target.checked,
      });
    }
    // setIsChecked(event.target.checked);
  };

  return (
    <Box className="notification-tab">
      {notificationsLoader ? (
        <Box className="loader_div">
          <Loader color="inherit" value={100} size={40} />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <Grid container marginLeft={5.5}>
            <Grid container item>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    width: '100%',
                    height: '100%',
                    marginLeft: 0,
                    padding: '10px 0px 10px 25px',
                  },
                }}
              >
                <Paper elevation={0}>
                  <Grid container marginLeft={3}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        className="notification-title"
                        marginBottom={1.5}
                        marginTop={3}
                      >
                        Email Notifications
                      </Typography>
                      <Grid marginBottom={1}>
                        <Typography
                          variant="h7"
                          className="notification-subtitle"
                          marginBottom={1}
                          marginTop={2}
                        >
                          Here you can manage your notifications by feature.
                        </Typography>
                      </Grid>
                      <Grid item xs={11.5}>
                        <Grid />
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="h6"
                        className="notification-list-title"
                        marginTop={3}
                        marginBottom={2}
                      >
                        Daily report
                      </Typography>
                      <Typography
                        variant="h7"
                        className="notification-list-subtitle"
                        marginBottom={1}
                      >
                        An overview of the activity in your Brandkaro
                        organization
                      </Typography>
                    </Grid>

                    <Grid xs={2} />
                    <Grid xs={2} className="switch_grid">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={
                              notificationData &&
                              notificationData?.dailyReportUpdates
                            }
                            onChange={(event) =>
                              handleSwitchChange(event, 'dailyReportUpdates')
                            }
                            // inputProps={{ onClick: handleSwitchChange }}
                            sx={{ m: 1 }}
                            className={'Email_switches'}
                          />
                        }
                        {...register('dailyReportUpdates')}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Typography
                        variant="h6"
                        className="notification-list-title"
                        marginTop={3}
                      >
                        Queue Status Updates
                      </Typography>
                      <Typography
                        variant="h6"
                        className="notification-list-subtitle"
                        marginTop={1}
                      >
                        Send me emails about changes to the status of my queue
                        and scheduled posts. For example, when a post
                        successfully publishes or if my queue becomes empty.
                      </Typography>
                    </Grid>
                    <Grid xs={2} />
                    <Grid xs={2} className="switch_grid">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={
                              notificationData &&
                              notificationData?.queueStatusUpdates
                            }
                            onChange={(event) =>
                              handleSwitchChange(event, 'queueStatusUpdates')
                            }
                            sx={{ m: 1 }}
                            className={'Email_switches'}
                          />
                        }
                        {...register('queueStatusUpdates')}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Typography
                        variant="h6"
                        className="notification-list-title"
                        marginTop={3}
                      >
                        Post Failures
                      </Typography>
                      <Typography
                        variant="h6"
                        className="notification-list-subtitle"
                        marginTop={1}
                      >
                        Get notified by email if a post that you scheduled
                        failed to get published.
                      </Typography>
                    </Grid>

                    <Grid xs={2} />
                    <Grid xs={2} className="switch_grid">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={
                              notificationData &&
                              notificationData?.postFailureUpdates
                            }
                            onChange={(event) =>
                              handleSwitchChange(event, 'postFailureUpdates')
                            }
                            sx={{ m: 1 }}
                            className={'Email_switches'}
                          />
                        }
                        {...register('postFailureUpdates')}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Typography
                        variant="h6"
                        className="notification-list-title"
                        marginTop={3}
                      >
                        Collaboration
                      </Typography>
                      <Typography
                        variant="h6"
                        className="notification-list-subtitle"
                        marginTop={1}
                      >
                        Send me emails about contributions from team members.
                        For example, if a post is awaiting approval.
                      </Typography>
                    </Grid>

                    <Grid xs={2} />
                    <Grid xs={2} className="switch_grid">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={
                              notificationData &&
                              notificationData?.collaborationUpdates
                            }
                            onChange={(event) =>
                              handleSwitchChange(event, 'collaborationUpdates')
                            }
                            sx={{ m: 1 }}
                            className={'Email_switches'}
                          />
                        }
                        {...register('collaborationUpdates')}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Typography
                        variant="h6"
                        className="notification-list-title"
                        marginBottom={0}
                        marginTop={2}
                      >
                        Product Updates
                      </Typography>
                      <Typography
                        variant="h6"
                        className="notification-list-subtitle"
                        marginBottom={0}
                        marginTop={1}
                      >
                        Send me emails with product updates, tips, and best
                        practices.
                      </Typography>
                    </Grid>

                    <Grid xs={2} />
                    <Grid xs={2} className="switch_grid">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={
                              notificationData &&
                              notificationData?.productUpdates
                            }
                            onChange={(event) =>
                              handleSwitchChange(event, 'productUpdates')
                            }
                            sx={{ m: 1 }}
                            className={'Email_switches'}
                          />
                        }
                        {...register('productUpdates')}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Typography
                        variant="h6"
                        className="notification-list-title"
                        marginTop={2}
                      >
                        Billing
                      </Typography>
                      <Typography
                        variant="h6"
                        className="notification-list-subtitle"
                        marginTop={1}
                      >
                        Send me emails relating to billing and payment
                        reminders.
                      </Typography>
                    </Grid>

                    <Grid xs={2} />
                    <Grid xs={2} className="switch_grid">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={
                              notificationData &&
                              notificationData?.billingUpdates
                            }
                            onChange={(event) =>
                              handleSwitchChange(event, 'billingUpdates')
                            }
                            sx={{ m: 1 }}
                            className={'Email_switches'}
                          />
                        }
                        {...register('billingUpdates')}
                      />
                    </Grid>
                  </Grid>

                  <Grid className="notification-save-button">
                    <Grid marginBottom={2} marginTop={5}>
                      <p className="data_err">
                        {' '}
                        {updateError && updateError?.message}
                      </p>
                      <p className="success_Message">
                        {' '}
                        {updateSuccess && updateSuccess}
                      </p>
                      {updateLoader ? (
                        <Loader className="saveChanges_button" />
                      ) : (
                        <Button className="saveChanges_button ">
                          Save Changes
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default EmailNotification;
