import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Divider, Typography } from '@mui/material';
import * as api from '../../../../redux/Api/index';
import Loader from '../../../../components/loader';
import { useDispatch,useSelector } from "react-redux";
import {handleNotesData} from "../../../../redux/Slice/user";

export default function HandleDeleteDialog({ open, onClose,id }) {
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState('');
  const { selectedSocialAccountId } = useSelector((state) => state.user)

  const dispatch= useDispatch()
 const getNotesData=()=>{
  api.getNotes(selectedSocialAccountId).then((res)=>{
    dispatch(handleNotesData(res.data))
  })
 }
   
  const handleDeleteAccount = async () => {
    
    setDeleteLoader('true');
    const data = {
      "social_id": selectedSocialAccountId,
      "note_id": id
  }
    await api
      .deleteNotes(data)
      .then((res) => {
        if (res.status === 200) {
          getNotesData()
          setDeleteLoader(false);
          onClose()
        }
      })
      .catch((err) => {
        setDeleteLoader(false);
        if (err.response) {
          setDeleteError(err.response?.data);
        } else {
          setDeleteError({ message: 'Network Error' });
        }
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h4" fontWeight={700} p="16px 24px">
          Confirm to Delete Note
        </Typography>
        <Divider />
        <DialogContent>
          <Typography variant="h6" p={1}>
            Are you sure you want to delete the Note
          </Typography>
        </DialogContent>
        {deleteError && (
          <p className="data_err">{deleteError && deleteError?.message}</p>
        )}
        <DialogActions>
          <Button onClick={onClose} className="cancelDialogbtn">
            Cancel
          </Button>
          {deleteLoader ? (
            <Button className="deleteDialogbtn">
              <Loader color="inherit" value={100} size={25} />
            </Button>
          ) : (
            <Button
              onClick={handleDeleteAccount}
              className="deleteDialogbtn"
              autoFocus
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
