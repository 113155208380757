import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Typography, Grid, TextField } from '@mui/material';
import '../Billing.css';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Loader from '../../../../components/loader';
import * as api from '../../../../redux/Api/index';

const maxWidth = 'sm';
export default function CreditCardModal({
  modalHeading,
  content,
  onClose,
  open,
  fetchCurrentPlanDetailsData,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState('');

  const handleClose = () => {
    onClose();
    setError('');
    setIsComplete('');
    setIsProcessing('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    //card details from stripe elements
    const cardElement = elements.getElement(CardElement);

    //creating stripe payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error);
      setIsProcessing(false);
    } else {
      setError('');
      // Call your backend API to update the payment to customer and store in db
      let data = { paymentMethodId: paymentMethod.id };
      await api
        .updatePaymentMethodApi(data)
        .then((res) => {
          if (res.status === 200) {
            setIsProcessing(false);
            setIsComplete(res?.data);
            fetchCurrentPlanDetailsData();
            handleClose();
          }
        })
        .catch((err) => {
          console.log('errrrrrrrrrrrrr', err);
          setIsProcessing(false);
          if (err.response) {
            setError(err.response?.data);
          } else {
            setError({ message: 'Network Error' });
          }
        });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={maxWidth}
      >
        <DialogTitle id="alert-dialog-title" p={1}>
          <Typography variant="h5" color="#1d1d1d" fontWeight={600}>
            Enter your credit or debit card details here
          </Typography>
        </DialogTitle>
        <Divider />

        <DialogContent p={2}>
          {/* <Grid container> */}
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label htmlFor="card-element">New Card Details</label>
              <CardElement id="card-element" options={{}} />
              {error && <div className="error">{error?.message}</div>}
              {isComplete && (
                <div className="success">
                  {/* Payment method updated successfully! */}
                  {isComplete}
                </div>
              )}
            </div>

            <Grid p={2} className="btn-align">
              <Button onClick={handleClose} className="cancelBtn">
                Cancel
              </Button>
              {isProcessing ? (
                <Button className="AgreeBtn" sx={{ mr: 2, ml: 2 }}>
                  <Loader color="inherit" value={100} size={25} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="AgreeBtn"
                  sx={{ mr: 2, ml: 2 }}
                >
                  update Payment Details
                </Button>
              )}
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
