import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Divider, Typography } from '@mui/material';
import '../setting.css';
import * as api from '../../../../redux/Api/index';
import Loader from '../../../../components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setCookie, getCookie, removeCookie } from '../../../../helpers/cookie/cookie';
import {getAllSocialAccountData} from "../../../../redux/reduxThunk";

export default function HandleRemoveAccountDialog({ open, onClose }) {
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState('');
  const [deleteSuccess, setDeleteSuccess] = React.useState('');
  const createdBrandId = getCookie('created_brand_id');
  const { selectedSocialAccountId, selectedSocialAccountType,allSocialAccountData } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const handleDeleteAccount = async () => {
    let socialAccountType = '';
    if (selectedSocialAccountType === 'Facebook Page') {
      socialAccountType = 'facebookPage';
    } else if (selectedSocialAccountType === 'Facebook Group') {
      socialAccountType = 'facebookGroup';
    } else if (selectedSocialAccountType === 'Instagram') {
      socialAccountType = 'instagram';
    } else {
      socialAccountType = selectedSocialAccountType; // else part need to change after remaining social accounts also added based on names
    }

    const syncData = {
      social_id: createdBrandId,
      account: socialAccountType
    };
    setDeleteLoader('true');
    await api
      .deleteSocialAccounts(syncData)
      .then((res) => {
        if (res.status === 200) {
          setDeleteLoader(false);
          setDeleteSuccess('Account Deleted Successfully.');
          dispatch(getAllSocialAccountData(createdBrandId))
          setCookie("socialAccountCount",allSocialAccountData?.count)
        }
      })
      .catch((err) => {
        setDeleteLoader(false);
        if (err.response) {
          setDeleteError(err.response?.data);
        } else {
          setDeleteError({ message: 'Network Error' });
        }
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h4" fontWeight={700} p="16px 24px">
          Confirm to Remove Social Media Account.
        </Typography>
        <Divider />
        <DialogContent>
          <Typography variant="h6" p={1}>
            Are you sure you want to delete the Account.
            <br />
            If you delete the Account all Progress and changes will be gone.
          </Typography>
        </DialogContent>
        {deleteError && (
          <p className="data_err">{deleteError && deleteError?.message}</p>
        )}

        {deleteSuccess && (
          <p className="success_msg">{deleteSuccess && deleteSuccess}</p>
        )}
        <DialogActions>
          <Button onClick={onClose} className="cancelDialogbtn">
            Cancel
          </Button>
          {deleteLoader ? (
            <Button className="deleteDialogbtn">
              <Loader color="inherit" value={100} size={25} />
            </Button>
          ) : (
            <Button
              onClick={handleDeleteAccount}
              className="deleteDialogbtn"
              autoFocus
            >
              Remove
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
