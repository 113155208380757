import React, { useState } from 'react';
import {
  Grid,
  Avatar,
  Dialog,
} from '@mui/material';
import SocialCard from './socialCard';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  PinterestIcon,
  MyBusinessIcon,
  YoutubeIcon,
} from '../../assets';
import FacebookDialog from './facebookDialog';
import {
  handleInstagramLogin,
} from '../../helpers/handleSocialLogin/handleSocialLogin';
import { useLocation } from 'react-router-dom';

const SocialLoginComponent = () => {

  const location = useLocation();
  const [facebookSelectionDialog, setFacebookSelectionDialog] = useState();

  const openFacebookSelectionDialog = () => {
    setFacebookSelectionDialog(true);
  };

  const closefacebookSelectionDialog = () => {
    setFacebookSelectionDialog(false);
  };

  const iconStyle = {
    width: 45,
    height: 45,
  };

  const socialCardsProps = [
    {
      socialName: 'Facebook',
      socialIcon: <Avatar src={FacebookIcon} sx={iconStyle} />,
      accountType: 'Page or Group',
      action: () => {
        openFacebookSelectionDialog(location?.pathname);
      },
      title: 'Add Facebook Account',
      info: '',
    },
    {
      socialName: 'Instagram',
      socialIcon: (
        <Avatar src={InstagramIcon} sx={iconStyle} variant="square" />
      ),
      accountType: 'Business Account',
      action: () => {
        // setCookie('selectedSocialAccount',"instagram")
        handleInstagramLogin(location?.pathname);
      },
      title: 'Add Instagram Account',
      info: '',
    },
    {
      socialName: 'Linkedin',
      socialIcon: <Avatar src={LinkedinIcon} sx={iconStyle} />,
      accountType: 'Page or Profile',
      action:null,
      // action: () => {
      //   setCookie('selectedSocialAccount',"Linkedin")
      //   handleLinkedinLogin(location?.pathname);
      // },
      title: 'Add Linkedin Account',
      info: '',
    },
    {
      socialName: 'Pinterest',
      socialIcon: <Avatar src={PinterestIcon} sx={iconStyle} />,
      accountType: 'Business',
      action:null,
      // action: () => {
      //   setCookie('selectedSocialAccount',"Pinterest")
      //   handlePinterestLogin(location?.pathname);
      // },
      title: 'Add Pinterest Account',
      info: '',
    },
    {
      socialName: 'My Business',
      socialIcon: (
        <Avatar src={MyBusinessIcon} sx={iconStyle} variant="square" />
      ),
      accountType: 'Account',
      action:null,
      // action: () => {
      //   setCookie('selectedSocialAccount',"google")
      //   handleMyBusinessLogin(location?.pathname);
      // },
      title: 'Add Google Account',
      info: '',
    },
    {
      socialName: 'Youtube',
      socialIcon: <Avatar src={YoutubeIcon} sx={iconStyle} />,
      accountType: 'Profile',
      action:null,
      // action: () => {
      //   setCookie('selectedSocialAccount',"Youtube")
      //   handleYoutubeLogin(location?.pathname);
      // },
      title: 'Add Youtube Account',
      info: '',
    },
  ];

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          p: 1,
        }}
      >
        {socialCardsProps.map((cardInfo, index) => (
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            key={index}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
           
            
          >
            <SocialCard {...cardInfo} />
          </Grid>
        ))}
      </Grid>

      <Dialog
        onClose={closefacebookSelectionDialog}
        open={facebookSelectionDialog}
      >
          
          <FacebookDialog />
      </Dialog>
    </>

    //  <a href="http://localhost:8080/api/facebook/page">Facebook Login</a>
    // <Button onClick={handleFacebookLogin}> Facebook Page Login </Button>
  );
};

export default SocialLoginComponent;
