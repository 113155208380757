import { makeStyles } from '@mui/styles';

export const sidebarStyles = makeStyles({
    sidebar_header: {
        padding: "8px 24px 8px 24px",
        display: 'flex',
        justifyContent: 'space-between',
        // position: 'fixed',
        top: 0,
        zIndex: 999,
        width: '100%',
        backgroundColor: 'white',
        '@media (max-width: 767px)': {
            padding: "8px 16px 8px 16px",
        },
        boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.25)",
    },
    sidebar_main: {
        width: "250px",
        borderTop: '3px solid #F8F8F8 !important',
        backgroundColor: 'white',
        height: '100%',
        // position:'fixed',
        top: "85px",
        zIndex: 999,
        '@media (max-width: 767px)': {
            display: 'none',
            width: "0px"
        },
    },
    sidebar_grid: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    },
    avatar: {
        width: "32px",
        height: "32px",
        fontSize: '16px',
        fontWeight: '600'
    },
    sidebar_logo: {
        width: "180px",
        height: "45px",
        marginRight: "42px",

        '@media (max-width: 600px)': {
            width: "28px",
            height: "24px",
            marginRight: "8px",
        },

    },
    brand_title: {
        fontSize: "14px",
        fontWeight: '500 !important'
    },
    icon: {
        height: 20,
        width: 20,
        marginRight: "8px",
        marginTop: "8px"
    },
    drop_down_icon: {
        marginLeft: "60px !important",
        fontSize: "28px !important",
        cursor: 'pointer',
    },
    sidebar_heading: {
        fontFamily: 'Rubik,sans-serif',
        lineHeight: '1.6',
        color: '#6c6868',
        fontSize: '16px',
        fontWeight: '500'
    }
});


