import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Padding } from '@mui/icons-material';

export const useStyles = makeStyles({
  leftImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },

  logo: {
    height: '56px',
    width: '56px',
    marginTop: '32px',
    marginBottom: '20px',
    objectFit: 'fill',

  },
  input: {
    height: '50px',
    width: '400px',
    border: 'solid 1px grey',
    borderRadius: '8px',
    marginBottom: '30px',
    marginTop: '10px',
    paddingLeft: '15px',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    outlineStyle: 'none',

    '@media (min-width: 1800px)': {
      width: 700,
      height: 60,
    },
    '@media (min-width: 1600px)': {
      width: 600,
      height: 60,
    },
    '@media (max-width: 1226px)': {
      width: 400,
      height: 45,
    },
    '@media (max-width: 1000px)': {
      width: 300,
      height: 40,
    },
    '@media (max-width: 768px)': {
      width: 300,
      height: 35,
    },

  },
  OtpInput: {
    height: '50px',
    width: '400px',
    border: 'solid 1px grey',
    borderRadius: '8px',
    marginBottom: '12px',
    marginTop: '10px',
    paddingLeft: '15px',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    outlineStyle: 'none',

    '@media (min-width: 1800px)': {
      width: 700,
      height: 60,
    },
    '@media (min-width: 1600px)': {
      width: 600,
      height: 60,
    },
    '@media (max-width: 1226px)': {
      width: 400,
      height: 45,
    },
    '@media (max-width: 1000px)': {
      width: 300,
      height: 40,
    },
    '@media (max-width: 768px)': {
      width: 300,
      height: 35,
    },

  },
  input1: {
    height: '50px',
    width: '400px',
    border: 'solid 1px grey',
    borderRadius: '8px',
    marginBottom: '8px',
    marginTop: '10px',
    paddingLeft: '15px',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    outlineStyle: 'none',
    alignItems: 'center',
    '@media (min-width: 1800px)': {
      width: 500,
      height: 60,
    },
    '@media (min-width: 1600px)': {
      width: 450,
      height: 60,
    },
    '@media (max-width: 1226px)': {
      width: 350,
      height: 45,
    },
    '@media (max-width: 1000px)': {
      width: 300,
      height: 40,
    },
    '@media (max-width: 768px)': {
      width: 250,
      height: 35,
    },

  },


  submit_button: {
    height: '50px',
    width: '400px',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: 'black !important',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'capitalize !important',
    '@media (min-width: 1800px)': {
      width: 700,
      height: 60,
    },
    '@media (min-width: 1600px)': {
      width: 600,
      height: 60,
    },
    '@media (max-width: 1226px)': {
      width: 400,
      height: 45,
    },
    '@media (max-width: 1000px)': {
      width: 300,
      height: 40,
    },
    '@media (max-width: 768px)': {
      width: 300,
      height: 35,
    },

  },
  Big_sized_Btn: {
    height: '56px',
    padding: '16px 32px',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: 'black !important',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'capitalize !important',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  Medium_sized_Btn: {
    height: '48px',
    padding: '16px 32px',
    border: 'solid 1px black !important',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: 'black !important',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'capitalize !important',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  Small_sized_Btn: {
    height: '40px',
    padding: '12px 24px',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: 'black !important',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'capitalize !important',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  purple_color_Btn: {
    height: '48px',
    width: '250px',
    padding: '16px 32px',
    border: 'solid 1px #7a40ed !important',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: '#7a40ed !important',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'capitalize !important',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  Red_color_Btn: {
    height: '48px',
    padding: '16px 32px',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: '#d61a1a !important',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  Grey_color_Btn: {
    height: '48px',
    padding: '8px 16px',
    border: 'solid 1px #979797 !important',
    borderRadius: '8px !important',
    color: '#646464 !important',
    backgroundColor: 'white !important',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  Grey_small_Btn: {
    height: '40px',
    padding: '8px 16px',
    border: 'solid 1px #979797 !important',
    borderRadius: '8px !important',
    color: '#646464 !important',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  Blue_color_Btn: {
    height: '48px',
    width: '250px',
    padding: '16px 32px',
    border: 'solid 1px #131bea !important',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: '#131bea !important',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  Blue_color_disabled_Btn: {
    height: '48px',
    width: '250px',
    padding: '16px 32px',
    border: 'solid 1px #4e4e4e !important',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: '#4e4e4e !important',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },

  disabledSubmitBtn: {
    backgroundColor: '#4b4a4a !important',
    height: '48px !important',
    padding: '16px 32px !important',
    borderRadius: '8px !important',
    color: 'white !important',
    cursor: 'pointer',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    textTransform: 'capitalize !important',
  },

  phInput: {
    height: 45,
    width: 290,
    border: 'solid 1px #979797',
    borderRadius: '5px',
    marginBottom: '30px',
    // marginLeft: '25px',
    marginTop: '10px',
    '@media (max-width: 1339px)': {
      width: 200,
      height: 45,
    },
    '@media (max-width: 768px)': {
      width: 200,
      height: 45,
    },
    '@media (min-width: 1600px)': {
      width: 350,
      height: 45,
    },
  },
  otp_phInput: {
    height: 45,
    width: 306,
    border: 'solid 1px #979797',
    borderRadius: '5px',
    marginBottom: '30px',
    marginLeft: '0px',
    marginTop: '10px',
    '@media (max-width: 1339px)': {
      width: 200,
      height: 45,
    },
    '@media (max-width: 768px)': {
      width: 200,
      height: 45,
    },
    '@media (min-width: 1600px)': {
      width: 350,
      height: 45,
    },
  },
  err: {
    // marginBottom: '20px',
    fontSize: 'small',
    font: 100,
    color: 'brown',
  },
  data_err: {
    marginBottom: '20px',
    color: 'brown',
    textAlign: 'center',
    fontSize: '16px',
  },
  terms: {
    color: 'blue',
    fontSize: '12px',
    cursor: 'pointer'
  },
  leftGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#FFF',
    height: '100%',
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  rightGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#fff',
    height: '100%',
    overflowY: 'auto',
  },
  dropDown: {
    width: '100%',
  },
  stepperInput: {
    width: '100%',
    fontSize: 20,
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    '& input::placeholder': {
      fontSize: '16px',
      padding: '10px 0px 0px 0px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    mb: '10px',
    fontWeight: 800,
    ml: 64,
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    mb: '50px',
  },
});


// mui theme settings
export const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 34,
    },
    h2: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 28,
    },
    h3: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 24,
      fontWeight: 400,
    },
    h4: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 20,
      fontWeight: 400,
    },
    h5: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
    h6: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 400,
    },

    p: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
    body1: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 12,
      fontWeight: 400,
    },
    body2: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 8,
      fontWeight: 400,
    },
    body3: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 10,
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      variants: [

        {
          props: { variant: 'black' },
          style: {
            textTransform: 'none',
            border: `2px solid #000`,
            color: '#fff',
            backgroundColor: "#000",
            fontSize: 14,
            height: '40px',
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: '#000',
              boxShadow: 'none',
            },
            ":disabled": {
              backgroundColor: '#9e9e9e',
              border: `2px solid #9e9e9e`,
              color: '#fff',
            }
          },
        },
        {
          props: {
            variant: 'black',
            size: 'medium'
          },
          style: {
            fontSize: 16,
            height: '48px',
            padding: '16px 32px'
          },
        },
        {
          props: {
            variant: 'black',
            size: 'large'
          },
          style: {
            fontSize: 18,
            height: '56px',
            padding: '16px 32px'
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            textTransform: 'capitalize',
            color: '#fff',
            backgroundColor: "#1d4ed8",
            fontSize: '16px',
            height: '40px',
            padding: '8px 16px',
            borderRadius: "8px",
            fontWeight: 600,
            '&:hover': {
              backgroundColor: '#3B82F6',
              boxShadow: 'none',
              color: '#fff',
            },
            ":disabled": {
              backgroundColor: '#90caf9',
              border: `2px solid #90caf9`,
              color: '#fff',
            },
            "&:focus": {
              backgroundColor: '#93C5FD',
            }
          },
        },
        {
          props: {
            variant: 'primary',
            size: 'medium'
          },
          style: {
            fontSize: 16,
            height: '48px',
            padding: '16px 32px'
          },
        },
        {
          props: {
            variant: 'primary',
            size: 'large'
          },
          style: {
            fontSize: 18,
            height: '56px',
            padding: '16px 32px'
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'capitalize',
            color: '#0284FE',
            fontSize: '14px',
            height: '32px',
            padding: '0px',
            borderRadius: "8px",
            fontWeight: 600,
            '&:hover': {
              // backgroundColor: '#3B82F6',
              boxShadow: 'none',
              color: '#1d4ed8',
            },
            //   ":disabled": {
            //     backgroundColor: '#90caf9',
            //     border: `2px solid #90caf9`,
            //   color: '#fff',
            //   },
            //  "&:focus":{
            //     backgroundColor: '#93C5FD',
            //   }
          },
        },
        {
          props: {
            variant: 'text',
            size: 'medium'
          },
          style: {
            fontSize: 16,
            height: '48px',
            padding: '8px 16px'
          },
        },
        {
          props: {
            variant: 'text',
            size: 'large'
          },
          style: {
            fontSize: 18,
            height: '56px',
            padding: '8px 16px'
          },
        },
        {
          props: { variant: 'purple' },
          style: {
            textTransform: 'none',
            border: `2px solid #7a40ed`,
            color: '#fff',
            backgroundColor: "#7a40ed",
            fontSize: 14,
            height: '40px',
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: '#7a40ed',
              boxShadow: 'none',
            },
            ":disabled": {
              backgroundColor: '#d1c4e9',
              border: `2px solid #d1c4e9`,
              color: '#fff',
            }
          },
        },
        {
          props: {
            variant: 'purple',
            size: 'medium'
          },
          style: {
            fontSize: 16,
            height: '48px',
            padding: '16px 32px'
          },
        },
        {
          props: {
            variant: 'purple',
            size: 'large'
          },
          style: {
            fontSize: 18,
            height: '56px',
            padding: '16px 32px'
          },
        },
        {
          props: { variant: 'blackOutlined' },
          style: {
            textTransform: 'none',
            border: `2px solid #000`,
            color: '#000',
            backgroundColor: "#fff",
            fontSize: 14,
            height: '40px',
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: '#fff',
              boxShadow: 'none',
            },
            ":disabled": {
              backgroundColor: '#9e9e9e',
              border: `2px solid #9e9e9e`,
              color: '#fff',
            }
          },
        },
        {
          props: {
            variant: 'blackOutlined',
            size: 'medium'
          },
          style: {
            fontSize: 16,
            height: '48px',
            padding: '16px 32px'
          },
        },
        {
          props: {
            variant: 'blackOutlined',
            size: 'large'
          },
          style: {
            fontSize: 18,
            height: '56px',
            padding: '16px 32px'
          },
        },
        {
          props: { variant: 'redOutlined' },
          style: {
            textTransform: 'none',
            border: `2px solid #d61a1a`,
            color: '#d61a1a',
            backgroundColor: "#fff",
            fontSize: 14,
            height: '40px',
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: '#fff',
              boxShadow: 'none',
            },
            ":disabled": {
              backgroundColor: '#ffcdd2',
              border: `2px solid #ffcdd2`,
              color: '#fff',
            }
          },
        },
        {
          props: {
            variant: 'redOutlined',
            size: 'medium'
          },
          style: {
            fontSize: 16,
            height: '48px',
            padding: '16px 32px'
          },
        },
        {
          props: {
            variant: 'redOutlined',
            size: 'large'
          },
          style: {
            fontSize: 18,
            height: '56px',
            padding: '16px 32px'
          },
        },
      ],
    }
  }
})
