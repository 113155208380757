import { Avatar, Button, Divider, Grid, Switch, Typography } from '@mui/material'
import React from 'react'
import SelectDropDown from '../../../components/selectDropDown'
import './Settings.css'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import * as api from "../../../redux/Api";
import Loader from '../../../components/loader';
import { getBrandSettingData } from "../../../redux/reduxThunk";
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { buttonStyles } from '../../../Themes/buttonTheme';

function Settings() {
    const { brandSettingData, brandId } = useSelector((state) => state.user);
    const [languageList, setLanguageList] = React.useState([]);
    const [timeZoneList, setTimeZoneList] = React.useState([]);
    const [radioValue, setRadioValue] = React.useState({
        calendarView: brandSettingData?.settings?.calendarView,
        dateFormat: brandSettingData?.settings?.dateFormat,
        firstDayOfWeek: brandSettingData?.settings?.firstDayOfWeek,
        timeFormat: brandSettingData?.settings?.timeFormat,
    })
    const createdBrandId = getCookie('created_brand_id')
    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
    } = useForm();
    const buttons = buttonStyles()
    const timeMenuList = timeZoneList?.map((time) => time.name)
    const languageMenuList = languageList?.map((language) => language.language)
    const [updateLoader, setUpdateLoader] = React.useState(false);
    const [updateError, setUpdateError] = React.useState('');
    const [updateSuccess, setUpdateSuccess] = React.useState('');
    const dispatch = useDispatch();
    const handleChangeRadio = (e) => {
        setRadioValue({ ...radioValue, [e.target.name]: e.target.value })
    }
    const onSubmit = async (data) => {
        // updateSettings
        let updatedObj = {
            "calendarView": radioValue.calendarView,
            "dateFormat": radioValue.dateFormat,
            "firstDayOfWeek": radioValue.firstDayOfWeek,
            "timeFormat": radioValue.timeFormat,
            "language": data.languageList,
            "timeZone": data.timeZoneList,
            "brandId": createdBrandId

        };
        await api
            .updateSettings(updatedObj)
            .then((res) => {
                if (res.status === 200) {
                    setUpdateLoader(false);
                    setUpdateSuccess('Brand Settings Updated Successfully.');
                    dispatch(getBrandSettingData(createdBrandId));
                }
            })
            .catch((err) => {
                setUpdateLoader(false);
                if (err.response) {
                    setUpdateError(err.response?.data);
                } else {
                    setUpdateError({ message: 'Network Error' });
                }
            });
    };
    // Fetching Category List api
    const dataFetchedRef = React.useRef(false);
    const getLanguageData = async () => {
        await api.getBusinessCategory('language_settings').then((res) => {
            setLanguageList(res.data);
        });
    };
    const getTimezonesData = async () => {
        await api.getBusinessCategory('timezones').then((res) => {
            setTimeZoneList(res.data);
        });
    };
    React.useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        // category list api
        getLanguageData();
        getTimezonesData()
    }, []);
    return (<>
        <form onSubmit={handleSubmit(onSubmit)} >
            <Grid sx={{
                p: { xs: '16px 8px', md: '24px' },
                ml: { xs: '16px' },
                mr: { xs: '16px' },
                mt: '16px',
                mb: '16px',
                // width: { xs: '100%', sm: '100%', md: '80%', lg: '100%' },
            }}
                fullWidth
                bgcolor="#ffffff"
                borderRadius="16px">
                <Grid container display="flex" justifyContent="space-around" columns={12} p={4}>
                    {/******** Left side grid ********/}
                    <Grid lg={6} >
                        <Grid alignContent="center" sx={{ mb: "24px" }}>
                            {/* <Typography display="flex" variant="h6" className="Setting_label" >
                                Language
                            </Typography> */}
                            <label className='label-style'> Language</label><br />
                            <SelectDropDown
                                Placeholder="English"
                                name="languageList"
                                className="settings_dropdown"
                                control={control}
                                defaultValue={brandSettingData?.settings?.language}
                                menuList={languageMenuList}
                                {...register('languageList')}
                                onChange={handleChangeRadio}
                            />
                        </Grid>
                        <Grid mb="32px">

                            <FormControl>
                                {/* <Typography display="flex" variant="h6" color='#484444'>Time Format </Typography> */}
                                <label className='label-style'> Time Format</label><br />
                                <RadioGroup
                                    sx={{ marginTop: "-12px" }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="timeFormat"
                                    defaultValue={brandSettingData?.settings?.timeFormat == 12 ? "12" : "24"}
                                    {...register('timeFormat')}
                                    onChange={handleChangeRadio}
                                >
                                    <FormControlLabel value="12" control={<Radio />} label={<Typography variant='p' color='#818181'>12 hour</Typography>} />
                                    <FormControlLabel value="24" control={<Radio />} label={<Typography variant='p' color='#818181'>24 hour</Typography>} />
                                </RadioGroup>
                            </FormControl>

                        </Grid>
                        <Grid>
                            <FormControl>
                                {/* <Typography display="flex" variant="h6" color='#484444'>First Day of Week </Typography> */}
                                <label className='label-style'>First Day of Week </label><br />
                                <RadioGroup
                                    sx={{ marginTop: "-12px" }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="firstDayOfWeek"
                                    defaultValue={brandSettingData?.settings?.firstDayOfWeek == "Sunday" ? "Sunday" : "Monday"}
                                    {...register('firstDayOfWeek')}
                                    onChange={handleChangeRadio}
                                >
                                    <FormControlLabel value="Sunday" control={<Radio />} label={<Typography variant='p' color='#818181'>Sunday</Typography>} />
                                    <FormControlLabel value="Monday" control={<Radio />} label={<Typography variant='p' color='#818181'>Monday</Typography>} />
                                </RadioGroup>
                            </FormControl>


                        </Grid>
                    </Grid>

                    {/******** Right side grid ********/}
                    <Grid lg={6}>

                        <Grid alignContent="center" sx={{ mb: "24px" }}>
                            {/* <Typography
                                display="flex"
                                variant="h6"
                                className="Setting_label"

                            >
                                Time Zone
                            </Typography> */}
                            <label className='label-style'> Time Zone </label><br />
                            <SelectDropDown
                                Placeholder="(GMT+5.5) Asia/Calcutta - IST"
                                className="settings_dropdown"
                                name="timeZoneList"
                                control={control}
                                menuList={timeMenuList}
                                defaultValue={brandSettingData?.settings?.timeZone}
                                {...register('timeZoneList')}
                                onChange={handleChangeRadio}

                            />
                        </Grid>
                        <Grid >
                            <Grid mb="32px">
                                <FormControl>
                                    <label className='label-style'> Date Format </label><br />
                                    {/* <Typography display="flex" variant="h6" color='#484444'>Date Format</Typography> */}
                                    <RadioGroup
                                        sx={{ marginTop: "-12px" }}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="dateFormat"
                                        defaultValue={brandSettingData?.settings?.dateFormat == "MM/DD/YYY" ? "MM/DD/YYY" : "DD/MM/YYYY"}
                                        {...register('dateFormat')}
                                        onChange={handleChangeRadio}
                                    >
                                        <FormControlLabel value="MM/DD/YYY" control={<Radio />} label={<Typography variant='p' color='#818181'>MM/DD/YYY</Typography>} />
                                        <FormControlLabel value="DD/MM/YYYY" control={<Radio />} label={<Typography variant='p' color='#818181'>DD/MM/YYYY</Typography>} />
                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                            <Grid>
                                <FormControl>
                                    <label className='label-style'> Default calendar view </label><br />
                                    {/* <Typography display="flex" variant="h6" color='#484444'> Default calendar view</Typography> */}
                                    <RadioGroup
                                        sx={{ marginTop: "-12px" }}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="calendarView"
                                        defaultValue={brandSettingData?.settings?.calendarView == "Month" ? "Month" : "Week"}
                                        {...register('calendarView')}
                                        onChange={handleChangeRadio}
                                    >
                                        <FormControlLabel value="Month" control={<Radio />} label={<Typography variant='p' color='#818181'>Month</Typography>} />
                                        <FormControlLabel value="Week" control={<Radio />} label={<Typography variant='p' color='#818181'>Week</Typography>} />
                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />

                <Grid p={"24px"} container display='flex'>
                    <Grid lg={12}>
                        <Typography variant="h6" fontWeight={500} fontSize="24px">
                            Media
                        </Typography>
                    </Grid>

                    <Grid lg={10} sx={{ padding: "16px 0px 16px 0px" }} display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant="h6" fontSize="16px" color='#484444'>
                            Automatically use the EXIF/IPTC
                            description as the caption for the uploaded photos
                        </Typography>
                        <Switch />
                    </Grid>
                    <Grid lg={10} sx={{ padding: "16px 0px 16px 0px" }} display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant="h6" fontSize="16px" color='#484444'>
                            Automatically use the EXIF/IPTC
                            description as the caption for the uploaded videos
                        </Typography>
                        <Switch />
                    </Grid>

                </Grid>

                {/* <Divider /> */}
                {/* <Grid p={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        Link Shortening
                    </Typography>


                    <Grid container lg={12} mb={4}>
                        <Grid lg={8} >
                            <Typography variant="h6" color='#484444'>
                                Shorten all the links you add to the dashboard, schedule for the future,
                                or just post automatically, including those in post descriptions.
                            </Typography>
                        </Grid>

                        <Grid lg={4} display='flex' alignItems='center' justifyContent='center'>
                            <Button className='Settings_btn'>
                                Select a Shortner
                            </Button>
                        </Grid>

                    </Grid>
                    <Divider />

                    <Typography variant="h6" fontWeight={600} m='16px 0px'>
                        Watermark
                    </Typography>

                    <Grid container lg={12} mb={4}>


                        <Grid lg={8}>
                            <Typography variant="h6" color='#484444'>
                                Watermarks help you protect your digital work.
                                They serve as logos that can be put on top of media.
                            </Typography>
                        </Grid>

                        <Grid lg={4} display='flex' alignItems='center' justifyContent='center'>
                            <Button className='Settings_btn'>
                                Upload your watermark
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />


                    <Typography variant="h6" fontWeight={600} m='16px 0px'>
                        Brand Approvals
                    </Typography>

                    <Grid container lg={12} mb={8} >
                        <Grid lg={8}>
                            <Typography variant="h6" color='#484444'>
                                Watermarks help you protect your digital work.
                                They serve as logos that can be put on top of media.
                            </Typography>
                        </Grid>
                        <Grid lg={4} display='flex' alignItems='center' justifyContent='center'>

                            <Button className='Settings_btn'>
                                Assign Approvals
                            </Button>
                        </Grid>
                    </Grid>
                </Grid> */}
                {updateError && (
                    <p className="data_err">{updateError && updateError?.message}</p>
                )}

                {updateSuccess && (
                    <p className="success_msg">{updateSuccess && updateSuccess}</p>
                )}
                <Grid ml={2}>
                    {updateLoader ? (
                        <button className="mail_btn">
                            <Loader color="inherit" value={100} size={25} />
                        </button>
                    ) : (
                        <Button type="submit" variant="contained" className={buttons.Blue_color_Btn}>
                            UPDATE SETTINGS
                        </Button>
                    )}

                </Grid>

            </Grid>
        </form>
    </>
    )
}

export default Settings