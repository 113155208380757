import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Typography, Grid, TextField } from '@mui/material';
import '../Billing.css';
import { useForm } from 'react-hook-form';
import CreditCardFields from '../creditCardFields/CreditCardFields';
import * as api from '../../../../redux/Api/index';
import Loader from '../../../../components/loader';

const maxWidth = 'sm';
export default function CustomerInfoModal({
  onClose,
  open,
  personalData,
  isStripeUserExist,
}) {
  //form handlers
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  // state for toggling personal info and card info on same modal
  const [isPersonalInfo, setIsPersonalInfo] = useState(!isStripeUserExist);
  //Loader,Error & Success MSg States
  const [userLoader, setUserLoader] = React.useState(false);
  const [userError, setUserErrorr] = React.useState('');
  console.log('isStripeUserExist', isStripeUserExist, isPersonalInfo);
  // Form on Submit handler
  const onSubmit = async (data) => {
    setUserLoader(true);
    console.log('dataaaaa', data);
    let userObj = {
      emailId: data.emailId,
      name: data.name,
      phone: data.phone,
      address: {
        line1: data.address,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        country: data.country,
      },
    };
    console.log('dataaaaa userObj', userObj);
    // Create Stripe User API
    await api
      .createStripeUserApi(userObj)
      .then((res) => {
        if (res.status === 200) {
          setUserLoader(false);
          setIsPersonalInfo(false);
        }
      })
      .catch((err) => {
        setUserLoader(false);
        if (err.response) {
          setUserErrorr(err.response?.data);
        } else {
          setUserErrorr({ message: 'Network Error' });
        }
      });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={maxWidth}
      >
        {console.log('isStripeUserExist return', isPersonalInfo)}
        {isPersonalInfo ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="alert-dialog-title" p={1}>
              <Typography variant="h5" color="#1d1d1d" fontWeight={600}>
                Billing Address
              </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent p={2}>
              <Grid container>
                <ul className="creditcard_ul">
                  <li className="creditcard_li">
                    <Typography
                      display="flex"
                      variant="h5"
                      className="creditcard_label"
                    >
                      Email
                    </Typography>
                    <TextField
                      // className="form-input"
                      placeholder="Enter email"
                      inputProps={{
                        style: {
                          fontSize: 16,
                          padding: '12px 16px',
                          width: 200,
                        },
                      }}
                      disabled
                      // disabled={isSubmitting}
                      readOnly
                      defaultValue={personalData && personalData?.emailId}
                      {...register('emailId', {
                        pattern:
                          /^[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                        required: true,
                        // onChange: (e) => handleOnChange(e),
                      })}
                      error={!!errors.emailId}
                    />
                    <input
                      type="hidden"
                      defaultValue={personalData && personalData?.emailId}
                      {...register('emailId')}
                    />
                  </li>
                  <li>
                    <Typography
                      display="flex"
                      variant="h5"
                      className="creditcard_label"
                    >
                      Name
                    </Typography>
                    <TextField
                      // className="form-input"
                      inputProps={{
                        style: {
                          fontSize: 16,
                          padding: '12px 16px',
                          width: 200,
                        },
                      }}
                      defaultValue={
                        personalData &&
                        `${personalData?.firstName} ${personalData?.lastName}`
                      }
                      placeholder="Eg:Tata motors"
                      disabled
                      // disabled={isSubmitting}
                      readOnly
                      {...register('name', {
                        required: true,
                        maxLength: 80,
                      })}
                      error={!!errors.name}
                    />
                    <input
                      type="hidden"
                      defaultValue={
                        personalData &&
                        `${personalData?.firstName} ${personalData?.lastName}`
                      }
                      {...register('name')}
                    />
                  </li>
                </ul>
                <ul className="creditcard_ul">
                  <li className="creditcard_li">
                    <Typography
                      display="flex"
                      variant="h5"
                      className="creditcard_label"
                    >
                      Mobile Number
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          fontSize: 16,
                          padding: '12px 12px',
                          width: 200,
                        },
                      }}
                      placeholder="Mobile Number"
                      disabled
                      // disabled={isSubmitting}
                      readOnly
                      defaultValue={
                        personalData &&
                        `+${personalData?.countryCode}${personalData?.phoneNumber}`
                      }
                      {...register('phone', {
                        required: true,
                      })}
                      error={!!errors.phone}
                    />
                    <input
                      type="hidden"
                      defaultValue={
                        personalData &&
                        `+${personalData?.countryCode}${personalData?.phoneNumber}`
                      }
                      {...register('phone')}
                    />
                  </li>
                  <li>
                    <Typography
                      display="flex"
                      variant="h5"
                      className="creditcard_label"
                    >
                      Address
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          fontSize: 16,
                          padding: '12px 16px',
                          width: 200,
                        },
                      }}
                      placeholder="e.g., street, PO Box, or company name"
                      {...register('address', {
                        required: true,
                        maxLength: 80,
                      })}
                      error={!!errors.address}
                    />
                  </li>
                </ul>
                <ul className="creditcard_ul">
                  <li className="creditcard_li">
                    <Typography
                      display="flex"
                      variant="h5"
                      className="creditcard_label"
                    >
                      City
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          fontSize: 16,
                          padding: '12px 16px',
                          width: 200,
                        },
                      }}
                      placeholder="City Name"
                      {...register('city', {
                        required: true,
                        maxLength: 30,
                      })}
                      error={!!errors.city}
                    />
                  </li>
                  <li>
                    <Typography
                      display="flex"
                      variant="h5"
                      className="creditcard_label"
                    >
                      State
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          fontSize: 16,
                          padding: '12px 16px',
                          width: 200,
                        },
                      }}
                      placeholder="state"
                      {...register('state', {
                        required: true,
                        maxLength: 30,
                      })}
                      error={!!errors.state}
                    />
                  </li>
                </ul>
                <ul className="creditcard_ul">
                  <li className="creditcard_li">
                    <Typography
                      display="flex"
                      variant="h5"
                      className="creditcard_label"
                    >
                      Country
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          fontSize: 16,
                          padding: '12px 16px',
                          width: 200,
                        },
                      }}
                      placeholder="Country"
                      {...register('country', {
                        required: true,
                        maxLength: 30,
                      })}
                      error={!!errors.country}
                    />
                  </li>
                  <li>
                    <Typography
                      display="flex"
                      variant="h5"
                      className="creditcard_label"
                    >
                      Postal Code
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          fontSize: 16,
                          padding: '12px 16px',
                          width: 200,
                        },
                      }}
                      placeholder="Postal Code"
                      {...register('postal_code', {
                        required: true,
                        maxLength: 20,
                      })}
                      error={!!errors.postal_code}
                    />
                  </li>
                </ul>
              </Grid>
            </DialogContent>
            {userError && (
              <p className="data_err">{userError && userError?.message}</p>
            )}

            <DialogActions>
              <Grid p={2}>
                <Button onClick={onClose} className="cancelBtn">
                  Cancel
                </Button>
                {userLoader ? (
                  <Button
                    className="AgreeBtn"
                    sx={{ mr: 2, ml: 2, width: 110, height: 45 }}
                  >
                    <Loader color="inherit" value={100} size={25} />
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="AgreeBtn"
                    sx={{ mr: 2, ml: 2 }}
                  >
                    Continue
                  </Button>
                )}
              </Grid>
            </DialogActions>
          </form>
        ) : (
          //   <form onSubmit={handleSubmit(onSubmit)}>
          <CreditCardFields />
          //   </form>
        )}
      </Dialog>
    </>
  );
}
