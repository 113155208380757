import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import './style.css';
import SideBar from '../../components/sideBar/header';
import LeftBar from '../../components/sideBar';
import Member from './Member';
import Navigation from '../index';

function index() {
  const drawerWidth = 300;
  return (
    <>
      <Navigation title={'Brand Members'} />
    </>
  );
}

export default index;
