import Cookies from 'js-cookie';

const cookieDomain= window.location.hostname === 'localhost' ? 'localhost' : '.socialxperia.ai';
// Set a value in a cookie
export function setCookie(name, value) {
  Cookies.set(name, value, { domain:cookieDomain });
}

// Get a value from a cookie
export function getCookie(name) {
  return Cookies.get(name,{ domain:cookieDomain });
}

// Remove value from a cookie
export function removeCookie(name) {
  return Cookies.remove(name,{ domain:cookieDomain});
}
