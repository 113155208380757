import React,{useEffect} from 'react'
import { Grid } from '@mui/material';
import Navigation from "../index";

function SocialAccountSettings() {
  return (<>
   <Navigation title={"Social Account Settings"} />
  </>
  )
}

export default SocialAccountSettings