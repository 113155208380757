import React from 'react'
import Navigation from "../index";

function BrandSetting() {
    return (<>
     <Navigation title={"Brand Settings"} />
    </>
    )
}

export default BrandSetting