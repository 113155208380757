import { configureStore } from "@reduxjs/toolkit";
import userReduser from './Slice/user'
import { createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';


const middleware = [
  // Add the serializable state middleware
  createSerializableStateInvariantMiddleware({
    // Optionally provide a list of paths to ignore
    ignoredActions: ['Brand/social-accounts/pending']
  })
];
export const store = configureStore({
  reducer: {
    user: userReduser,
    middleware
  }
});

// we can add number of reducer based on the requirement

// const {count } = useEffect((state) => state.counter);
// const count = useSelector((state) => state.counter.count)

// calling actions

// const dispatch = useDispatch();
// dispatch(increment())
