import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './SocialSettingStyles.css';
import Settings from './settings';
// import PostingSchedule from './postingSchedule';
// import UrlSettings from './urlSettings';
import Notes from './notes';
import { Avatar, Grid } from '@mui/material';
import { setCookie, getCookie, removeCookie } from '../../helpers/cookie/cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleNotesData,
  handleSelectedSocialAccountId,
  handleSelectedSocialAccountType,
  handleToken,
} from '../../redux/Slice/user';
import { useNavigate } from 'react-router-dom';
import * as api from '../../redux/Api';
import {
  getSocialAccountsSettingsData,
} from '../../redux/reduxThunk';
import Loader from '../../components/loader';
import NestedAvatar from '../../components/nestedAvatar';
import {
  add, LinkedinIcon,
  YoutubeIcon,
  InstagramIcon,
  MyBusinessIcon,
  PinterestIcon,
  FacebookIcon,
} from '../../assets';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// console.log("userPlan",myObjectCookie);

function SocialAccountSettingTabs() {
  const navigate = useNavigate();
  //local states
  const [socailAccounts, setSocialAccounts] = useState([]);
  const [value, setValue] = React.useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [userplanStatus, setUserPlanStatus] = useState(false);

  //redux states
  const dispatch = useDispatch();
  const {
    allBrandData,
    userData,
    allSocialAccountData,
    currentPlanData,
    socialAccountSettingsData,
    selectedSocialAccountId,
    allSocialAccountLoading,
  } = useSelector((state) => state.user);
  // const brandId = allBrandData?.map((brandId)=>brandId._id).join("").replace(/"/g, "")
  const userPlan = getCookie('userPlanDetails');
  const socialAccountCount = getCookie('socialAccountCount')

  useEffect(() => {
    if (userPlan > socialAccountCount) {
      setUserPlanStatus(true);
    }
  }, [activeIndex, socialAccountCount]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (index, socialAccount) => {

    setActiveIndex(index);
    dispatch(handleSelectedSocialAccountId(socialAccount.id._id));
    dispatch(handleSelectedSocialAccountType(socialAccount.name));
    api.getNotes(socialAccount.id._id).then((res) => {
      dispatch(handleNotesData(res.data));
    });

    // fetching settings data based on social account
    const selected_brand_id = getCookie('created_brand_id');
    const socialAccountSettingsData = {
      social_id: socialAccount.id._id,
      brand_id: selected_brand_id,
    };
    dispatch(getSocialAccountsSettingsData(socialAccountSettingsData));
  };

  const AddSocialAccounts = async () => {
    // localStorage.setItem('activeStep', 1);
    dispatch(handleToken(true));
    navigate('/upgrade-social-brand-accounts');
  };

  const upgradeSocialAccounts = () => {
    setCookie('billing_tab_value', 3);
    navigate('/account-settings');
  };

  const socialMediaData = [
    {
      name: 'facebookPage',
      icon: FacebookIcon,
      id: allSocialAccountData?.facebookPage
        ? allSocialAccountData?.facebookPage
        : null,
    },
    {
      name: 'facebookGroup',
      icon: FacebookIcon,
      id: allSocialAccountData?.facebookGroup
        ? allSocialAccountData?.facebookGroup
        : null,
    },
    {
      name: 'instagram',
      icon: InstagramIcon,
      id: allSocialAccountData?.instagram
        ? allSocialAccountData?.instagram
        : null,
    },
    {
      name: 'Pinterest',
      icon: PinterestIcon,
      id: allSocialAccountData?.pinterest
        ? allSocialAccountData?.pinterest
        : null,
    },
    {
      name: 'google',
      icon: MyBusinessIcon,
      id: allSocialAccountData?.google
        ? allSocialAccountData?.google
        : null,
    },
    {
      name: 'LinkedIn',
      icon: LinkedinIcon,
      id: allSocialAccountData?.linkedIn
        ? allSocialAccountData?.linkedIn
        : null,
    },
    {
      name: 'Youtube',
      icon: YoutubeIcon,
      id: allSocialAccountData?.youtube
        ? allSocialAccountData?.youtube
        : null,
    },
  ];

  let filterSocialMediaData =
    socialMediaData && socialMediaData?.filter((item) => item.id !== null);

  // if (
  //   selectedSocialAccountId === '' &&
  //   filterSocialMediaData &&
  //   filterSocialMediaData?.length > 0
  // ) {
  //   dispatch(handleSelectedSocialAccountId(filterSocialMediaData[0]?.id));
  // }

  useEffect(() => {
    // Loop through the object data and get the first item's id
    dispatch(handleSelectedSocialAccountId(filterSocialMediaData[0]?.id._id));
  }, [allSocialAccountData]);

  console.log(
    'firstvaluesssssssssss selectedSocialAccountId',
    selectedSocialAccountId
  );
  return (
    <>
      {allSocialAccountLoading ? (
        <Box className="loader_div">
          <Loader color="inherit" value={100} size={40} />
        </Box>
      ) : (
        <Box sx={{ p: { xs: '16px 0px', md: '24px 0px 24px 24px' } }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="social_Settings_tab"

            >
              <Tab
                className="Brand-settings-list"
                label={
                  <Typography variant="h6" fontWeight={600}>
                    Settings
                  </Typography>
                }
                {...a11yProps(0)}
              />
              {/* <Tab
                className="Brand-settings-list"
                label={
                  <Typography variant="h6" fontWeight={600}>
                    Posting Schedule
                  </Typography>
                }
                {...a11yProps(1)}
              />
              <Tab
                className="Brand-settings-list"
                label={
                  <Typography variant="h6" fontWeight={600}>
                    URL Settings
                  </Typography>
                }
                {...a11yProps(2)}
              /> */}
              <Tab
                className="Brand-settings-list"
                label={
                  <Typography variant="h6" fontWeight={600}>
                    Notes
                  </Typography>
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <Grid item className="socia-media-logos" display="flex">
            <Grid className="social-account-tab">
              {filterSocialMediaData &&
                filterSocialMediaData?.length > 0 &&
                filterSocialMediaData?.map((socialAccount, index) => (
                  <Grid sx={{ display: "flex", marginBottom: "25px" }}
                    className={`${activeIndex === index
                      ? 'social-media-image'
                      : 'social-media-image-disable'
                      }`}
                    onClick={() => handleClick(index, socialAccount)}
                    title={socialAccount?.id?.pageName}
                  >
                    <NestedAvatar
                      height={40}
                      width={40}
                      key={index}
                      bigAvatar={socialAccount?.id?.profileImage}
                      smallAvatar={socialAccount.icon}

                    />
                  </Grid>
                ))}

              {userplanStatus ? (
                <img
                  sx={{ height: '32px', width: '32px' }}
                  src={add}
                  className="social-media-image"
                  onClick={AddSocialAccounts}
                  alt=""
                />
              ) : (
                <img
                  sx={{ height: '32px', width: '32px' }}
                  src={add}
                  className="social-media-image"
                  onClick={upgradeSocialAccounts}
                  alt=""
                />
              )}
            </Grid>
            <Grid>
              <TabPanel value={value} index={0}>
                <Settings />
              </TabPanel>
              {/* <TabPanel value={value} index={1}>
                <PostingSchedule />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <UrlSettings />
              </TabPanel> */}
              <TabPanel value={value} index={1}>
                <Notes />
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default SocialAccountSettingTabs;
