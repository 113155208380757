import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonalForm from './accountInformation/personalForm';
import './AccountSettings.css';
import ChangePassword from './security';
import EmailNotification from './notifications';
import { useSelector } from 'react-redux';
import BillingAndPlans from './billingAndPlan';
import Brands from './brands';
import {
  setCookie,
  getCookie,
  removeCookie,
} from '../../helpers/cookie/cookie';
import { Grid } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="tabPanel-box">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  Value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function AccountSettings({
  accountSettingsData,
  accountSettingsLoader,
  fetchAccountSettingsData,
  notificationsData,
  notificationsLoader,
  fetchNotificationsData,
  currentPlanData,
  currentPlanLoader,
  allSocialAccountLoading,
  allSocialAccountData,
  allBrandData,
  allBrandLoading,
  invoiceData,
  invoiceLoader,
  fetchCurrentPlanDetailsData,
}) {
  // redux states
  const {
    signedUserLoader,
    signedUserData,
    userData,
    billingPlanData,
    billingPlanLoader,
  } = useSelector((state) => state.user);
  const billingValue = getCookie('billing_tab_value');

  // local states
  const [value, setValue] = useState(parseInt(billingValue));
  console.log('billingValue', value);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{}}>
        <Grid  >
          <Tabs
            orientation="horizontal"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            variant="scrollable"
            className="Acc_Settings_tab  tablePanel-class"
            sx={{
              '& .MuiTabs-root': {
                overflowX: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'gray lightgray !important',
                position: "static",

              },
            }}

          >
            <Tab
              disableRipple
              className="Acc-settings-list"
              label={

                <Typography variant="h6" fontWeight={600}>
                  Account Information
                </Typography>

              }
              {...a11yProps(0)}
            />
            <Tab
              disableRipple
              className="Acc-settings-list"
              label={

                <Typography variant="h6" fontWeight={600}>
                  Security
                </Typography>

              }
              {...a11yProps(1)}
            />

            {/* <Tab
          disableRipple
          className="Acc-settings-list"
          label={
            <Typography variant="h6" fontWeight={600}>
              Notifications{' '}
            </Typography>
          }
          {...a11yProps(2)}
         /> */}
            {userData?.accountType === 'SUPER_ADMIN' ? (
              <Tab
                disableRipple
                className="Acc-settings-list"
                label={
                  <Typography variant="h6" fontWeight={600}>
                    Brands{' '}
                  </Typography>
                }
                {...a11yProps(2)}
              />
            ) : null}

            {userData?.accountType === 'SUPER_ADMIN' ? (
              <Tab
                disableRipple
                className="Acc-settings-list"
                label={
                  <Typography variant="h6" fontWeight={600}>
                    Billing And Plans{' '}
                  </Typography>
                }
                {...a11yProps(3)}
              />

            ) : null}

          </Tabs>
        </Grid>

        <TabPanel value={value} index={0} className="tablePanel-class">
          <PersonalForm
            personalData={
              accountSettingsData &&
              accountSettingsData?.accountInformation &&
              accountSettingsData?.accountInformation
            }
            companyName={
              accountSettingsData &&
              accountSettingsData?.companyName &&
              accountSettingsData?.companyName
            }
            isVerifyEmailMobileOtp={
              accountSettingsData &&
              accountSettingsData?.verified &&
              accountSettingsData?.verified
            }
            accountSettingsLoader={accountSettingsLoader}
            fetchAccountSettingsData={fetchAccountSettingsData}
            signedUserLoader={signedUserLoader}
            signedUserData={signedUserData}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChangePassword
            securityInfo={
              accountSettingsData && accountSettingsData?.security
                ? accountSettingsData?.security
                : {}
            }
          />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
        <EmailNotification
          notificationDataObj={
            notificationsData && notificationsData?.notifications
          }
          notificationsLoader={notificationsLoader}
          fetchNotificationsData={fetchNotificationsData}
        />
      </TabPanel> */}
        <TabPanel value={value} index={2}>
          <Brands
            currentPlanData={currentPlanData}
            currentPlanLoader={currentPlanLoader}
            allSocialAccountLoading={allSocialAccountLoading}
            allSocialAccountData={allSocialAccountData}
            allBrandData={allBrandData}
            allBrandLoading={allBrandLoading}
            setValue={setValue}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <BillingAndPlans
            // currentPlanData={currentPlanData}
            // currentPlanLoader={currentPlanLoader}
            allBrandData={allBrandData}
            invoiceData={invoiceData}
            invoiceLoader={invoiceLoader}
            personalData={
              accountSettingsData &&
              accountSettingsData?.accountInformation &&
              accountSettingsData?.accountInformation
            }
            billingPlanData={billingPlanData}
            billingPlanLoader={billingPlanLoader}
            fetchCurrentPlanDetailsData={fetchCurrentPlanDetailsData}
          />
        </TabPanel>
      </Box>
    </>
  );
}

export default AccountSettings;
