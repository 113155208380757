import { makeStyles } from '@mui/styles';

export const headerStyles = makeStyles({
    h1: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 42,
    },
    h2: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 32
    },
    h3: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 28,
        fontWeight: 400,
    },
    h4: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 22,
        fontWeight: 400,
    },
    h5: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 16,
        fontWeight: 400,
    },
    h6: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 400,
    },
    p: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 12,
        fontWeight: 400,
    },
    body1: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 10,
        fontWeight: 400,
    },
    body2: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: 8,
        fontWeight: 400,
    },
    label: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        marginTop:'32px',
        fontSize: "16px",
        lineHeight: "12px",
        '@media (max-width: 458px)': {
            fontSize: "14px",
          },
    },
    require: {
        color: "red"
    },
   heading: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: "28px",
        lineHeight: "1.2",
        fontWeight: "700",
        marginBottom: "40px",
        '@media (max-width: 458px)': {
            fontSize: "24px",
          },
    },
    forgotPasswordHeading: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: "28px",
        lineHeight: "1.2",
        fontWeight: "700",
        marginBottom: "12px",
        // marginTop: "48px",
    },
    subheading: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        fontSize: "16px",
        lineHeight: "1.2",
        fontWeight: "100",
        marginBottom: "32px",
    },
    footerHeading:{
        fontFamily: 'poppins,sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: "1.6",
        marginTop: '48px',
        marginBottom:"32px",
        textAlign: 'center',
    },
    terms_privacy: {
        fontSize: '16px',
      },
    terms: {
        color: '#0284FE',
        fontSize: '12px',
        cursor: 'pointer',
        textDecoration:'none',
        fontWeight:550
      },
   

});


