import {
  Avatar,
  Button,
  Divider,
  Grid,
  Link,
  Radio,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect, useMemo } from 'react';
import TableComponent from '../../../components/tableComponent';
import mastercardlogo from '../../../assets/assets/mastercardlogo.png';
import './Billing.css';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  MyBusinessIcon,
  YoutubeIcon,
  GreetKaroIcon,
} from '../../../assets';
import { Lock } from '@mui/icons-material';
import CancelSubsDialog from './cancelSubsDialog/CancelSubsDialog';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Loader from '../../../components/loader';
import CreditCardModal from './creditCardModal/CreditCardModal';
import Switch from '@mui/material/Switch';
import CustomerInfoModal from './customerInfoModal/CustomerInfoModal';
import CustomerPriceModal from './customerPriceModal/CustomerPriceModal';
import { handlePaymentPriceModel } from '../../../redux/Slice/user';
import { useSelector, useDispatch } from 'react-redux';
import * as api from '../../../redux/Api/index';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { buttonStyles } from '../../../Themes/buttonTheme';
const stripePromise = loadStripe(
  'pk_test_51KtAkGSGnhxQu4RMghhme0y0yemkt0Nr446eeAyRhxO1CVv7ttheIMpmMbQjKsN4LNtksvnZGhQCZ3JjjVcaUkdf00dIkwd1km'
); // starts with pk_

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'sky-blue',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #000',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    color: '#000', //color change added here
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#7a40ed' : '#308fe8',
  },
}));

const Billingheading = [
  {
    Date: 'Date',
    Amount: 'Amount',
    Button: 'Invoice',
  },
];
const Billing = [
  {
    Date: 'January 9, 2023  ',
    Amount: '$0.00',
    Button: 'Download',
  },
];
const Plans = [
  {
    id: 'free',
    name: 'Free',
    cost: '0',
  },
  {
    id: 'starter',
    name: 'Starter',
    cost: '15',
  },
  {
    id: 'professional',
    name: 'Professional',
    cost: '36',
  },
  {
    id: 'team',
    name: 'Team',
    cost: '65',
  },
  {
    id: 'agency_basic',
    name: 'agency (15)',
    cost: '175',
  },
  {
    id: 'agency_pro',
    name: 'agency (30)',
    cost: '275',
  },
];

function BillingAndPlans({
  // currentPlanData,
  // currentPlanLoader,
  allBrandData,
  invoiceData,
  invoiceLoader,
  personalData,
  billingPlanData,
  billingPlanLoader,
  fetchCurrentPlanDetailsData,
}) {
  const buttons = buttonStyles();
  const dispatch = useDispatch();
  const { paymentPriceModel, currentPlanData, currentPlanLoader } = useSelector(
    (state) => state.user
  );

  const [subscribe, setSubscribe] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [card, setCard] = React.useState(false);
  const [customerForm, setCustomerForm] = React.useState(false);
  const [brandNumber, setBrandNumber] = useState(0);
  const [userNumber, setUserNumber] = useState(0);
  //   const [planPeriod, setPlanPeriod] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [selectedPriceItems, setSelectedPriceItems] = useState([]);
  const [couponId, setCouponId] = useState('');
  const [selectedCost, setSelectedCost] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [brandAddonAmount, setBrandAddonAmount] = useState(0);
  const [userAddonAmount, setUserAddonAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  //Loader,Error & Success MSg States
  const [userLoader, setUserLoader] = React.useState(false);
  const [userError, setUserError] = React.useState('');
  const [secretKey, setSecretKey] = useState('');

  useMemo(() => {
    let selectedPlanItem =
      billingPlanData &&
      billingPlanData?.plans?.find(
        (item) => item.name === currentPlanData?.planDetails?.plan
      );
    setSelectedPlan(selectedPlanItem?.name);
  }, [currentPlanData, billingPlanData]);

  const handlePlanDuration = (event) => {
    setIsYearly(!isYearly);
    let yearlyDiscount =
      billingPlanData?.planDiscount?.yearlyDiscount > 0
        ? billingPlanData?.planDiscount?.yearlyDiscount
        : 0;
    let monthlyDiscount =
      billingPlanData?.planDiscount?.monthlyDiscount > 0
        ? billingPlanData?.planDiscount?.monthlyDiscount
        : 0;
    let perBrandAddonCost =
      billingPlanData?.userAndBrandPrices?.brand?.brandPrice;
    let perUserAddonCost = billingPlanData?.userAndBrandPrices?.user?.userPrice;
    let foundPlanItem =
      billingPlanData &&
      billingPlanData?.plans?.find((item) => item.name === selectedPlan);
    // if (totalCost !== 0) {
    if (event.target.checked) {
      let priceId = foundPlanItem?.price_id_yearly;
      console.log('checkout price', priceId);
      let couponId = foundPlanItem?.coupon_id_yearly;

      let yearlyCost = Number(selectedCost) * 12;
      let discountCost = Number(yearlyCost) * (yearlyDiscount / 100);
      let brandAddonCost = Number(brandNumber) * perBrandAddonCost * 12;
      let userAddonCost = Number(userNumber) * perUserAddonCost * 12;
      let totalCostPayable =
        Number(yearlyCost) +
        brandAddonCost +
        userAddonCost -
        Number(discountCost);

      setTotalCost(yearlyCost);
      setDiscountAmount(discountCost);
      //brandAddon
      setBrandAddonAmount(brandAddonCost);
      //userAddon
      setUserAddonAmount(userAddonCost);
      //Total Payable
      setPayableAmount(totalCostPayable);

      setSelectedPriceId(priceId);
      setCouponId(couponId);
      //addons
      if (brandNumber > 0) {
        let newItems = {
          id: 'brand',
          price: billingPlanData?.userAndBrandPrices?.brand?.price_id_yearly,
          quantity: brandNumber,
        };
        updateAddonList(newItems);
      }
      if (userNumber > 0) {
        let newItems = {
          id: 'user',
          price: billingPlanData?.userAndBrandPrices?.user?.price_id_yearly,
          quantity: userNumber,
        };
        updateAddonList(newItems);
      }
    } else {
      let priceId = foundPlanItem?.price_id_monthly;
      let couponId = foundPlanItem?.coupon_id_monthly;

      let brandAddonCost = Number(brandNumber) * Number(perBrandAddonCost);
      let userAddonCost = Number(userNumber) * Number(perUserAddonCost);
      let discountCost = Number(selectedCost) * (Number(monthlyDiscount) / 100);

      let totalCostPayable =
        Number(selectedCost) +
        brandAddonCost +
        userAddonCost -
        Number(discountCost);

      setTotalCost(Number(selectedCost));
      setDiscountAmount(discountCost);
      //brandAddon
      setBrandAddonAmount(brandAddonCost);
      //userAddon
      setUserAddonAmount(userAddonCost);
      //Total Payable
      setPayableAmount(Number(totalCostPayable));

      setSelectedPriceId(priceId);
      setCouponId(couponId);
      //addons
      if (brandNumber > 0) {
        let newItems = {
          id: 'brand',
          price: billingPlanData?.userAndBrandPrices?.brand?.price_id_monthly,
          quantity: brandNumber,
        };
        updateAddonList(newItems);
      }
      if (userNumber > 0) {
        let newItems = {
          id: 'user',
          price: billingPlanData?.userAndBrandPrices?.user?.price_id_monthly,
          quantity: userNumber,
        };
        updateAddonList(newItems);
      }
    }
    // }
  };

  const handlePlanSelect = (event) => {
    console.log('eventtttttttttt val', event.target.value);
    // billingPlanData?.plans.map
    // plan
    setSelectedPlan(event.target.value);
    //cost
    const selectedOption = event.target.closest('label');
    const cost =
      selectedOption.dataset.cost || selectedOption.getAttribute('data-cost');
    setSelectedCost(cost);

    if (isYearly) {
      let foundPlanItem =
        billingPlanData &&
        billingPlanData?.plans?.find(
          (item) => item.name === event.target.value
        );
      let priceId = foundPlanItem?.price_id_yearly;
      console.log('checkout price', priceId);
      let couponId = foundPlanItem?.coupon_id_yearly;
      let yearlyCost = Number(cost) * 12;
      let yearlyDiscount =
        billingPlanData?.planDiscount?.yearlyDiscount > 0
          ? billingPlanData?.planDiscount?.yearlyDiscount
          : 0;
      let discountCost = Number(yearlyCost) * (yearlyDiscount / 100);
      let totalCostPayable =
        Number(yearlyCost) -
        Number(discountCost) +
        brandAddonAmount +
        userAddonAmount;
      setTotalCost(yearlyCost);
      setDiscountAmount(discountCost);
      setPayableAmount(totalCostPayable);
      setSelectedPriceId(priceId);
      setCouponId(couponId);
    } else {
      let foundPlanItem =
        billingPlanData &&
        billingPlanData?.plans?.find(
          (item) => item.name === event.target.value
        );
      let priceId = foundPlanItem?.price_id_monthly;
      console.log('checkout price else', priceId);
      let couponId = foundPlanItem?.coupon_id_monthly;

      let monthlyDiscount =
        billingPlanData?.planDiscount?.monthlyDiscount > 0
          ? billingPlanData?.planDiscount?.monthlyDiscount
          : 0;
      let discountCost = Number(cost) * (Number(monthlyDiscount) / 100);
      let totalCostPayable =
        Number(cost) -
        Number(discountCost) +
        brandAddonAmount +
        userAddonAmount;
      setTotalCost(Number(cost));
      setDiscountAmount(discountCost);
      setPayableAmount(totalCostPayable);
      setSelectedPriceId(priceId);
      setCouponId(couponId);
    }
  };
  console.log('selectedPriceItems', selectedPriceItems);

  const updateAddonList = (newItems) => {
    const existingIndex = selectedPriceItems.findIndex(
      (item) => item.id === newItems.id
    );
    if (existingIndex !== -1) {
      const updatedItems = [...selectedPriceItems];
      updatedItems[existingIndex].quantity = newItems.quantity;
      updatedItems[existingIndex].price = newItems.price;
      if (newItems.quantity > 0) {
        setSelectedPriceItems(updatedItems);
      } else {
        selectedPriceItems.splice(existingIndex, 1);
        setSelectedPriceItems([...selectedPriceItems]);
      }
      // setSelectedPriceItems(updatedItems);
    } else {
      setSelectedPriceItems([...selectedPriceItems, newItems]);
    }
  };

  const brandIncrement = () => {
    if (brandNumber > 6) {
      setBrandNumber(6);
    } else if (brandNumber < 6) {
      setBrandNumber(Number(brandNumber) + 1);

      let fixedBrandCost =
        billingPlanData?.userAndBrandPrices?.brand?.brandPrice;
      let brandMemCost = (Number(brandNumber) + 1) * fixedBrandCost;
      if (isYearly) {
        let brandTotalCost = Number(brandMemCost) * 12;
        setBrandAddonAmount(brandTotalCost);
        let totalCostPayable =
          totalCost + brandTotalCost + userAddonAmount - discountAmount;
        // setDiscountAmount(discountCost);
        setPayableAmount(totalCostPayable);
        let newItems = {
          id: 'brand',
          price: billingPlanData?.userAndBrandPrices?.brand?.price_id_yearly,
          quantity: Number(brandNumber) + 1,
        };
        updateAddonList(newItems);
      } else {
        let totalCostPayable =
          totalCost + brandMemCost + userAddonAmount - discountAmount;
        setBrandAddonAmount(Number(brandMemCost));
        // setDiscountAmount(discountCost);
        setPayableAmount(Number(totalCostPayable));
        let newItems = {
          id: 'brand',
          price: billingPlanData?.userAndBrandPrices?.brand?.price_id_monthly, //Change: need to change monthly price dynamic value
          quantity: Number(brandNumber) + 1,
        };
        updateAddonList(newItems);
      }
    }
  };

  const brandDecrement = () => {
    if (brandNumber <= 0) {
      setBrandNumber(0);
    } else if (brandNumber > 0) {
      setBrandNumber(Number(brandNumber) - 1);

      let fixedBrandCost =
        billingPlanData?.userAndBrandPrices?.brand?.brandPrice;
      let brandMemCost = (Number(brandNumber) - 1) * fixedBrandCost;
      if (isYearly) {
        let brandTotalCost = Number(brandMemCost) * 12;
        let totalCostPayable =
          totalCost + brandTotalCost + userAddonAmount - discountAmount;

        setBrandAddonAmount(Number(brandMemCost) * 12);
        setPayableAmount(totalCostPayable);
        let newItems = {
          id: 'brand',
          price: billingPlanData?.userAndBrandPrices?.brand?.price_id_yearly,
          quantity: Number(brandNumber) - 1,
        };
        updateAddonList(newItems);
      } else {
        let totalCostPayable =
          totalCost + brandMemCost + userAddonAmount - discountAmount;
        setBrandAddonAmount(Number(brandMemCost));
        setPayableAmount(totalCostPayable);
        let newItems = {
          id: 'brand',
          price: billingPlanData?.userAndBrandPrices?.brand?.price_id_monthly,
          quantity: Number(brandNumber) - 1,
        };
        updateAddonList(newItems);
      }
    }
  };

  const userIncrement = () => {
    if (userNumber > 6) {
      setUserNumber(6);
    } else if (userNumber < 6) {
      setUserNumber(Number(userNumber) + 1);

      let fixedUserCost = billingPlanData?.userAndBrandPrices?.user?.userPrice;
      let userAddCost = (Number(userNumber) + 1) * fixedUserCost;
      if (isYearly) {
        let userTotalCost = Number(userAddCost) * 12;
        let totalCostPayable =
          totalCost + brandAddonAmount + userTotalCost - discountAmount;

        setUserAddonAmount(userTotalCost);
        setPayableAmount(totalCostPayable);
        let newItems = {
          id: 'user',
          price: billingPlanData?.userAndBrandPrices?.user?.price_id_yearly,
          quantity: Number(userNumber) + 1,
        };
        updateAddonList(newItems);
      } else {
        let totalCostPayable =
          totalCost + brandAddonAmount + userAddCost - discountAmount;
        setUserAddonAmount(Number(userAddCost));
        setPayableAmount(totalCostPayable);
        let newItems = {
          id: 'user',
          price: billingPlanData?.userAndBrandPrices?.user?.price_id_monthly,
          quantity: Number(userNumber) + 1,
        };
        updateAddonList(newItems);
      }
    }
  };
  const userDecrement = () => {
    if (userNumber <= 0) {
      setUserNumber(0);
    } else if (userNumber > 0) {
      setUserNumber(Number(userNumber) - 1);

      let fixedUserCost = billingPlanData?.userAndBrandPrices?.user?.userPrice;
      let userAddCost = (Number(userNumber) - 1) * fixedUserCost;
      if (isYearly) {
        let userTotalCost = Number(userAddCost) * 12;
        let totalCostPayable =
          totalCost + brandAddonAmount + userTotalCost - discountAmount;

        setUserAddonAmount(userTotalCost);
        setPayableAmount(totalCostPayable);
        let newItems = {
          id: 'user',
          price: billingPlanData?.userAndBrandPrices?.user?.price_id_yearly,
          quantity: Number(userNumber) - 1,
        };
        updateAddonList(newItems);
      } else {
        let totalCostPayable =
          totalCost + brandAddonAmount + userAddCost - discountAmount;
        setUserAddonAmount(Number(userAddCost));
        setPayableAmount(totalCostPayable);
        let newItems = {
          id: 'user',
          price: billingPlanData?.userAndBrandPrices?.user?.price_id_monthly,
          quantity: Number(userNumber) - 1,
        };
        updateAddonList(newItems);
      }
    }
  };

  const onHandleCheckout = async () => {
    // Remove the 'id' property from each object in the array of selectedPriceItems
    const newSelectedPriceItems = selectedPriceItems.map(
      ({ id, ...rest }) => rest
    );
    setUserLoader(true);
    let planObj = {
      addOnPlan: newSelectedPriceItems,
      priceId: selectedPriceId,
      couponId: couponId,
    };
    await api
      .subscriptionCheckoutApi(planObj)
      // await api
      //   .createSubscriptionApi(planObj)
      .then((res) => {
        if (res.status === 200) {
          setUserLoader(false);
          // dispatch(handlePaymentPriceModel(true));
          // setSecretKey(res?.data?.clientSecret);
          window.location.replace(res?.data?.url);
        }
      })
      .catch((err) => {
        setUserLoader(false);
        if (err.response) {
          setUserError(err.response?.data);
        } else {
          setUserError({ message: 'Network Error' });
        }
      });
  };

  const customerFormOpen = () => {
    if (billingPlanData?.isStripeUserExist?.stripeCustomerAttachPayId) {
      dispatch(handlePaymentPriceModel(true));
    } else {
      setCustomerForm(true);
    }
  };
  const customerFormClose = () => {
    setCustomerForm(false);
  };

  const creditCardOpen = () => {
    setCard(true);
  };
  const handleCardClose = () => {
    setCard(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log('currentPlanData', currentPlanData);
  return (
    <>
      <Grid
        // p={4}
        bgcolor="#ffffff"
        sx={{
          ml: { xs: '16px', md: '35px' },
          mr: { xs: '16px', md: '35px' },
          p: { xs: '16px', md: '32px' },
        }}
      >
        {currentPlanLoader || billingPlanLoader || invoiceLoader ? (
          <Box className="loader_div">
            <Loader color="inherit" value={100} size={40} />
          </Box>
        ) : (
          <>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid>
                <Typography variant="h4" fontWeight={600} mb={1.5}>
                  Billing & Plan
                </Typography>
                <Typography variant="h5" color="#767676">
                  Here you can edit your plan and see your usage
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h6">
                  <a
                    href="https://socialxperia.ai/pricing/"
                    target="/"
                    // color="#131bea"
                  >
                    View Plan Details
                  </a>
                </Typography>
              </Grid>
            </Grid>

            <Grid container columns={12}>
              <Grid
                lg={7.5}
                sx={{ p: { xs: '16px 0px', md: '16px 16px 16px 0px' } }}
              >
                {/******** first box ********/}

                <Box className="Billing_box">
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    height={60}
                    padding="32px 24px"
                  
                  >
                    <Grid display="flex" alignItems="center">
                      <Typography variant="p" fontWeight={600} mr={2} sx={{mr:{xs:1.5}}}>
                        Current Plan :
                      </Typography>
                      <Box className="PlanBox">
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          textTransform="capitalize"
                        >
                          {`${currentPlanData?.planDetails?.plan} Plan`}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid>
                      <Typography variant="h5" fontWeight={600} mr={2} sx={{mr:{xs:0}}}>
                        {`$ ${currentPlanData?.currentPlan?.price} /
                                                ${currentPlanData?.currentPlan?.duration}`}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container className="box_content">
                    <Grid container mb={2}>
                      <Typography variant="h6" color="#767676" mr={1} sx={{mr:{xs:0}}}>
                        The {`${currentPlanData?.planDetails?.plan} plan`} is
                        limited to{' '}
                        {currentPlanData?.planDetails?.socialAccountLimit}{' '}
                        channels. Upgrade to the Professional plan and connect
                        as many channels as you’d like for just $5 each per
                        month.
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid sm={12} md={5} lg={6} p="0px 20px 0px 0px">
                        <Typography variant="h6" mb={1}>
                          {`${allBrandData && allBrandData?.length}/${
                            currentPlanData?.planDetails?.brandLimit
                          } Brands`}
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            (allBrandData &&
                              allBrandData?.length /
                                currentPlanData?.planDetails?.brandLimit) * 100
                          }
                        />
                      </Grid>

                      <Grid sm={12} md={5} lg={6} p="0px 0px 0px 20px">
                        <Typography variant="h6" mb={1}>
                          {`1/${currentPlanData?.planDetails?.users} Users`}
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            (1 / currentPlanData?.planDetails?.users) * 100
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      mt={4}
                      mb={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid mb={2}>
                        <Typography variant="h5" fontWeight={600}>
                          Included in your current plan:
                        </Typography>
                      </Grid>
                      <Grid display="flex" alignItems="center">
                        <Typography
                          variant="h6"
                          mr={2}
                          fontWeight={400}
                          color="#6f767e"
                        >
                          {`${currentPlanData?.planDetails?.brandLimit} Brand ( ${currentPlanData?.planDetails?.socialAccountLimit} Social Profiles )`}
                        </Typography>
                        <Box
                          sx={{
                            width: 180,
                            height: 28,
                            border: '1px solid #e6e6e6',
                            borderRadius: '19px',
                            p: '0px 8px',
                          }}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Avatar
                            sx={{ height: 20, width: 20 }}
                            src={FacebookIcon}
                          />
                          <Avatar
                            sx={{ height: 20, width: 20 }}
                            src={InstagramIcon}
                          />
                          <Avatar
                            sx={{ height: 20, width: 20 }}
                            src={TwitterIcon}
                          />
                          <Avatar
                            sx={{ height: 20, width: 20 }}
                            src={MyBusinessIcon}
                          />
                          <Avatar
                            sx={{ height: 20, width: 20 }}
                            src={YoutubeIcon}
                          />
                          <Avatar
                            sx={{ height: 20, width: 20 }}
                            src={TwitterIcon}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    {currentPlanData?.planDetails?.brandFeatures &&
                      currentPlanData?.planDetails?.brandFeatures?.length > 0 &&
                      currentPlanData?.planDetails?.brandFeatures?.map(
                        (item, index) => {
                          return (
                            <Grid container>
                              <Grid sm={12} md={5} lg={5.5}>
                                <Grid display="flex" alignItems="center">
                                  <DoneIcon
                                    sx={{
                                      color: '#fff',
                                      backgroundColor: '#4bae4f',
                                      width: '16px',
                                      height: '16px',
                                      borderRadius: '50%',
                                      mt:"7px"
                                    }}
                                  />
                                  <Typography
                                    variant="h6"
                                    ml={3}
                                    mt={1}
                                    color="#6f767e"
                                  >
                                    {item}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    {currentPlanData?.planDetails?.plan === 'free' && (
                      <Grid mt={5}>
                        <Typography variant="h6" className="red">
                          Only 3 Social accounts included per Brand in free plan
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>

                {/******** 2nd box ********/}
                {currentPlanData?.cardDetails && (
                  <Box className="Billing_box2" mt={5}>
                    <Grid
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      height={30}
                      p={3}
                    >
                      <Grid>
                        <Typography variant="h6" fontWeight={500}>
                          Payment Method
                        </Typography>
                      </Grid>
                    </Grid> 
                    <Divider />
                    <Grid className="box_content3">
                      <Grid container mb={1} alignItems="center">
                        <Avatar
                          src={mastercardlogo}
                          variant="square"
                          sx={{ height: 75, width: 100 }}
                        />
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          mr={1}
                          ml={0.5}
                        >
                          {`.... .... ${currentPlanData?.cardDetails?.number}`}
                        </Typography>
                      </Grid>

                      <Grid>
                        <Button className="Update_btn" onClick={creditCardOpen}>
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <Elements stripe={stripePromise}>
                  <CreditCardModal
                    open={card}
                    onClose={handleCardClose}
                    fetchCurrentPlanDetailsData={fetchCurrentPlanDetailsData}
                  />
                </Elements>
                {/******** last box ********/}
                <Box className="Billing_box1" mt="16px">
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    variant="h4"
                    sx={{ p: { xs: '16px', md: '24px 24px 0px 24px' } }}
                  >
                    <Typography variant="h4" fontWeight={600}>
                      Invoice & Receipts
                    </Typography>
                  </Grid>
                 
                  <Grid container className="box_content2">
                    <Grid container>
                      <TableComponent
                        Billingheading={Billingheading}
                        Billing={invoiceData}
                      />
                    </Grid>
                  </Grid>
                
                </Box>
                <Grid mt={2}>
                  <Typography variant="h6" color= "#6f767e" mb="16px">
                    For billing and invoice-related questions, contact :{' '}<br></br>
                  </Typography>
                    <a  className="link" href="mailto:example@gmail.com">
                      {' '}
                      billingteam@socialxepria.ai
                    </a>
                  <Typography mt={2} variant="h6">
                    No longer interested in SocialXperia?{' '}
                    {currentPlanData?.planDetails?.plan === 'free' ||
                    currentPlanData?.subscriptionStatus === 'canceled' ? (
                      <span className="redDisable">
                        Cancel or pause subscription
                      </span>
                    ) : (
                      <span onClick={handleOpen} className="red">
                        Cancel or pause subscription
                      </span>
                    )}
                  </Typography>
                </Grid>
                <CancelSubsDialog
                  open={open}
                  onClose={handleClose}
                  fetchCurrentPlanDetailsData={fetchCurrentPlanDetailsData}
                />
              </Grid>

              {/*******  Upgrade plan side Box ********/}

              <Grid
                lg={4.5}
                sx={{ pt:"15px"  }}
              >
                <Box className="upgradePlanBox">
                  <Grid
                    pt="24px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                   
                  >
                    <Typography variant="h3" fontWeight={600} color="#000000" >
                      Upgrade Plan
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid display="flex" alignItems="center" p="24px 8px">
                      <Typography variant="h6" fontWeight={600}>
                        Monthly
                      </Typography>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={isYearly}
                            // sx={{ m: 1 }}
                            className="two-factor-toggle-switch"
                            onChange={(event) => handlePlanDuration(event)}
                          />
                        }
                      />
                      <Typography variant="h6" fontWeight={600}>
                        Yearly
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    mb="24px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {!isYearly &&
                      billingPlanData?.planDiscount?.monthlyDiscount > 0 && (
                        <Typography color="#0284fe" variant='body3'>
                          {`Save ${billingPlanData?.planDiscount?.monthlyDiscount}% on Monthly Plans`}
                        </Typography>
                      )}
                    {isYearly &&
                      billingPlanData?.planDiscount?.yearlyDiscount > 0 && (
                        <Typography color="#0284fe" variant='body3'>
                          {`Save ${billingPlanData?.planDiscount?.yearlyDiscount}% on Yearly Plans`}
                        </Typography>
                      )}
                  </Grid>
                  
                  <Grid mb="24px">
                    <FormControl sx={{ display: 'block' }} component="fieldset">
                      <RadioGroup
                        // aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="female"
                        name="radio-buttons-group"
                        value={selectedPlan}
                        onChange={handlePlanSelect}
                      >
                        {billingPlanData &&
                          billingPlanData?.plans &&
                          billingPlanData?.plans.length > 0 &&
                          billingPlanData?.plans.map((plan, index) => {
                            return (
                              <Grid
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                p={1}
                              >
                                <Grid display="flex" alignItems="center" ml={1}>
                                  <FormControlLabel
                                    key={plan.id}
                                    value={plan.id}
                                    control={<Radio />}
                                    label={
                                      <Typography variant="h6">
                                        {plan.name}
                                      </Typography>
                                    }
                                    data-cost={plan.cost}
                                  />
                                </Grid>
                                <Grid mr={2}>
                                  <Typography variant="h6" fontWeight={600}>
                                    {/* $0 USD/month */}
                                    {`$${plan.cost} USD/month`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                      </RadioGroup>
                    </FormControl>

                    {/********   ********/}
                    <Typography variant='h4' p={2} fontWeight={600} color="#000000">Add by your own</Typography>
                    <Grid
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={2}
                    >
                      <Grid>
                        <Typography variant="h5" pb="7px" fontWeight={600}>
                          Brands
                        </Typography>
                        <Typography variant="body1" color="#6f767e">{`$${billingPlanData?.userAndBrandPrices?.brand?.brandPrice}/mo per Brand`}</Typography>
                      </Grid>
                      <Grid>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100px"
                          border="1px solid grey"
                          borderRadius={2}
                        >
                          <Button
                            disableRipple
                            onClick={brandDecrement}
                            sx={{ minWidth: 25 }}
                          >
                            <RemoveIcon
                              sx={{ fontSize: 'large', color: '#000' }}
                            />
                          </Button>
                          <Typography variant="p">{brandNumber}</Typography>
                          <Button
                            disableRipple
                            onClick={brandIncrement}
                            sx={{ minWidth: 25 }}
                          >
                            <AddIcon
                              sx={{ fontSize: 'large', color: '#000' }}
                            />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={2}
                    >
                      <Grid>
                        <Typography variant="h5" pb="7px" fontWeight={600}>
                          Users
                        </Typography>
                        <Typography variant="body1" color="#6f767e">{`$${billingPlanData?.userAndBrandPrices?.user?.userPrice}/mo per User`}</Typography>
                      </Grid>
                      <Grid>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100px"
                          border="1px solid grey"
                          borderRadius={2}
                        >
                          <Button
                            disableRipple
                            onClick={userDecrement}
                            sx={{ minWidth: 25 }}
                            // minWidth={45}
                          >
                            <RemoveIcon
                              sx={{ fontSize: 'large', color: '#000' }}
                            />
                          </Button>
                          <Typography variant="p">{userNumber}</Typography>
                          <Button
                            disableRipple
                            onClick={userIncrement}
                            sx={{ minWidth: 25 }}
                          >
                            <AddIcon
                              sx={{ fontSize: 'large', color: '#000' }}
                            />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>

                    {/********   ********/}
                    <Grid
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p="48px 15px 20px 15px"
                    >
                      <Grid display="flex" alignItems="center">
                        <Typography variant="h6" color="#6f767e">
                          Monthly Total After Trial
                        </Typography>
                      </Grid>
                      <Grid mr={2}>
                        <Typography variant="h5" fontWeight={600}>
                          {/* $175.00 */}
                          {`$${totalCost}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p="20px 15px"
                    >
                      <Grid display="flex" alignItems="center">
                        <Typography variant="h6" color="#6f767e">Free Trial Period</Typography>
                      </Grid>
                      <Grid mr={2}>
                        <Typography variant="h5" fontWeight={600} >
                          $0.00
                        </Typography>
                      </Grid>
                    </Grid>
                    {brandNumber > 0 && (
                      <Grid
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p="20px 15px"
                      >
                        <Grid display="flex" alignItems="center">
                          <Typography variant="h6" color="#6f767e">
                            Brand Addon Total
                          </Typography>
                        </Grid>
                        <Grid mr={2}>
                          <Typography variant="h5"  fontWeight={600}>
                            {`$${brandAddonAmount}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {userNumber > 0 && (
                      <Grid
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p="20px 15px"
                      >
                        <Grid display="flex" alignItems="center">
                          <Typography variant="h6" color="#6f767e">User Addon Total</Typography>
                        </Grid>
                        <Grid mr={2}>
                          <Typography variant="h5" fontWeight={600}>
                            {`$${userAddonAmount}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {discountAmount !== 0 && totalCost !== 0 && (
                      <Grid
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p="20px 15px"
                      >
                        <Grid display="flex" alignItems="center">
                          <Typography variant="h6">
                            Discount-
                            {isYearly
                              ? billingPlanData?.planDiscount?.yearlyDiscount
                              : billingPlanData?.planDiscount?.monthlyDiscount}
                            %
                          </Typography>
                        </Grid>
                        <Grid mr={2}>
                          <Typography variant="h5" fontWeight={600} >
                            {`$${parseFloat(discountAmount.toFixed(2))}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p="20px 15px"
                    >
                      <Grid display="flex" alignItems="center">
                        <Typography variant="h6" color="#6f767e">Total Amount</Typography>
                      </Grid>
                      <Grid mr={2}>
                        <Typography variant="h5" fontWeight={600}>
                          {`$${parseFloat(payableAmount.toFixed(2))}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    {console.log(
                      'selectedPlan',
                      selectedPlan,
                      currentPlanData?.planDetails?.plan
                    )}
                    <Grid p={2}>
                      <Typography variant="body1" color="#6f767e">
                        You won’t be charged for your free trial. On Mar 10,
                        2023, your monthly Advanced subscription will start and
                        you’ll be charged $175.00. Cancel anytime before then.
                      </Typography>
                    </Grid>
                    {userError && (
                      <p className="data_err">
                        {userError && userError?.message}
                      </p>
                    )}
                    <Grid container display="flex" justifyContent="center">
                      {userLoader ? (
                        <Button className="freeTrial_btn">
                          <Loader color="inherit" value={100} size={25} />
                        </Button>
                      ) : selectedPlan === currentPlanData?.planDetails?.plan ||
                        selectedPlan === 'free' ? (
                        <Button
                          className={buttons.Blue_color_disabled_Btn}
                          disabled
                      
                          sx={{width:"92%", }}
                        >
                          Upgrade Plan
                        </Button>
                      ) : (
                        <Button
                          className={buttons.Blue_color_Btn}
                          disabled
                          // onClick={customerFormOpen} // for card and account details and checkout but not req
                          onClick={onHandleCheckout}
                          p={2}
                          sx={{width:"92%"}}
                        >
                          Upgrade Plan
                        </Button>
                      )}
                    </Grid>
                    <Grid  sx={{display:"flex", justifyContent:"center", p:"43px 12px 27px 0px"}} >
                      <Lock fontSize="small" />
                      <Typography ml={0.5}  variant="body1" color="#6f767e">
                        All payments are secure and encrypted.
                      </Typography>
                    </Grid>
                    <CustomerInfoModal
                      open={customerForm}
                      onClose={customerFormClose}
                      personalData={personalData}
                      isStripeUserExist={
                        billingPlanData?.isStripeUserExist?.stripeCustomerId
                          ? true
                          : false
                      }
                      isPaymentAttached={
                        billingPlanData?.isStripeUserExist
                          ?.stripeCustomerAttachPayId
                          ? true
                          : false
                      }
                    />
                    <Elements stripe={stripePromise}>
                      <CustomerPriceModal
                        open={paymentPriceModel}
                        selectedPlan={selectedPlan}
                        isYearly={isYearly}
                        payableAmount={payableAmount}
                        secretKey={secretKey}
                      />
                    </Elements>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
export default BillingAndPlans;
