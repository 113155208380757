import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import ConformOtp from './pages/auth/conformOtp';
import ForgetPassword from './pages/auth/forgetPassword';
import SignIn from './pages/auth/signin';
import { theme } from './Themes/theme';
import { CssBaseline, Grid, ThemeProvider } from '@mui/material';
import Stepper from './pages/auth/collectData/stepper';
import UserSignUp from './pages/auth/signUp';
import AccountSettings from './pages/accountSetting/AccountSetting';
import BrandSetting from './pages/brandSetting/BrandSetting';
import Member from './pages/members';
import SocialAccountSettings from './pages/socialAccountSettings/SocialAccountSettings';
import ResetPassword from './pages/auth/forgetPassword/ResetPassword';
import { useDispatch, useSelector } from 'react-redux';
import * as api from './redux/Api';
import { handleToken } from './redux/Slice/user';
import { getUserData } from './redux/reduxThunk';
import MemberSignUp from './pages/memberAuth/SignUp';
import AddSocialChannel from './pages/auth/upgradeSocialAccount';
import TwoFactorAuth from './pages/auth/twoFactorAuth';
import NotFoundPage from './pages/auth/notFoundPage';
import SuccessPage from './pages/successPage';
import CancelPage from './pages/CancelPage';
import AccountSelection from './components/addSocials/selectSocials';
import { getCookie } from './helpers/cookie/cookie';
import Loader from './components/loader';

function App() {
  const { token, userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const cookeToken = getCookie('token');

  console.log("env", process.env.NODE_ENV)

  useEffect(() => {
    const handlePopstate = () => {
      // do nothing when back button is clicked
      window.history.pushState(null, '', window.location.href);
    };

    if (userData?.onboardingStatus === 'COMPLETED') {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handlePopstate);
    } else {
      window.removeEventListener('popstate', handlePopstate);
    }

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
  useEffect(() => {
    if (cookeToken) {
      dispatch(handleToken(true));
      dispatch(getUserData());
    }
  }, [token]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust the delay as needed
  }, []);

  if (isLoading) {
    return <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Loader color="secondary" value={50} size={50} />;
    </Grid>

  }
  const commonRoutes = () => (
    <>
      <Route exact path="/sign-in" element={<SignIn />} />
      <Route exact path="/sign-up" element={<UserSignUp />} />
      <Route exact path="/verify-otp" element={<ConformOtp />} />
      <Route exact path="/forgot-password" element={<ForgetPassword />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route exact path="/two-factor-auth" element={<TwoFactorAuth />} />
      <Route exact path="/user-sign-up/user" element={<MemberSignUp />} />
    </>
  );
  const renderRoutes = (status) => {
    switch (status) {
      case 'COMPLETED':
        return (
          <>
            {/* Routes for users with 'COMPLETED' onboardingStatus */}
            <Route exact path="/success" element={<SuccessPage />} />
            <Route exact path="/cancel" element={<CancelPage />} />
            <Route
              exact
              path="/account-settings"
              element={<AccountSettings />}
            />
            <Route exact path="/brand-settings" element={<BrandSetting />} />
            <Route exact path="/members" element={<Member />} />
            <Route
              exact
              path="/social-account-settings"
              element={<SocialAccountSettings />}
            />
            <Route
              exact
              path="/upgrade-social-brand-accounts"
              element={<AddSocialChannel />}
            />
            {commonRoutes()}
          </>
        );

      case 'WORKSPACE_REQ':
        return (
          <>
            {/* Routes for users with 'WORKSPACE_REQ' onboardingStatus */}
            <Route exact path="/social-brand-accounts" element={<Stepper />} />
          </>
        );

      default:
        return (
          <>
            {/* Default Routes for Non-Logged-In Users or Other Conditions */}
            {commonRoutes()}
          </>
        );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          {renderRoutes(userData?.onboardingStatus)}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
