import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Link,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { useLocation } from "react-router-dom";

function SocialCard(prop) {
  const { socialName, socialIcon, accountType, action, title, info } = prop;
  const { allSocialAccountData, currentPlanData, allUnselectedSocialAccountData,SocialAccountNoData,tempSocialAccounts } = useSelector(
    (state) => state.user
  );

  const userPlan = getCookie('userPlanDetails');
  const socialAccountCount = getCookie('socialAccountCount')
  const [userplanStatus, setUserPlanStatus] = useState(false);

  useEffect(() => {
    if (userPlan > socialAccountCount) {
      setUserPlanStatus(true);
    }else{
      setUserPlanStatus(false);
    }

  }, [socialAccountCount]);


  return (
    <Card
      sx={{
        width: '95%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: 'auto',
        border: '1',
        borderColor: '',
        opacity: action && userplanStatus ? '1' : '0.5'
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mb: 1 }}>{socialIcon}</Box>
        <Typography variant="h6">{socialName}</Typography>
        <Typography variant="p">{accountType}</Typography>
      </CardContent>
      <CardActions>
        {console.log("userplanStatus", action, userplanStatus)}
        {action !== null && userplanStatus && !tempSocialAccounts  ? (
          <Link component={Button} onClick={action}>
            Connect
          </Link>
        ) : (
          <Link component={Button} disabled>
            Connect
          </Link>
        )}
      </CardActions>
    </Card>
  );
}

export default SocialCard;
