import {
  Avatar,
  Box,
  Button,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../../redux/Api';
import Loader from '../../../components/loader';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { getBrandSettingData } from "../../../redux/reduxThunk";
import { buttonStyles } from '../../../Themes/buttonTheme';

function BrandInformation({ brandSettingData, brandSettingLoading }) {
  const { allBrandData } = useSelector((state) => state.user);
  const createdBrandId = getCookie('created_brand_id')
  const brandId = allBrandData?.data && allBrandData?.length > 0 ? allBrandData[0]?._id : createdBrandId
  const [imageUrl, setImageUrl] = useState(null);
  const [formData, setFormData] = useState(
    brandSettingData ? brandSettingData : {}
  );
  const [updateLoader, setUpdateLoader] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState('');
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const buttons = buttonStyles()
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    // console.log('eeeeeeeeeeeeeeee', file.size);
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        if (width >= 300 || height >= 300) {
          setError('profileImg', {
            type: 'filetype',
            message: 'Image dimensions should be less than 250px x 250px.',
          });
        } else if (file && file.size > 1000000) {
          setError('profileImg', {
            type: 'filetype',
            message: 'Image size should not exceed 1MB.',
          });
        } else {
          if (brandSettingData?.profileImg) {
            api.s3ImageDelete(brandSettingData?.profileImg)
          }
          api.s3ImageUpload({ imageurl: reader.result }).then((res) => {
            setImageUrl(res.data);
            setError('profileImg', {
              type: 'filetype',
              message: '',
            });
          });
        }
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };


  const onSubmit = async (data) => {
    let updatedObj = {
      ...data,
      logo: imageUrl,
      brandId: brandId
    };
    await api
      .updateBrandSettings(updatedObj)
      .then((res) => {
        if (res.status === 200) {
          setUpdateLoader(false);
          setUpdateSuccess('Brand Information Updated Successfully.');
          dispatch(getBrandSettingData(brandId));
        }
      })
      .catch((err) => {
        setUpdateLoader(false);
        if (err.response) {
          setUpdateError(err.response?.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      });
  };
  useMemo(() => {
    setFormData(brandSettingData ? brandSettingData : {});
    setImageUrl(brandSettingData && brandSettingData?.logo);
  }, [brandSettingData]);
  console.log(imageUrl)
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        {brandSettingLoading ? (
          <Box className="loader_div">
            <Loader color="inherit" value={100} size={40} />
          </Box>
        ) : (
          <Box sx={{
            p: { xs: '16px 8px', md: '24px' },
            ml: { xs: '16px' },
            mr: { xs: '16px' },
            mt: '16px',
            mb: '16px',
            width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          }}
            fullWidth
            bgcolor="#ffffff"
            borderRadius="16px">
            <ul className="personal_info-ul">
              <li className="brand-setting-li">
                <Grid>
                  <label className='label-style'>Brand Name</label><br />
                  <TextField
                    className="form-input-brand"
                    sx={{ width: '100%', height: 20 }}
                    defaultValue={brandSettingData && brandSettingData?.name}
                    inputProps={{
                      style: { fontSize: 16, padding: '12px 16px' },
                      maxLength: 15,
                    }}
                    {...register('name', {
                      required: true,
                      maxLength: '15',
                    })}
                    error={!!errors.brandName}
                  />
                  {errors.brandName?.type === 'required' && (
                    <p className="form_err_msg">Brand Name is required</p>
                  )}
                </Grid>
              </li>
              <li className="personal_info-li">
                <label className='logo-label'>Logo</label><br />
                <Box
                  display="flex"
                  alignItems="start"
                  justifyContent="left"
                  mb="20px"
                >
                  <Box>
                    {imageUrl ? (
                      <Avatar
                        src={imageUrl}
                        alt="logo"
                        sx={{ height: "40px ", width: "40px " }}
                      />
                    ) : (
                      <Avatar title="profile_image"
                        sx={{ height: "40px ", width: "40px ", marginTop: "8px" }} />
                    )}
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Button
                      component="label"
                      display="block"
                      className="Upload_btn"
                      sx={{ height: 32 }}

                    >
                      Upload Logo
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: 'none' }}
                        {...register('profileImg', {
                          onChange: (e) => handleLogoUpload(e),
                        })}
                      />
                    </Button>
                    <Typography variant="body" mt={0} display="flex" ml="16px">
                      Recommended picture size : 250x250px
                    </Typography>
                  </Box>
                </Box>
                {errors.profileImg && (
                  <p className="err_msg" >{errors.profileImg?.message}</p>
                )}
              </li>

            </ul>
            {/* <ul className="personal_info-ul">
              <li className="brand-setting-li"> */}
            {/* <Typography variant="h6" className="brand-title">
                  Brand  Description
                </Typography> */}
            <Box sx={{ marginTop: "32px" }}>
              <label className='label-style'>Brand  Description</label><br />
              <TextField
                multiline
                rows={6}
                sx={{ width: 400, mt: 1 }}
                inputProps={{
                  style: { fontSize: 16 },
                }}
                fullWidth
                defaultValue={brandSettingData && brandSettingData?.brandDescription}
                id="fullWidth"
                {...register('brandDescription', {
                  maxLength: 80,
                })}
              />
            </Box>
            {/* </li>
            </ul> */}
            {updateError && (
              <p className="data_err">{updateError && updateError?.message}</p>
            )}

            {updateSuccess && (
              <p className="success_msg">{updateSuccess && updateSuccess}</p>
            )}

            <Grid mt={5} >
              {updateLoader ? (
                <button className="mail_btn">
                  <Loader color="inherit" value={100} size={25} />
                </button>
              ) : (
                <Button type="submit" variant="contained" className={buttons.Blue_color_Btn}>
                  UPDATE BRAND
                </Button>
              )}
            </Grid>
          </Box>
        )}
      </form>
    </>
  );
}

export default BrandInformation;
