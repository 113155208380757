import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { setCookie, getCookie, removeCookie } from '../../helpers/cookie/cookie';
import { useSelector } from 'react-redux';
// import dotenv from 'dotenv';

// dotenv.config();


console.log("yugandhar")
let onboardUrl, scheduleUrl, editorUrl, analyticsUrl;
if (process.env.REACT_APP_NODE_ENV === 'production') {
  onboardUrl = process.env.REACT_APP_onboardUrl;
  scheduleUrl = process.env.REACT_APP_scheduleUrl;
  analyticsUrl = process.env.REACT_APP_analyticsUrl;
  editorUrl = process.env.REACT_APP_editorUrl;
} else if (process.env.REACT_APP_NODE_ENV === 'staging') {
  onboardUrl = process.env.REACT_APP_onboardUrl;
  scheduleUrl = process.env.REACT_APP_scheduleUrl;
  analyticsUrl = process.env.REACT_APP_analyticsUrl;
  editorUrl = process.env.REACT_APP_editorUrl;
} else {
  onboardUrl = process.env.REACT_APP_onboardUrl;
  scheduleUrl = process.env.REACT_APP_scheduleUrl;
  analyticsUrl = process.env.REACT_APP_analyticsUrl;
  editorUrl = process.env.REACT_APP_editorUrl;
}

export { onboardUrl, scheduleUrl, editorUrl, analyticsUrl };
const baseURL =
  process.env.NODE_ENV === 'production' ? 'https://api.socialxperia.ai/api' : 'http://localhost:8080/api';

// to fetch Client geolocation details
let ipinfoToken = 'ed1ce99956a407';
export const ClientGeoLocationUrl = `https://ipinfo.io/json?token=${ipinfoToken}`;

//RazorPay Keys
export const RAZORPAY_KEY = 'rzp_test_isJxad5vGMC9bF';
export const RAZORPAY_SECRET_KEY = 'V47SeT8luQWYUfrD34ETVx8A';

// const token = getCookie('token');
// const headers = token ? { 'token': token } : {};

// const API = axios.create({
//   baseURL,
//   headers,
// });

const API = axios.create({ baseURL });
API.interceptors.request.use(
  (config) => {
    const token = getCookie('token');
    if (token) {
      config.headers['token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
// Onboard API's Login Module
export const regestorUser = (data) => {
  const params = new URLSearchParams(window.location.search);
  const plan = params.get('plan');
  const duration = params.get('duration');
  const cta = params.get('cta');
  data['cta'] = cta;
  data['plan'] = plan ? plan : 'free';
  data['duration'] = duration ? duration : 'month';
  // return API.post('/register', data);

  return API.post('/sign-up', data);
};

// user register api
export const userRegisterUser = (data) => {
  return API.post('/userRegister', data);
};

export const loginUser = async (data) => {
  let result = '';
  API.post('/login', data)
    .then(({ data }) => {
      // doing something with success
      result = data;
      console.log("result",result)
    })
    .catch((err) => {
      result = err;
      let message =
        typeof err.response !== 'undefined'
          ? err.response.data.message
          : err.message;
      console.warn('error', message);
    });
  console.log('test error result', result);
  return result;
};

export const verifyOtp = async (data) => API.post('/verify-otp', data);

export const updateEmail = (data) => API.post('/change-email', data);

export const updatePhoneNumber = (data) =>
  API.post('/change-mobile-number', data);

export const resentEmailOtp = (data) => API.post('/resend-email-otp', data);

export const resentMobileOtp = (data) => API.post('/change-mobile-otp', data);

export const resentOtp = (data) => API.post('/resentOtp', data);

export const logIn = (data) => API.post('/sign-in', data);

export const forgotPassword = (data) => API.post('/forget-password', data);

export const resetPassword = (data) => API.post('/reset-password', data);

// conform two factor auth
export const conformTwoFactorAuth = (data) =>
  API.post('/conform-two-factor-auth', data);

// resend two factor auth
export const resendTwoFactorAuth = () => API.post('/resend-two-factor-auth');

export const brandCreationFinalStep = (data) =>
  API.post('/start-free-trail', data);
//*** Onboard API's Login Module Ends****//

// *** User Signup API's ***//
export const userRegisterApi = (data) => {
  return API.post('/user-sign-up', data);
};

//*** Account Settings API's Starts****//
export const accountSettingsApi = () => API.get(`/account-settings`);

export const updateAccountSettingsApi = (data) =>
  API.post(`/account-settings`, data);

export const updatePasswordApi = (data) => API.post('/change-password', data);

export const temporaryAccountAccessApi = (data) =>
  API.post('/temporary-account-access', data);

export const twoFactorAuthenticationApi = (data) =>
  API.post('/two-factor-auth', data);

export const getNotificationsApi = () => API.get('/notifications');

export const updateNotificationsApi = (data) =>
  API.post('/notifications', data);

export const removeAccountAPI = (data) => API.delete('/account-settings');

export const getCurrentPlanDetailsApi = () => API.get('/get-current-plan');

export const getInvoiceReceiptsApi = () => API.get('/invoice-receipts');

// export const cancelSubscriptionApi = () => API.put('/cancel-subscription');

//*** Account Settings API's Ends****//

// export const userOtherInfo = (data) => API.post('/userinfo', data, config);
export const userOtherInfo = (data) => API.post('/userinfo', data);
export const getUser = () => API.get(`/user`);

export const getBusinessCategory = (data) =>
  API.get(`/configuration?name=${data}`);

export const orderApi = (data) => API.post('/order', data);

export const paymentVerifyApi = (data) => API.post('/paymentVerify', data);

export const getAccountSettingsApi = () => API.get(`/getAccountSettings`);

export const createBrandApi = (data) => API.post('/createBrand', data);

export const getBrandsApi = (data) =>
  API.get(`/accountSettings?brandId=${data}`);

export const removeBrandsApi = (data) =>
  API.delete(`/BrandSettings?brandId=${data}`);

// member apis
export const addUsersApi = (data) => API.post(`/addUser`, data);
export const getMemeberApi = () => API.get(`/getUsers`);
export const updateUsersApi = (data) => API.post(`/updateUser`, data);

// Brand apis
export const userBrandInfo = (data) => API.post(`/create-brand`, data);
export const getBrandSettings = (id) => API.get(`/brand-settings?brand=${id}`);
export const getBrandAll = () => API.get(`/get-all-brands`);
export const updateBrandSettings = (data) =>
  API.post(`/update-brand-info?brand=${data.brandId}`, data);
export const updateSettings = (data) =>
  API.post(`/brand-settings?brand=${data.brandId}`, data);

// S3 bucket image apis
export const s3ImageUpload = (data) => API.post('/upload-image', data);
export const s3ImageDelete = (data) =>
  API.delete(`/delete-image?imageurl=${data}`);

export const verifyToken = () => API.post('/verify-token');

// social-accounts adding
export const getAllSocialAccounts = (data) =>
  API.get(`/social-accounts?brand_id=${data}`);
  
  export const getTempSocialAccounts = (data) =>
  API.get(`/temp-social-accounts?id=${data}`);
  

//get all unselected accounts
export const getAllUnselectedSocialAccounts = (data) =>
  API.get(`/get-all-social-accounts?account_type=${data}`);
//update all unselected accounts
export const updateAllUnselectedSocialAccounts = (data) =>
  API.put(`/social-accounts?account_type=${data.account_type}&brand_id=${data.brand_id}`,data);

export const deleteSocialAccounts = (data) =>
  API.delete(
    `/social-accounts?account_type=${data.account}&brand_id=${data.social_id}&email_id=${data.emailId}&pageName=${data.pageName}`
  );

//social account settings
export const getSocialAccountsSettings = (data) =>
  API.get(
    `/social-account-settings?social_id=${data.social_id}&brand_id=${data.brand_id}`
  );

export const syncPostsApi = (data) =>
  API.post(`/sync-post?social_id=${data.social_id}`, {});

export const autoSyncPostsApi = (data, autoSyncData) =>
  API.post(`/auto-sync-post?social_id=${autoSyncData.social_id}`, data);

export const deleteSchedulePostsApi = (data) =>
  API.delete(`/delete-schedule-post?social_id=${data.social_id}`);

export const deleteSocialAccountsApi = (data) =>
  API.delete(
    `/social-media-accounts?social_id=${data.social_id}&account=${data.account}`
  );

// Notes
export const createNotes = (data) =>
  API.post(`/create-notes?social_id=${data.social_id}`, data);
export const getNotes = (data) => API.get(`/get-notes?social_id=${data}`);
export const updateNotes = (data) =>
  API.put(
    `/update-notes?social_id=${data.social_id}&note_id=${data.note_id}`,
    data
  );
export const deleteNotes = (data) =>
  API.delete(
    `/delete-notes?social_id=${data.social_id}&note_id=${data.note_id}`
  );

// posting schedule
export const postSchedule = (data) =>
  API.post(`/ques?brand_id=${data.brand_id}`, data);

// Members API
export const inviteMemberApi = (data) => API.post('/brand-members', data);

export const getMembersApi = (data) =>
  API.get(`/brand-members?brand=${data.brand_id}`);

export const updateMembersApi = (data) =>
  API.put(`/brand-members?brand_member_id=${data.brand_member_id}`, data);

export const deleteMembersApi = (data) =>
  API.delete(`/brand-members?brand_member_id=${data.brand_member_id}`);

//get all user brands
export const getUserBrands = () => API.get('/get-user-brands');

// payment APIs
export const getPlanDetailsApi = () => API.get(`/billing`);

export const createStripeUserApi = (data) =>
  API.post('/create-stripe-user', data);

export const createStripePaymentMethodApi = (data) =>
  API.post('/create-payment-method', data);

export const createPriceToCustomerApi = (data) =>
  API.post('/create-price', data);

export const subscriptionCheckoutApi = (data) =>
  API.post('/create-checkout-session', data);

export const createSubscriptionApi = (data) =>
  API.post('/create-subscription', data);

export const confirmCardPaymentApi = (data) =>
  API.post('/confirm-card-payment', data);

export const cancelSubscriptionApi = (data) =>
  API.post('/cancel-subscription', data);

export const updatePaymentMethodApi = (data) =>
  API.post('/update-paymentMethod', data);
