import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  Avatar,
  Button,
  Grid,
  Paper,
  StepIcon,
  Typography,
} from '@mui/material';
import '../collectData.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateActiveStep } from '../../../../redux/Slice/user';
import CollectData from './../../collectData';
import AddSocialChannel from '../../addSocialChannel';
import { logox } from '../../../../assets';
import ConfirmationStepper from '../confirmationStepper';
import { makeStyles } from '@mui/styles';
import StepperContainer from '../stepperContainer';
import { handleToken, handleRestRedux } from "../../../../redux/Slice/user";
import { useNavigate } from "react-router-dom";
import { setCookie, getCookie, removeCookie } from "../../../../helpers/cookie/cookie";
import Cookies from 'js-cookie';
import { useStyles } from '../../../../Themes/theme'
// const useStyles = makeStyles({
//   logOutButton: {
//     padding: '15px',
//     color: '#696767 !important',
//   },
//   label: {
//     fontSize: '50px',
//   },
// });

const steps = [
  {
    StepNo: 'Step 1',
    StepLabels: 'User & brand info',
  },
  {
    StepNo: 'Step 2',
    StepLabels: 'Connect Socials',
  },
  {
    StepNo: 'Step 3',
    StepLabels: 'Confirmation',
  },
];

export default function MultiStepper(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { activeStep } = useSelector((state) => state.user);
  const navigate = useNavigate();
  activeStep = parseInt(activeStep);

  const [skipped, setSkipped] = React.useState(new Set());
  // const [brandActiveStep, setBrandActiveStep] = React.useState(activeStep);

  React.useEffect(() => {
    let currentStep = localStorage.getItem('activeStep');
    // console.log('localstep', currentStep);
    if (currentStep === undefined) {
      currentStep = localStorage.setItem('activeStep', 0);
      dispatch(updateActiveStep(currentStep));
      // return 0;
    } else {
      // return currentStep;
      dispatch(updateActiveStep(currentStep));
    }
  }, []);
  // console.log('ASP:::', activeStep);
  const handleLogout = () => {
    dispatch(handleRestRedux())
    navigate("/sign-in");
    dispatch(handleToken(false))
    removeCookie('token')
    // removeCookie('selectedSocialAccount')
    // Cookies.remove('token');
    localStorage.clear()
    Object.keys(Cookies.set()).forEach(function (cookieName) {
      // removeCookie(cookieName);
      Cookies.remove(cookieName);
    });
  }
  return (
    <Box className='stepperBackground' bgcolor="#f7f7f7" p={2}>
      <Grid container display="flex" lg={12}>
        <Grid xs={6} sm={2}   display="flex" justifyContent="center" sx={{ml:{xs:0,md:7}}}>
          <img src={logox} style={{ height: 56, width: 56 }} variant="square" />
        </Grid>
        <Grid
          sm={8} md={7} lg={7}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          container
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps} className="muiStepper_class">

                    <StepLabel
                      {...labelProps}
                      className="Mui-active Mui-completed"
                    >
                      <Typography variant="h5" fontWeight={600}>{label.StepNo}</Typography>
                      <Typography variant="h6" sx={{mr:{xs:0,sm:3,md:7}}} className="stepperLabel">
                        {label.StepLabels}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
        </Grid>
        <Grid xs={6}sm={2}  lg={2} display="flex" justifyContent="center" alignItems="center">
          {activeStep === 1 && (
            <Button className={classes.Grey_small_Btn} onClick={handleLogout}>Logout</Button>
          )}
          {activeStep === 2 && (
            <Button className={classes.Grey_small_Btn} onClick={handleLogout}>Logout</Button>
          )}
        </Grid>
      </Grid>

      <React.Fragment>
        <Grid container display="flex" justifyContent="center" mt={2}>
          <Paper sx={{ width: {xs:'100%',sm:'80%'} }}>
            {activeStep === 0 && (
              <StepperContainer>
                <CollectData />
              </StepperContainer>
            )}
            {activeStep === 1 && (
              <StepperContainer>
                <AddSocialChannel />
              </StepperContainer>
            )}
            {activeStep === 2 && (
              <StepperContainer>
                <ConfirmationStepper />
              </StepperContainer>
            )}
          </Paper>
        </Grid>
      </React.Fragment>
    </Box>
  );
}
