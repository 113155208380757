import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  ThemeProvider,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateUser,
  handleChangeOtpModal,
  handleChangeEmailInfo,
} from '../../../../redux/Slice/user';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { updateEmail, updatePhoneNumber } from '../../../../redux/Api/index';
import '../confirmotp.css';
import Loader from '../../../../components/loader';
import { useStyles } from '../../../../Themes/theme';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getUserData } from '../../../../redux/reduxThunk';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

export default function OtpModal() {
  const classes = useStyles();
  const { userData, isOtpModal, isEmailChange, isMobileChange } = useSelector(
    (state) => state.user
  );
  const [updateLoader, setUpdateLoader] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  // on click on modal close
  const handleClose = () => {
    dispatch(handleChangeOtpModal(false));
    let updateObj = { isEmail: false, isMobile: false };
    dispatch(handleChangeEmailInfo(updateObj));
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onPhoneNumberChange = (value, country) => {
    setValue('phoneNumber', value.slice(country.dialCode.length));
    setValue('countryCode', country.dialCode);
  };

  const onSubmit = async (data) => {
    console.log('emaildataaaaaa data', data);
    setError('');
    const emailId = userData && userData?.emailId ? userData?.emailId : '';
    if (isEmailChange) {
      const newEmailId = data.email;
      const emailData = { emailId, newEmailId };
      if (emailId !== newEmailId) {
        setUpdateLoader(true);
        try {
          await updateEmail(emailData).then((res) => {
            dispatch(getUserData());
            setUpdateLoader(false);
            dispatch(handleChangeOtpModal(false));
          });
        } catch (err) {
          setUpdateLoader(false);
          if (err.response) {
            setError(err.response.data);
          } else {
            setError({ message: 'Network Error' });
          }
        }
      }
    } else if (isMobileChange) {
      let existMobileNum =
        userData && userData?.phoneNumber ? userData?.phoneNumber : '';
      const newPhoneNumber = data.phoneNumber;
      if (data.countryCode === '' || data.phoneNumber == undefined) {
        setError({ message: 'Phone Number is required.' });
      } else if (existMobileNum !== newPhoneNumber) {
        const mobileData = {
          countryCode: data.countryCode,
          newPhoneNumber: data.phoneNumber,
        };
        setUpdateLoader(true);
        try {
          await updatePhoneNumber(mobileData).then((res) => {
            dispatch(getUserData());
            setUpdateLoader(false);
            dispatch(handleChangeOtpModal(false));
          });
        } catch (err) {
          setUpdateLoader(false);
          if (err.response) {
            setError(err.response.data);
          } else {
            setError({ message: 'Network Error' });
          }
        }
      }
    }
  };
  return (
    <Modal
      className="modal"
      open={isOtpModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ padding: "48px" }}
    >
      <Box sx={style}>
        <Box id="modal-modal-title" className="modal-title">
          <Box>
            {isEmailChange && (
              <Typography variant="h4" sx={{ fontWeight: '700' }}>Change Email</Typography>
            )}
            {isMobileChange && (
              <Typography variant="h4" sx={{ fontWeight: '700' }}>Change Number</Typography>
            )}
          </Box>
          <Box className="closeIcon_btn">
            <CloseIcon onClick={handleClose} />
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* register your input into the hook by invoking the "register" function */}
          <Box sx={{ padding: "31px" }}>
            {isEmailChange && (

              <Box >
                <label className='label-style'>Email</label>
                <input
                  className="ChangeEmailInput"
                  placeholder="Business Email addresss *"
                  defaultValue={
                    userData && userData?.emailId ? userData?.emailId : ''
                  }
                  // {...register('email', { required: true })}
                  {...register('email', {
                    pattern:
                      /^[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                    required: true,
                  })}
                />
              </Box>


            )}

            {/* include validation with required or other standard HTML validation rules */}
            {isMobileChange && (
              <>
                <label className='label-style'>Mobile Number</label>
                <ReactPhoneInput
                  country={'in'}
                  enableSearch
                  disableSearchIcon
                  searchStyle={{ margin: '0', width: '97%', height: '30px' }}
                  className={classes.otp_phInput}
                  placeholder="Enter your phone number"
                  inputStyle={{
                    height: 40,
                    width: 300,
                    fontSize: '16px',
                    padding: '15px 0px 10px 70px',
                    borderRadius: '0px',
                    border: 'none',
                  }}
                  buttonStyle={{
                    borderRadius: '8px 0px 0px 8px',
                    border: 'none',
                    borderRight: '1px solid grey',
                    backgroundColor: '#fff',
                    width: '57px',
                    className: 'button',
                  }}
                  dropdownStyle={{ width: '290px' }}
                  inputProps={{ name: 'phoneNumber', required: true }}
                  onChange={onPhoneNumberChange}
                />
              </>
              // 
            )}
            <input type="hidden" {...register('countryCode')} />
            {/* errors will return when field validation fails  */}
            {errors.email?.type === 'required' && (
              <p className="err_msg">Email address is required</p>
            )}
            {errors.email?.type === 'pattern' && (
              <p className="err_msg">Enter a valid email address</p>
            )}
            {errors.phoneNumber?.type === 'required' && (
              <span>Mobile Number is required</span>
            )}
            {errors.phone?.type === 'required' && (
              <p className="err_msg">Phone number is required</p>
            )}
            <p className="data_err"> {error && error?.message}</p>

            {updateLoader ? (
              <button className="Submit_btn">
                <Loader color="inherit" value={100} size={25} />
              </button>
            ) : (
              <input type="submit" className="otp_btn" />
            )}
            </Box>
        </form>
      </Box>
    </Modal>
  );
}
