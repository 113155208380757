import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, Button, Divider, Grid, Typography } from '@mui/material';
import { GreetKaroIcon } from '../../../../assets';
import * as api from '../../../../redux/Api';
import Loader from '../../../../components/loader';
import { useSelector, useDispatch } from 'react-redux';
import SuccessModel from '../../../../components/successModel';
import PauseSubsDialog from '../pauseSubsDialog/PauseSubsDialog';

function CancelSubsDialog({
  open,
  onClose,
  onClick,
  fetchCurrentPlanDetailsData,
}) {
  const dispatch = useDispatch();
  const { currentPlanData } = useSelector((state) => state.user);

  //local states
  const [cancelLoader, setCancelLoader] = useState(false);
  const [cancelError, setCancelError] = useState('');
  const [cancelSuccessMsg, setCancelSuccessMsg] = useState('');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [pauseOpen, setPauseOpen] = React.useState(false);

  const handleOpen = () => {
    setPauseOpen(true);
  };
  const handleClose = () => {
    setPauseOpen(false);
  };

  const handleCancelSubscription = async () => {
    let data = { subscriptionId: currentPlanData?.subscriptionId };
    setCancelLoader(true);
    try {
      await api.cancelSubscriptionApi(data).then((res) => {
        if (res.status === 200) {
          setCancelLoader(false);
          setCancelSuccessMsg('Subscription cancelled successfully.');
          handleContinueDialogOpen();
        }
      });
    } catch (err) {
      setCancelLoader(false);
      if (err.response) {
        setCancelError(err.response.data);
      } else {
        setCancelError({ message: 'Network Error' });
      }
    }
  };
  const handleContinueDialogOpen = () => {
    setSuccessOpen(true);
  };
  const handleContinueDialogClose = () => {
    onClose();
    fetchCurrentPlanDetailsData();
    setSuccessOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        // onClick={onClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h5" fontWeight={600} p={3}>
          Would you like to cancel or pause?
        </Typography>
        <Divider />
        <DialogContent p={2}>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid lg={5} p={2}>
              <Avatar
                src={GreetKaroIcon}
                variant="square"
                sx={{ height: 220, width: 220 }}
              />
            </Grid>
          </Grid>
          <Typography variant="h5" fontWeight={600} mb={2}>
            We recommend pausing your subscription
          </Typography>
          <Typography variant="h6">
            Just like canceling, you will no longer be charged, but this way you
            get to lock the current price you’re paying (which may go up in the
            future) and everything else you have set up so far.
          </Typography>
        </DialogContent>

        {cancelError && (
          <p className="data_err"> {cancelError && cancelError?.message}</p>
        )}
        {/* {cancelSuccessMsg && (
          <p className="success_msg">{cancelSuccessMsg && cancelSuccessMsg}</p>
        )} */}

        <DialogActions>
          <Grid container display="flex" justifyContent="space-around" p={2}>
            {cancelLoader ? (
              <Button className="cancelBtn">
                <Loader color="inherit" value={100} size={25} />
              </Button>
            ) : (
              <Button
                className="cancelBtn"
                onClick={() => handleCancelSubscription()}
              >
                Cancel Subscription
              </Button>
            )}

            <Button className="pauseBtn" onClick={handleOpen}>
              Pause Subscription
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <SuccessModel
        content={cancelSuccessMsg}
        open={successOpen}
        onClose={handleContinueDialogClose}
      />
      <PauseSubsDialog
        open={pauseOpen}
        onClose={handleClose}
        fetchCurrentPlanDetailsData={fetchCurrentPlanDetailsData}
      />
    </>
  );
}

export default CancelSubsDialog;
