import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BrandInformation from './brandInfornation';
import './BrandSetting.css';
import Settings from './settings';
import { getBrandSettingData } from '../../redux/reduxThunk';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../helpers/cookie/cookie';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BrandSettingTabs() {
  // const brandSettings_index=getCookie('brand_settings_tab_index')

  //Local States
  const [value, setValue] = React.useState(0);

  //Global states
  const { allBrandData, brandSettingData, brandSettingLoading, brandId } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dataFetchedRef = React.useRef(false);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    dispatch(getBrandSettingData(brandId));
  }, [brandId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="Brand_Settings_tab"
          >
            <Tab
              className="Brand-settings-list"
              label={
                <Typography variant="h6" fontWeight={600}>
                  Brand Information
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              className="Brand-settings-list"
              label={
                <Typography variant="h6" fontWeight={600}>
                  Settings
                </Typography>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} >
          <BrandInformation
            brandSettingData={brandSettingData}
            brandSettingLoading={brandSettingLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Settings />
        </TabPanel>
      </Box>
    </>
  );
}

export default BrandSettingTabs;
