import React from 'react'
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import "./style.css"
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

function NestedAvatar({bigAvatar,smallAvatar,width,height}) {
  return (<>
     <Badge className='social-account'
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <SmallAvatar alt="Remy Sharp" src={smallAvatar} />
        }
      >
        <Avatar alt="Travis Howard" src={bigAvatar} sx={{ width: {width}, height: {height} }}/>
      </Badge>
  </>
  )
}

export default NestedAvatar