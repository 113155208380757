import { Grid, Radio, Typography, Button, Box } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './forgotPassword.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as api from '../../../redux/Api';
import Loader from '../../../components/loader';
import { useStyles } from '../../../Themes/theme';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { FinalImg } from '../../../assets';
import { useLocation } from 'react-router-dom';
import { headerStyles } from '../../../Themes/headerTheme';
import { inputStyles } from '../../../Themes/InputTheme';
import { buttonStyles } from '../../../Themes/buttonTheme';
import { AddBoxTwoTone } from '@mui/icons-material';
import CustomTextField from '../../../components/customTextField';
export default function ForgetPassword() {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [error, setErrors] = useState('');
  const [dataError, setDataErrors] = useState('');
  const [signupLoader, setSignupLoader] = useState(false);
  const [val, setVal] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const headerClass = headerStyles();
  const inputClass = inputStyles();
  const buttonClass = buttonStyles()

  // on submit Forgot password
  const onSubmit = async (data) => {
    setErrors('');
    let dataObj = {};
    const email = isNaN(data.emailId);

    if (email) {
      dataObj.emailId = data.emailId;
    } else {
      dataObj.phoneNumber = data.emailId;
    }

    if (dataObj.emailId) {
      const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let isValidEmail = regexEmail.test(String(dataObj.emailId).toLowerCase());
      // regexEmail.test(dataObj.emailId);
      if (isValidEmail) {
        forgotPasswordAPI(dataObj);
      } else {
        setDataErrors({
          message:
            'Invalid email format. Please provide a valid email address.',
        });
      }
    } else if (dataObj.phoneNumber) {
      if (dataObj?.phoneNumber?.length === 10) {
        forgotPasswordAPI(dataObj);
      } else {
        setDataErrors({ message: 'Provide proper Phone Number.' });
      }
    } else {
      setDataErrors({ message: 'Enter correct Email or Phonenumber.' });
    }
    // if (!email && data?.emailId?.length !== 10) {
    //   setErrors('Enter a valid phone Number');
    // }
  };

  const forgotPasswordAPI = async (data) => {
    setSignupLoader(true);
    await api
      .forgotPassword(data)
      .then((res) => {
        if (res.status === 200) {
          setSignupLoader(false);
          setCookie('token', res.data);
          setCookie('forgotOtpSentInfo', JSON.stringify(data));
          navigate('/reset-password');
        }
      })
      .catch((err) => {
        setSignupLoader(false);
        if (err.response) {
          setDataErrors(err.response.data);
        } else {
          setDataErrors({ message: 'Network Error' });
        }
      });
  };

  return (
    <Grid container display='flex' height='100vh'>
      <Grid
        sm={6}
        md={7}
        flex={1}
        className={classes.leftGrid}
      >
        <img
          src={FinalImg}
          alt="left"
          className={classes.leftImage}
        />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={5}
        className={classes.rightGrid}
      >
        <Grid xs={10} sm={9}>
          {/* <img src={logox} alt="logo" className={classes.logo} /> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography
                variant="h2"
                className={headerClass.forgotPasswordHeading}
              >
                Forgot your password?
                <Typography className={headerClass.subheading}>
                  Don't worry, we'll send you a message  to help you reset
                  your password.
                </Typography>
              </Typography>
            </Box>
            <Typography variant='h5' className={headerClass.label}>Email address <span className={headerClass.require}>*</span></Typography>
            <CustomTextField
              fieldName="emailId" // Renamed prop from `name` to `fieldName`
              placeholder="Business email addresss "
              classes={inputClass.input}
              {...register('emailId', {
                required: true,
              })}
            />
            {errors.emailId?.type === 'required' && (
              <Typography variant='body1' className={classes.err}>This field is required</Typography>
            )}

            <Typography variant='body1' className={classes.err}>{error && error}</Typography>
            <Typography variant='body1' className="data_err"> {dataError && dataError?.message}</Typography>
            <Box sx={{mt:4}}>
              {signupLoader ? (
                <Button
                  sx={{ width: '100%' }}
                  variant='primary'
                  size='large'
                  disableRipple >
                  <Loader color="inherit" value={100} size={25} />
                </Button>
              ) : (
                <Button
                  sx={{ width: '100%' }}
                  variant='primary'
                  size='large'
                  disableRipple
                  type="submit"
                >
                  Reset your password
                </Button>
              )}
            </Box>
          </form>
          <Typography className={headerClass.footerHeading}>
            Get me back to?
            <Link to="/sign-in"> log in</Link>
          </Typography>
          
        </Grid>
      </Grid>
    </Grid>
  );
}
