import React, { useState ,useEffect} from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CardContent,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
  Grid,
  Avatar
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    // height: "100vh"

  },
  formControl: {
    margin: theme.spacing(3),
  },
  submitButton: {
    margin: theme.spacing(2),
  },
}));

const AccountSelection = ({selectedAccount, setSelectedAccount}) => {
  const classes = useStyles();
  const {tempSocialAccountsData} = useSelector((state) => state.user);

  const handleCheckboxChange = (account) => (event) => {
    setSelectedAccount(event.target.checked ? account : null);
  };

  return (
    <ThemeProvider theme={theme} >
        <div className={classes.root}>
          <FormControl component="fieldset">
            <FormGroup>
              {tempSocialAccountsData && tempSocialAccountsData?.pagesInfo?.map((account) => (
                <FormControlLabel
                  key={account.pageId}
                  control={
                    <Checkbox
                      checked={selectedAccount?.pageId === account.pageId}
                      onChange={handleCheckboxChange(account)}
                    />
                  }
                  label={
                    <Grid sx={{ display: "flex", boxShadow: 'none',    alignItems: "center" }}>
                      <Avatar sx={{ width: 40, height: 40 }} src={account.profileImage}></Avatar>
                      <CardContent>
                        <Typography variant="h6" component="h2">
                          {account.pageName}
                        </Typography>
                      </CardContent>
                    </Grid>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      
    </ThemeProvider>
  );
};

export default AccountSelection;
