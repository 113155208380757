import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Divider, Typography, Box } from '@mui/material';
import '../personalForm/personalForm.css';
import { useForm } from 'react-hook-form';
import {
  verifyOtp,
  accountSettingsApi,
  resentEmailOtp,
  resentMobileOtp,
} from '../../../../redux/Api/index';
import Loader from '../../../../components/loader';
import SuccessModel from '../../../../components/successModel';
import {
  handleSignedUserData,
  handleSignedUserLoader,
  handleValidateOtp,
  handleValidateOtpDialog,
} from '../../../../redux/Slice/user';
import { buttonStyles } from '../../../../Themes/buttonTheme';
import { useSelector, useDispatch } from 'react-redux';

export default function ValidateOtpDialog({
  open,
  onClose,
  onClick,
  fetchAccountSettingsData,
}) {
  const { validateOtpDialog, validateEmail, validateMobile } = useSelector(
    (state) => state.user
  );
  // Form handlers
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const buttons =buttonStyles();
  //local States
  const [updateLoader, setUpdateLoader] = React.useState(false);
  const [updateError, setUpdateError] = React.useState('');
  const [updateSuccess, setUpdateSuccess] = React.useState('');
  const [resentOtpLoader, setResentOtpLoader] = React.useState(false);
  const [resentSuccessMsg, setResentSuccessMsg] = React.useState('');

  const [successOpen, setSuccessOpen] = React.useState(false);

  const onSubmit = async (data) => {
    setUpdateLoader(true);
    await verifyOtp(data)
      .then((res) => {
        if (res.status === 200) {
          setUpdateLoader(false);
          setUpdateSuccess('OTP Verified Successfully.');
          //to close current modal and open success msg display modal
          handleContinueDialogOpen();
          reset();
        }
      })
      .catch((err) => {
        setUpdateLoader(false);
        // setSuccessOpen(false);
        if (err.response) {
          setUpdateError(err.response?.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      });
  };

  const handleContinueDialogOpen = () => {
    setSuccessOpen(true);
  };
  const handleContinueDialogClose = () => {
    handleClose();
    fetchAccountSettingsData();
    setSuccessOpen(false);
  };

  const handleClose = () => {
    dispatch(handleValidateOtpDialog(false));
    let updateObj = { isEmail: false, isMobile: false };
    dispatch(handleValidateOtp(updateObj));
  };

  const handleResentOtp = async (mode) => {
    setResentOtpLoader(true);
    setResentSuccessMsg('');
    setUpdateError('');
    if (mode === 'email') {
      try {
        await resentEmailOtp().then((res) => {
          setResentSuccessMsg(res?.data);
          setResentOtpLoader(false);
        });
      } catch (err) {
        setResentOtpLoader(false);
        if (err.response) {
          setUpdateError(err.response.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      }
    } else if (mode === 'mobile') {
      try {
        await resentMobileOtp().then((res) => {
          setResentSuccessMsg(res?.data);
          setResentOtpLoader(false);
        });
      } catch (err) {
        setResentOtpLoader(false);
        if (err.response) {
          setUpdateError(err.response.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      }
    }
  };

  const handleOnChange = (e) => {
    setUpdateError('');
    setUpdateError('');
    setResentSuccessMsg('');
  };

  return (
    <div>
      <Dialog
        open={validateOtpDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {validateEmail && (
          <Typography variant="h5" fontWeight={700} p={2}>
            Validate Your Email OTP
          </Typography>
        )}
        {validateMobile && (
          <Typography variant="h5" fontWeight={700} p={2}>
            Validate Your Mobile OTP
          </Typography>
        )}
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <DialogContent p={2}>
            {validateEmail && (
              <Typography variant="h6">
                Enter OTP sent to your email.
              </Typography>
            )}
            {validateMobile && (
              <Typography variant="h6">
                Enter OTP sent to your Mobile Number.
              </Typography>
            )}

            {validateEmail && (
              <input
                type="text"
                className="validateInput"
                {...register('emailIdOtp', {
                  required: true,
                  onChange: (e) => handleOnChange(e),
                })}
              />
            )}
            {errors.emailIdOtp?.type === 'required' && (
              <p className="form_err_msg">OTP is required</p>
            )}

            {validateMobile && (
              <input
                type="text"
                className="validateInput"
                {...register('phoneNumberOtp', {
                  required: true,
                  onChange: (e) => handleOnChange(e),
                })}
              />
            )}

            {errors.phoneNumberOtp && (
              <p className="form_err_msg">OTP is required</p>
            )}
          </DialogContent>

          {updateError && (
            <p className="data_err"> {updateError && updateError?.message}</p>
          )}
          {resentSuccessMsg && (
            <p className="success_Message">
              {resentSuccessMsg && resentSuccessMsg}
            </p>
          )}

          <DialogActions>
            <Typography variant="h6" mr={2}>
              {resentOtpLoader ? (
                <Box className="resend_link">
                  <Loader color="blue" value={100} size={20} />
                </Box>
              ) : (
                <span
                  onClick={() =>
                    handleResentOtp(validateEmail ? 'email' : 'mobile')
                  }
                  className="resend_link"
                >
                  Resend OTP
                </span>
              )}
            </Typography>

            {updateLoader ? (
              <button className="ValidateDialogbtn">
                <Loader color="inherit" value={100} size={25} />
              </button>
            ) : (
              <Button type="submit" className={buttons.Blue_color_Btn} autoFocus>
                Validate OTP
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      <SuccessModel
        content={updateSuccess}
        open={successOpen}
        onClose={handleContinueDialogClose}
      />
    </div>
  );
}
