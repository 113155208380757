import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Typography, Grid, TextField } from '@mui/material';
import '../Billing.css';
import { useSelector, useDispatch } from 'react-redux';
import { handlePaymentPriceModel } from '../../../../redux/Slice/user';
import { useForm } from 'react-hook-form';
import * as api from '../../../../redux/Api/index';
import Loader from '../../../../components/loader';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(
  'pk_test_51KtAkGSGnhxQu4RMghhme0y0yemkt0Nr446eeAyRhxO1CVv7ttheIMpmMbQjKsN4LNtksvnZGhQCZ3JjjVcaUkdf00dIkwd1km'
); // starts with pk_

const maxWidth = 'sm';
export default function CustomerPriceModal({
  selectedPlan,
  isYearly,
  payableAmount,
  secretKey,
}) {
  //form handlers
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  //Loader,Error & Success MSg States
  const [userLoader, setUserLoader] = React.useState(false);
  const [userError, setUserError] = React.useState('');
  const [paymentIntent, setPaymentIntent] = React.useState();

  const { paymentPriceModel, accountSettingsData } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const handleClosePriceModal = () => {
    dispatch(handlePaymentPriceModel(false));
  };

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  // if (!stripe || !elements) {
  //   // Stripe.js has not loaded yet. Make sure to disable
  //   // form submission until Stripe.js has loaded.
  //   return '';
  // }

  // Form on Submit handler
  const onSubmit = async (data) => {
    setUserLoader(true);
    // // let ProductName = { name: selectedPlan };
    // let planPeriod = isYearly ? 'year' : 'month';
    // let userObj = {
    //   amount: payableAmount * 100, // Change: need to send in paisa
    //   currency: 'usd', // Change: currency based on country
    //   productData: selectedPlan,
    //   period: planPeriod,
    // };

    // await api
    //   .createPriceToCustomerApi(userObj)
    //   .then(async (res) => {
    //     console.log('ressssssss', res);
    //     if (res.status === 200) {
    //       setUserLoader(false);
    //       // const cardElement = elements.getElement(CardElement);
    //       // const confirmPayment = await stripe.confirmCardPayment(
    //       //   res?.data?.clientSecret
    //       // );

    //       // if (confirmPayment?.error) {
    //       //   alert(confirmPayment.error.message);
    //       // } else {
    //       //   alert('Success! Check your email for the invoice.');
    //       // }
    //     }
    //   })
    //   .catch((err) => {
    //     setUserLoader(false);
    //     if (err.response) {
    //       setUserErrorr(err.response?.data);
    //     } else {
    //       setUserErrorr({ message: 'Network Error' });
    //     }
    //   });

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    let name = `${accountSettingsData?.accountInformation?.firstName} ${accountSettingsData?.accountInformation?.lastName}`;
    const cardElement = elements.getElement(CardElement);
    let { error, paymentIntent } = await stripe.confirmCardPayment(secretKey, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      },
    });

    if (error) {
      // show error and collect new card details.
      setUserLoader(false);
      setUserError(error?.message);
      return;
    }
    console.log('paymentIntent', paymentIntent);
    setPaymentIntent(paymentIntent);
    setUserLoader(false);
  };
  // if (paymentIntent && paymentIntent.status === 'succeeded') {
  //   return navigate('/home');
  // }
  return (
    <>
      <Dialog
        open={paymentPriceModel}
        onClose={handleClosePriceModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={maxWidth}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title" p={1}>
            <Typography variant="h5" color="#1d1d1d" fontWeight={600}>
              Subscribe
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent p={2}>
            <Grid container>
              <div>
                <Typography
                  display="flex"
                  variant="h5"
                  className="creditcard_label"
                >
                  Selected Plan : {selectedPlan},
                </Typography>
                <Typography
                  display="flex"
                  variant="h5"
                  className="creditcard_label"
                >
                  period:
                  {isYearly ? 'Yearly' : 'Monthly'},
                </Typography>
                <Typography
                  display="flex"
                  variant="h5"
                  className="creditcard_label"
                >
                  Total Amount: {payableAmount}
                </Typography>
              </div>
            </Grid>
            <CardElement />
          </DialogContent>
          {userError && (
            <p className="data_err">{userError && userError?.message}</p>
          )}

          <DialogActions>
            <Grid p={2}>
              <Button onClick={handleClosePriceModal} className="cancelBtn">
                Cancel
              </Button>
              {userLoader ? (
                <Button
                  className="AgreeBtn"
                  sx={{ mr: 2, ml: 2, width: 110, height: 45 }}
                >
                  <Loader color="inherit" value={100} size={25} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="AgreeBtn"
                  sx={{ mr: 2, ml: 2 }}
                >
                  Continue
                </Button>
              )}
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
