import React from 'react';
import { Avatar, Grid, Button, Typography } from '@mui/material';
import {
  FacebookPageIcon,
  FacebookGroupIcon,
} from '../../../assets';
import {
  handleFacebookPageLogin,
  handleFacebookGroupLogin,
} from '../../../helpers/handleSocialLogin/handleSocialLogin';
import { useLocation } from 'react-router-dom';

const FacebookDialog = () => {
  const location = useLocation();
  return (
    < Grid p='16px 8px'>
    <Typography variant='h5' p='8px 16px'>
          Select Facebook Page or Group
            </Typography>
    <Grid container display='flex' >
       
      <Grid lg={6} p={2}>
        <Button
          sx={{ width: '200px',border:'1px solid #000', height:'56px',
          p:'8px 16px',display:'flex',alignItems:'center' }}
          onClick={() => {handleFacebookPageLogin(location?.pathname)
           }}
        >
          <Avatar src={FacebookPageIcon} variant="square" sx={{height:24,width:24}} />
          <Typography variant='h6' ml={2}>Add Page</Typography>
        </Button>
      </Grid>
      <Grid lg={6} p={2}>
        <Button
          sx={{ width: '200px',border:'1px solid #000', height:'56px',
          p:'8px 16px',display:'flex',alignItems:'center',textAlign:'center' }}
          onClick={() => {handleFacebookGroupLogin(location?.pathname)
           }}
        >
          <Avatar src={FacebookGroupIcon} variant="square" sx={{height:24,width:24}}/>
          <Typography variant='h6' ml={2}>Add Group</Typography>
        </Button>
      </Grid>
    </Grid>
    </Grid>
  );
};

export default FacebookDialog;
