import {
  Grid,
  Radio,
  Typography,
  Box,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import logox from '../../../assets/assets/logox.png';
import './login.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { userRegisterApi } from '../../../redux/Api/index';
import { updateUser } from '../../../redux/Slice/user';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/loader';
import { makeStyles } from '@mui/styles';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactDOM from 'react-dom';
import { handleToken } from '../../../redux/Slice/user';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { FinalImg } from '../../../assets';
const useStyles = makeStyles({
  leftImage: {
    height: 900,
    width: '100%',
    '@media (max-width: 1280px)': {
      height: 740,
    },
    '@media (max-width: 768px)': {
      height: '700px',
    },
    '@media (min-width: 1600px)': {
      height: '1016px',
    },
  },
  right_section: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: 900,
    '@media (max-width: 1280px)': {
      height: 740,
    },
    '@media (max-width: 768px)': {
      height: 700,
    },
    '@media (min-width: 1600px)': {
      height: 1016,
    },
  },

  logo: {
    height: '70px',
    width: '60px',
    marginBottom: '20px',
    marginTop: '60px',
  },
  input: {
    height: 60,
    width: 550,
    border: 'solid 1px grey',
    borderRadius: '10px',
    marginBottom: '30px',
    marginTop: '0px',
    paddingLeft: '15px',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    '@media (max-width: 1280px)': {
      width: 400,
      height: 45,
    },
    '@media (max-width: 768px)': {
      width: 300,
      height: 45,
    },
    '@media (min-width: 1800px)': {
      width: 700,
      height: 70,
    },
  },
  phInput: {
    height: 60,
    width: 550,
    border: 'solid 1px grey',
    borderRadius: '8px',
    marginBottom: '30px',
    marginTop: '0px',
    '& .MuiFilledInput-root': {
      background: '#fff',
    },
    // paddingLeft: '15px',
    '@media (max-width: 1280px)': {
      width: 400,
      height: 45,
    },
    '@media (max-width: 768px)': {
      width: 300,
      height: 45,
    },
    '@media (min-width: 1800px)': {
      width: 700,
      height: 70,
    },
  },
  submit_button: {
    height: 60,
    width: 550,
    borderRadius: '10px',
    color: 'white ',
    backgroundColor: 'black',
    cursor: 'pointer',
    fontSize: 'large',
    marginBottom: '10px',
    '@media (max-width: 1280px)': {
      width: 400,
      height: 45,
    },
    '@media (max-width: 768px)': {
      width: 300,
      height: 45,
    },
    '@media (min-width: 1800px)': {
      width: 800,
      height: 80,
    },
  },

  err: {
    // marginBottom: '20px',
    marginTop: '-1px',
    fontSize: 'small',
    font: 100,
    color: 'brown',
  },
  data_err: {
    // marginBottom: '20px',
    color: 'brown',
    textAlign: 'center',
    fontSize: '16px',
  },
});

export default function SignUp() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [isValidNumber, setIsValidNumber] = useState(true);
  const [error, setError] = useState('');
  const [signupLoader, setSignupLoader] = useState(false);
  let navigate = useNavigate();
  const [state, setState] = useState({
    name: '',
    type: '',
    location: '',
    contact: '',
    MACoy: '',
    equipManu: '',
  });
  const [show, setShow] = useState(false);

  const onPhoneNumberChange = (value, country) => {
    setValue('phoneNumber', value.slice(country.dialCode.length));
    setValue('countryCode', country.dialCode);
  };

  const location = useLocation();
  useEffect(() => {
    // fetching user token and details from param for brand Member signup
    const token = location?.search?.split(':')[1];
    setCookie('token', token);
    var decoded = jwt_decode(token);
    setValue('emailId', decoded?.emailId ? decoded?.emailId : '');
  }, [location]);

  // on Signup Submit
  const onSubmit = async (data) => {
    setSignupLoader(true);
    setError('');
    let registorData = {
      ...data,
      onboardingStatus: 'CONFIRM_OTP',
    };
    // console.log('registorData', registorData);
    await userRegisterApi(registorData)
      .then((res) => {
        if (res.status === 200) {
          setSignupLoader(false);
          const userToken = res?.data;
          setCookie('token', userToken);
          // localStorage.setItem('token', userToken);
          navigate('/verify-otp');
          dispatch(handleToken(true));
        }
      })
      .catch((err) => {
        setSignupLoader(false);
        if (err.response) {
          setError(err.response.data);
        } else {
          setError({ message: 'Network Error' });
        }
      });
  };
  const handleOnChange = (...args) => {
    console.log(args);
  };

  return (
    <Grid container spacing={2} className="sign_up_div">
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <img src={FinalImg} alt="left" className={classes.leftImage} />
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        className={classes.right_section}
      >
        <div>
          <img src={logox} alt="logo" className={classes.logo} />
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <input
              // defaultValue={user.firstName}
              className={classes.input}
              maxLength={30}
              // inputProps={{ style: { fontSize: 16, padding: '10px 12px' } }}
              placeholder="First Name"
              // variant="outlined"
              {...register('firstName', { required: true })}
            />
            {errors.firstName?.type === 'required' && (
              <p className="form_err">First Name is required</p>
            )}
            <input
              // defaultValue={user.lastName}
              className={classes.input}
              maxLength={30}
              // inputProps={{ style: { fontSize: 16, padding: '10px 12px' } }}
              placeholder="Last Name"
              // variant="outlined"
              {...register('lastName', { required: true })}
            />
            {errors.lastName?.type === 'required' && (
              <p className="form_err">Last Name is required</p>
            )}
            <input
              disabled={true}
              className={classes.input}
              placeholder="Business Email addresss *"
              {...register('emailId', {
                // pattern:
                //   /^[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                required: true,
              })}
            />
            {errors.emailId?.type === 'required' && (
              <p className="err_msg">Email address is required</p>
            )}
            <Box className="input-wrapper">
              <input
                type={show ? 'text' : 'password'}
                placeholder="Password *"
                maxLength={15}
                className={classes.input}
                // style={{ position: 'relative' }}
                {...register('password', {
                  required: true,
                  // onChange: (e) => handleInputChange(e),
                })}
              />
              <IconButton
                onClick={() => setShow(!show)}
                className="toggle-icon"
              >
                {show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Box>
            {errors.password?.type === 'required' && (
              <p className="err_msg">Password is required</p>
            )}

            <ReactPhoneInput
              country={'in'}
              enableSearch
              disableSearchIcon
              searchStyle={{ margin: '0', width: '97%', height: '30px' }}
              className={classes.phInput}
              placeholder="Enter your phone number"
              inputStyle={{
                height: 53,
                width: 550,
                fontSize: '16px',
                padding: '15px 0px 10px 70px',
                borderRadius: '0px',
                border: 'none',
              }}
              buttonStyle={{
                borderRadius: '8px 0px 0px 8px',
                border: 'none',
                borderRight: '1px solid grey',
                backgroundColor: '#fff',
                width: '58px',
              }}
              dropdownStyle={{ width: '380px' }}
              inputProps={{ name: 'phoneNumber', required: true }}
              onChange={onPhoneNumberChange}
            />
            {errors.phone?.type === 'required' && (
              <p className="err_msg">Phone number is required</p>
            )}
            {/* {!isValidNumber && <p>Please enter a valid phone number.</p>} */}
            <input type="hidden" {...register('countryCode')} />
            <label htmlFor="muhRadio1">
              <div className="termsAndCondition">
                <div>
                  <input
                    className="terms"
                    type="checkbox"
                    {...register('termsAccepted', { required: true })}
                  />
                </div>
                <div>
                  I agree to <span className="span-1">Terms of Service</span>{' '}
                  and <span className="span-1">Privacy Policy</span>
                </div>
              </div>
            </label>
            {errors.termsAccepted && (
              <p className={classes.err}>
                Please agree to our Terms of Service & Privacy Policy.
              </p>
            )}
            <br />
            <p className="data_err"> {error && error?.message}</p>
            <div>
              {signupLoader ? (
                <button className={classes.submit_button}>
                  <Loader color="inherit" value={100} size={25} />
                </button>
              ) : (
                <button type="submit" className={classes.submit_button}>
                  Submit
                </button>
              )}
            </div>
            {/* <input type="submit" class="submit_buttons" /> */}
            <Typography
              // className="terms"
              variant="h6"
              component="p"
              mt={3}
              mb={3}
            >
              Already have an account?{' '}
              <span className="span-1">
                {' '}
                <Link to="/sign-in">sign in</Link>
              </span>
            </Typography>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
