import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Switch,
  Typography,
  Box,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import './setting.css';
import * as api from '../../../redux/Api';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/loader';
import HandleDeleteDialog from './handleDeleteDialog';
import HandleRemoveAccountDialog from './handleRemoveAccountDialog';
import moment from 'moment';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { getSocialAccountsSettingsData } from '../../../redux/reduxThunk';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
let syncCheck = false;
let mediaCheck = false;
function Settings() {
  const {
    selectedSocialAccountId,
    socialAccountSettingsData,
    socialAccountSettingsLoading,
  } = useSelector((state) => state.user);
  //local states
  const [dopen, setDopen] = React.useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(
    socialAccountSettingsData?.settings?.autoSync?.automaticallySyncPost
      ? socialAccountSettingsData?.settings?.autoSync
          ?.automaticallySyncPost
      : false
  );
  const [checkedMedia, setCheckedMedia] = React.useState(
    socialAccountSettingsData?.settings?.autoSync?.addPhotosSync
      ? socialAccountSettingsData?.settings?.autoSync?.addPhotosSync
      : false
  );

  const [syncLoader, setSyncLoader] = useState(false);
  const [syncError, setSyncError] = useState('');
  const [syncSuccessMsg, setSyncSuccessMsg] = useState('');

  const [autoSyncLoader, setAutoSyncLoader] = useState(false);
  const [autoSyncError, setAutoSyncError] = useState('');
  const [autoSyncSuccessMsg, setAutoSyncSuccessMsg] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setChecked(
      socialAccountSettingsData?.settings?.autoSync?.automaticallySyncPost
        ? socialAccountSettingsData?.settings?.autoSync
            ?.automaticallySyncPost
        : false
    );
    setCheckedMedia(
      socialAccountSettingsData?.settings?.autoSync?.addPhotosSync
        ? socialAccountSettingsData?.settings?.autoSync?.addPhotosSync
        : false
    );
  }, [socialAccountSettingsData]);

  const handleSyncPosts = async () => {
    const syncData = {
      social_id: selectedSocialAccountId,
    };
    setSyncLoader('true');
    await api
      .syncPostsApi(syncData)
      .then((res) => {
        // console.log('successsssss');
        if (res.status === 200) {
          setSyncLoader(false);
          setSyncSuccessMsg('Sync Successfully.');
          // fetching settings data based on social account
          fetchSocialAccountSettingsData();
        }
      })
      .catch((err) => {
        setSyncLoader(false);
        if (err.response) {
          setSyncError(err.response?.data);
        } else {
          setSyncError({ message: 'Network Error' });
        }
      });
  };

  const handleDeleteDialogOpen = () => {
    setDopen(true);
  };
  const handleDeleteDialogClose = () => {
    setDopen(false);
  };

  const handleRemoveDialogOpen = () => {
    setRemoveDialogOpen(true);
  };
  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
  };
  const handleChangeAutoSync = (event) => {
    setChecked(event.target.checked);
    syncCheck = event.target.checked;
    updateAutoSyncData();
  };
  const handleChangeAutoSyncMedia = (event) => {
    setCheckedMedia(event.target.checked);
    mediaCheck = event.target.checked;
    updateAutoSyncData();
  };

  const updateAutoSyncData = async () => {
    let data = {
      automaticallySyncPost: syncCheck,
      addPhotosSync: mediaCheck,
    };
    let autoSyncData = {
      social_id: selectedSocialAccountId,
    };
    setAutoSyncLoader(true);
    try {
      await api.autoSyncPostsApi(data, autoSyncData).then((res) => {
        // setAutoSyncSuccessMsg(res?.data);
        setAutoSyncLoader(false);
        // fetching settings data based on social account
        fetchSocialAccountSettingsData();
      });
    } catch (err) {
      setAutoSyncLoader(false);
      if (err.response) {
        setAutoSyncError(err.response.data);
      } else {
        setAutoSyncError({ message: 'Network Error' });
      }
    }
  };

  const fetchSocialAccountSettingsData = () => {
    // fetching settings data based on social account
    const selected_brand_id = getCookie('created_brand_id');
    const socialAccountSettingsData = {
      social_id: selectedSocialAccountId,
      brand_id: selected_brand_id,
    };
    dispatch(getSocialAccountsSettingsData(socialAccountSettingsData));
  };

  // caluclation for hours diff between sync time and current time
  const currentDate = moment();
  let syncTime = socialAccountSettingsData?.settings?.syncPost?.lastSync
    ? moment(socialAccountSettingsData?.settings?.syncPost?.lastSync)
    : null;
  let hoursDifference = '';
  if (syncTime !== null) {
    hoursDifference = currentDate.diff(syncTime, 'hours');
  }

  console.log('socialAccountSettingsData', socialAccountSettingsData);
  return (
    <>
      <Grid p={4} bgcolor="#fff">
        {socialAccountSettingsLoading ? (
          <Box className="loader_div" style={{ width: '600px' }}>
            <Loader color="inherit" value={100} size={40} />
          </Box>
        ) : (
          <>
            <Typography variant="h5" fontWeight={600} mb="8px">
              Sync Posts
            </Typography>

            <Grid
              container
              lg={12}
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              mb={4}
            >
              <Grid lg={8}>
                <Typography variant="h6" color="#767676">
                  Import and sync posts published outside Publer Last synced:
                  {`${
                    hoursDifference !== ''
                      ? `${hoursDifference} hours ago`
                      : '-'
                  } `}
                </Typography>
              </Grid>

              <Grid
                lg={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                {syncLoader ? (
                  <Button className="SyncPost_btn">
                    <Loader color="inherit" value={100} size={20} />
                  </Button>
                ) : (
                  <Button
                    className="SyncPost_btn"
                    onClick={() => handleSyncPosts()}
                    sx={{mt:{xs:"15px", sm:"20px"}}}
                  >
                    Select a Shortner
                  </Button>
                )}

                {syncError && (
                  <p className="data_err"> {syncError && syncError?.message}</p>
                )}
                {syncSuccessMsg && (
                  <p className="success_msg">
                    {syncSuccessMsg && syncSuccessMsg}
                  </p>
                )}
              </Grid>
            </Grid>

            <Typography variant="h5" fontWeight={600} mb="8px">
              Auto Sync
            </Typography>

            <Typography variant="h6" color="#767676">
              By checking these options, SocialXperia will automatically <br />
              import published posts from this social account
            </Typography>

            <Grid container lg={12} display="flex" alignItems="center" margin="12px 0px 12px -10px">
              <Grid display="flex" alignItems="center" mr={5}>
                <Checkbox
                  checked={checked}
                  onChange={handleChangeAutoSync}
                  {...label}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                />
                <Typography variant="h6" color="#767676">
                  Automatically Sync Posts
                </Typography>
              </Grid>
              <Grid display="flex" alignItems="center">
                <Checkbox
                  checked={checkedMedia}
                  onChange={handleChangeAutoSyncMedia}
                  {...label}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                />
                <Typography variant="h6" color="#767676">
                  Add photos from synced posts to the Media Library
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="h5" fontWeight={600} margin= "24px 0px 0px 0px">
              Delete Scheduled Posts
            </Typography>

            <Grid
              container
              lg={12}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb="40px"
              mt="8px"
            >
              <Grid lg={8}>
                <Typography variant="h6" color="#767676">
                  This will delete all scheduled posts from all users for this
                  social account. This can't be undone!
                </Typography>
              </Grid>
              <Grid
                lg={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button className="Delete_btn" onClick={handleDeleteDialogOpen}>
                  Delete All Scheduled Posts
                </Button>
              </Grid>
            </Grid>
            <Typography variant="h5" fontWeight={600} m="16px 0px">
              Remove Social Media Account
            </Typography>

            <Grid
              container
              lg={12}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb="40px"
              mt="8px"
            >
              <Grid lg={8}>
                <Typography variant="h6" color="#767676">
                  This will delete from Publer all the data related to it, such
                  as scheduled posts, analytics, and other settings.
                </Typography>
              </Grid>
              <Grid
                lg={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button className="Delete_btn" onClick={handleRemoveDialogOpen}>
                  Remove Social Account
                </Button>
              </Grid>
            </Grid>
            <HandleDeleteDialog
              open={dopen}
              onClose={handleDeleteDialogClose}
              variant="h5"
            />
            <HandleRemoveAccountDialog
              open={removeDialogOpen}
              onClose={handleRemoveDialogClose}
              variant="h5"
            />
          </>
        )}
      </Grid>
    </>
  );
}

export default Settings;
