import { DeleteOutline } from '@mui/icons-material';
import {
  Typography,
  Grid,
  Box,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import NestedAvatar from '../../../../components/nestedAvatar';
import { Avatar } from '@mui/material';
import '../AddSocialChannel.css';
import {
  LinkedinIcon,
  YoutubeIcon,
  InstagramIcon,
  MyBusinessIcon,
  PinterestIcon,
  FacebookIcon,
} from '../../../../assets';
import * as api from '../../../../redux/Api';
import { useSelector, useDispatch } from 'react-redux';
import { setCookie, getCookie, removeCookie } from '../../../../helpers/cookie/cookie';
import { getAllSocialAccountData } from '../../../../redux/reduxThunk';
import DeleteIcon from '@mui/icons-material/Delete';
import HandleDeleteDialog from './handleDeleteDialog';
function SocialsAddedCard() {
  const dispatch = useDispatch();
  const { allBrandData, allSocialAccountData } = useSelector(
    (state) => state.user
  );
  const created_brand_id = getCookie('created_brand_id');
  const [dopen, setDopen] = React.useState(false);
  const [socialMediaDeleteId, setSocialMediaDeleteId] = React.useState();
  const [account, setAccount] = React.useState();
  const [pageName, setPageName] = React.useState();
  useEffect(() => {
    dispatch(getAllSocialAccountData(created_brand_id));
  }, [created_brand_id]);

  const handleDeleteDialogOpen = (data) => {
    setSocialMediaDeleteId(data?.data?._id);
    setAccount(data.name);
    setPageName(data.data.pageName)
    setDopen(true);
  };
  const handleDeleteDialogClose = () => {
    setDopen(false);
  };
  const socialMediaData = [
    {
      name: 'facebookPage',
      icon: FacebookIcon,
      data: allSocialAccountData?.facebookPage
        ? allSocialAccountData?.facebookPage
        : null,
    },
    {
      name: 'facebookGroup',
      icon: FacebookIcon,
      data: allSocialAccountData?.facebookGroup
        ? allSocialAccountData?.facebookGroup
        : null,
    },
    {
      name: 'instagram',
      icon: InstagramIcon,
      data: allSocialAccountData?.instagram
        ? allSocialAccountData?.instagram
        : null,
    },
    {
      name: 'Pinterest',
      icon: PinterestIcon,
      data: allSocialAccountData?.pinterest
        ? allSocialAccountData?.pinterest
        : null,
    },
    {
      name: 'google',
      icon: MyBusinessIcon,
      data: allSocialAccountData?.google ? allSocialAccountData?.google : null,
    },
    {
      name: 'LinkedIn',
      icon: LinkedinIcon,
      data: allSocialAccountData?.linkedIn
        ? allSocialAccountData?.linkedIn
        : null,
    },
    {
      name: 'Youtube',
      icon: YoutubeIcon,
      data: allSocialAccountData?.youtube
        ? allSocialAccountData?.youtube
        : null,
    },
  ];

  let filterSocialMediaData =
    socialMediaData && socialMediaData?.filter((item) => item.data !== null);
  return (
    <>
      <Box className="Fragment">
        <Grid container xs={12} sm={12} md={12} lg={12} spacing={2}>
          {filterSocialMediaData.map((item) => (
            <div >
              <NestedAvatar
                height='48px'
                width='48px'
                bigAvatar={item?.data?.profileImage}
                smallAvatar={item.icon}
              />
              <Typography className='deleteIcon'>
                <DeleteIcon className='icon' sx={{ color: '#d61a1a', height: 24, width: 24 }} onClick={() => handleDeleteDialogOpen(item)} />
              </Typography>
            </div>
          ))}
          <HandleDeleteDialog
            open={dopen}
            onClose={handleDeleteDialogClose}
            variant="h5"
            account={account}
            pageName={pageName}
            id={socialMediaDeleteId}
          />
        </Grid>
      </Box>
    </>
  );
}

export default SocialsAddedCard;
