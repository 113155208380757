import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import '../personalForm/personalForm.css';
import { useForm } from 'react-hook-form';
import {
  accountSettingsApi,
  updatePhoneNumber,
} from '../../../../redux/Api/index';
import {
  handleSignedUserData,
  handleSignedUserLoader,
} from '../../../../redux/Slice/user';
import {useSelector,useDispatch } from 'react-redux';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useStyles } from '../../../../Themes/theme';
import { buttonStyles } from '../../../../Themes/buttonTheme';
import Loader from '../../../../components/loader';
import {
  handleValidateOtp,
  handleValidateOtpDialog,
} from '../../../../redux/Slice/user';
import ValidateOtpDialog from '../validateOtpDialog/ValidateOtpDialog';
function UpdateMobile({
  personalData,
  fetchAccountSettingsData,
  isVerifyEmailMobileOtp,
}) {
  //form handlers
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const buttons = buttonStyles();
  const dispatch = useDispatch();
  //local states
  const [matchEmail, setMatchEmail] = useState(false);
  const [updateLoader, setUpdateLoader] = React.useState(false);
  const [error, setError] = useState('');
  const [updateSuccessMsg, setUpdateSuccessMsg] = useState('');
  const [active, setActive] = useState(false)
  const { validateOtpDialog } = useSelector((state) => state.user);
  const onPhoneNumberChange = (value, country) => {
    if (personalData?.phoneNumber !== parseInt(value.substring(country.dialCode.length))) {
      setActive(true)
    } else {
      setActive(false)
    }
    setValue('phoneNumber', value.slice(country.dialCode.length));
    setValue('countryCode', country.dialCode);
    setError('');
    setUpdateSuccessMsg('');
  };

  // Form on Submit handler
  const onSubmit = async (data) => {
    let existMobileNum =
      personalData && personalData?.phoneNumber
        ? personalData?.phoneNumber
        : '';
    const newPhoneNumber = data.phoneNumber;
    if (
      data.phoneNumber === undefined ||
      data.phoneNumber === 'undefined' ||
      data.phoneNumber === null ||
      data.phoneNumber === ''
    ) {
      setError({ message: 'Phone Number is required.' });
    } else if (data.countryCode === '') {
      setError({ message: 'Country code is required.' });
    } else if (existMobileNum !== Number(newPhoneNumber)) {
      const mobileData = {
        countryCode: data.countryCode,
        newPhoneNumber: data.phoneNumber,
        isUpdatePhone: true,
      };
      setUpdateLoader(true);
      try {
        await updatePhoneNumber(mobileData).then((res) => {
          setUpdateLoader(false);
          setUpdateSuccessMsg('Phone Number updated successfully.');
          // after updating fetching updated data to display
          fetchAccountSettingsData();
        });
      } catch (err) {
        setUpdateLoader(false);
        if (err.response) {
          setError(err.response.data);
        } else {
          setError({ message: 'Network Error' });
        }
      }
    }
    let type='mobile'
    let updateObj = { isEmail: false, isMobile: false };
    if (type === 'mobile') {
      updateObj = { isEmail: false, isMobile: true };
    }
    dispatch(handleValidateOtp(updateObj));
    dispatch(handleValidateOtpDialog(true));
  };
  // console.log('personalData', personalData);
  return (
    <>
      <Box>
        <ul className="personal_info-upadte-form">
          <form onSubmit={handleSubmit(onSubmit)} className="mobile_form">
            <li>
              <Typography display="flex" variant="h6" className="label-name">
                Mobile Number <span className='red'>*</span>
              </Typography>
              <ReactPhoneInput
                country={
                  personalData && personalData?.countryCode
                    ? personalData?.countryCode
                    : 'in'
                }
                value={`+${personalData && personalData?.countryCode
                  ? personalData?.countryCode
                  : '91'
                  }${personalData && personalData?.phoneNumber
                    ? personalData?.phoneNumber
                    : ''
                  }`}
                enableSearch
                disableSearchIcon
                searchStyle={{ margin: '0', width: '97%', height: '30px' }}
                className={classes.phInput}
                placeholder="Enter your phone number"
                inputStyle={{
                  height: 40,
                  width: 300,
                  fontSize: '16px',
                  padding: '15px 0px 10px 70px',
                  borderRadius: '0px',
                  border: 'none',
                }}
                buttonStyle={{
                  borderRadius: '8px 0px 0px 8px',
                  border: 'none',
                  borderRight: '1px solid grey',
                  backgroundColor: '#fff',
                  width: '57px',
                  className: 'button',
                }}
                dropdownStyle={{ width: '290px' }}
                inputProps={{
                  name: 'phoneNumber',
                  required: true,
                }}
                onChange={onPhoneNumberChange}
              />
              <input type="hidden" {...register('countryCode')} />
              {errors.phoneNumber?.type === 'required' && (
                <p className="form_err_msg">Phone number is required</p>
              )}
              {errors.phoneNumber?.type === 'pattern' && (
                <p className="err_msg">Please enter only numbers</p>
              )}
              {errors.phoneNumber?.type === 'maxLength' && (
                <p className="err_msg">Please enter only 10 numbers</p>
              )}
              {error && <p className="data_err"> {error && error?.message}</p>}
              {updateSuccessMsg && (
                <p className="success_msg">
                  {' '}
                  {updateSuccessMsg && updateSuccessMsg}
                </p>
              )}
            </li>
            <li className="personal_info-li">
              {updateLoader ? (
                <Button className="personal-info-button" variant="contained">
                  <Loader color="inherit" value={100} size={25} />
                </Button>
              ) : (
                
                active ?
                  <Button
                  sx={{mt:'32px'}}
                    type="submit"
                    className={buttons.Blue_color_Btn}
                    variant="contained"
                  >
                    Update Mobile Number
                  </Button>
                  :
                  <Button
                  sx={{mt:'32px'}}
                    disabled
                    type="submit"
                    className={buttons.Blue_color_disabled_Btn}
                    variant="contained"
                  >
                    Update Mobile Number
                  </Button>
              )}
            </li>
          </form>
          {validateOtpDialog && (
        <ValidateOtpDialog
          fetchAccountSettingsData={fetchAccountSettingsData}
        />
      )}
          {/* {!isVerifyEmailMobileOtp?.phoneNumber && (
            <li>
              <ValidateOtpForm
                isEmail={false}
                fetchAccountSettingsData={fetchAccountSettingsData}
              />
            </li>
          )} */}
        </ul>
      </Box>
    </>
  );
}

export default UpdateMobile;
