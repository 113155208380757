import { Grid, Typography, Button } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import ColorPicker from "../../../../components/colorPicker";
import "../style.css";
import * as api from "../../../../redux/Api"
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Loader from '../../../../components/loader';
import { handleNotesData } from '../../../../redux/Slice/user';
import { buttonStyles } from '../../../../Themes/buttonTheme';
function AddNotesDialog({addOpen,setAddOpen}) {
    const [color, setColor] = useState('#d36618');
    const dispatch = useDispatch()
    const [updateLoader, setUpdateLoader] = useState(false);
    const [updateError, setUpdateError] = React.useState('');
    const { selectedSocialAccountId } = useSelector((state) => state.user)
  
    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
        reset
    } = useForm();
    const buttons = buttonStyles();
    const getNotesData = async () => {
        await api.getNotes(selectedSocialAccountId).then((res) => {
            dispatch(handleNotesData(res.data))
        })
    }
    const onAddSubmit = async (data) => {
      
        let Obj = {
            ...data,
            color: color,
            social_id: selectedSocialAccountId
        };
        await api.createNotes(Obj).then((res) => {
            if (res.status === 200) {
                getNotesData()
                setUpdateLoader(false);
                handleClose();
                reset()
            }
        }).catch((err) => {
            setUpdateLoader(false);
            if (err.response) {
                setUpdateError(err.response?.data);
            } else {
                setUpdateError({ message: 'Network Error' });
            }
        });

    }
  
    const handleClose = () => {
        setAddOpen(false);
        getNotesData()
    };
  return (
    <Dialog open={addOpen} onClose={handleClose}>
    <DialogTitle className="notes-dialogue">
        Add Notes
        <IconButton onClick={handleClose}>
            <CloseIcon />
        </IconButton>
    </DialogTitle>

    <DialogContent className="notes-body">
        <form onSubmit={handleSubmit(onAddSubmit)}>
            <Grid mb="32px" display="flex">
            <label className='label-style' style={{marginTop:"8px"}}>Color</label>
                {/* <Typography className="notes-body-title">Color</Typography> */}
                <ColorPicker color={color} setColor={setColor} />
            </Grid>
            <Grid mb="16px">
            <label className='label-style'>Name</label>
                {/* <Typography className="notes-body-title">Name</Typography> */}
                <TextField
                    className="form-input2"
                    type="text"
                    inputProps={{ style: { fontSize: 16, padding: '8px 12px' } }}
                    fullWidth
                    {...register('title')}
                />
            </Grid>
            <Grid>
            <label className='label-style'> Notes</label><br/>
                {/* <Typography className="notes-body-title">
                    Notes
                </Typography> */}
                <TextField
                    multiline
                    rows={4}
                    sx={{mt:"8px"}}
                    maxRows={8}
                    inputProps={{ style: { fontSize: 16 } }}
                    {...register('note')}
                    InputProps={{
                        style: { width: '400px' },
                    }}
                />
            </Grid>
            {updateError && (
                <p className="data_err">
                    {updateError && updateError?.message}
                </p>
            )}

            <DialogActions className='notes-button'>
                <Button onClick={handleClose} color="primary" className={buttons.Grey_color_Btn}>
                    Cancel
                </Button>
                {updateLoader ? (
                    <button className="mail_btn">
                        <Loader color="inherit" value={100} size={25} />
                    </button>
                ) : (
                    <button type="submit" className={buttons.Blue_color_Btn}>
                        Create
                    </button>
                )}

            </DialogActions>
        </form>
    </DialogContent>
</Dialog>
  )
}

export default AddNotesDialog