import React, { useState, useEffect, useMemo } from 'react';
import { Avatar, Drawer, Grid } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Button,
} from '@mui/material';
import * as api from '../../../redux/Api';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Settings from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import '../style.css';
import MenuIcon from '@mui/icons-material/Menu';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { CalendarMonth } from '@mui/icons-material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PeopleIcon from '@mui/icons-material/People';
import CloseIcon from '@mui/icons-material/Close';
import { setCookie, getCookie, removeCookie } from "../../../helpers/cookie/cookie";
import { handleToken, handleRestRedux } from "../../../redux/Slice/user"
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { sidebarStyles } from "../../../Themes/sideBarTheme";
import {
  getBrandSettingData,
  getAllBrandData
} from '../../../redux/reduxThunk';
import SelectSocialDropdown from "../selectSocialDropDown";
import { socialXperia_logo } from '../../../assets';
import socilaXperia_small_logo from '../../../assets/assets/logox.png';



function SideBar() {
  const {
    userData,
    accountSettingsData,
    brandSettingData,
    currentPlanData,
    allBrandData,
  } = useSelector((state) => state.user);
  const sidebarClass = sidebarStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(brandSettingData?.name);
  const [userplanStatus, setUserPlanStatus] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767); // Adjust the breakpoint as needed
    };

    handleResize(); // Check the screen size on initial render

    window.addEventListener('resize', handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);

  const handleSettings = () => {
    setCookie('billing_tab_value', 0);
    setCookie('sidebar_activeItem', "Account settings");
    navigate('/account-settings');
  };

  const sidebar_activeItem = getCookie('sidebar_activeItem');
  const handleListData = (value) => {
    setCookie('sidebar_activeItem', value);
    switch (value) {
      case 'Calendar':
        window.location.href = `${api.scheduleUrl}/calendar`;
        break;
      case 'Media Library':
        window.location.href = `${api.scheduleUrl}/medialibrary`;
        break;
      case 'Reports':
        window.location.href = `${api.analyticsUrl}/reports`;
        break;
      case 'Conversations':
        window.location.href = `${api.analyticsUrl}/conversations`;
        break;
      case 'Social Accounts':
        navigate('/social-account-settings');
        break;
      case 'Members':
        navigate('/members');
        break;
      case 'Brand settings':
        navigate('/brand-settings');
        dispatch(getBrandSettingData(createdBrandId));
        break;
      case 'Add Brand':
        localStorage.setItem('activeStep', 0);
        navigate('/social-brand-accounts');
        break;
      case 'Upgrade Brand':
        setCookie('billing_tab_value', 3);
        navigate('/account-settings');
        break;

      case 'Logout':
        dispatch(handleRestRedux());
        navigate('/sign-in');
        dispatch(handleToken(false));
        removeCookie('token');
        localStorage.clear();
        Object.keys(Cookies.set()).forEach(function (cookieName) {
          Cookies.remove(cookieName);
        });
        break;
      case 'Account settings':
        setCookie('billing_tab_value', 0);
        navigate('/account-settings');
        break;
      default:
        break;
    }
  };
  const handleAddBrand = () => {
    localStorage.setItem('activeStep', 0);
    navigate('/social-brand-accounts');
  };
  const handleUpgradeBrand = () => {
    setCookie('billing_tab_value', 3);
    navigate('/account-settings');
  };
  const handleLogout = () => {
    dispatch(handleRestRedux());
    navigate('/sign-in');
    dispatch(handleToken(false));
    removeCookie('token');
    localStorage.clear();
    Object.keys(Cookies.set()).forEach(function (cookieName) {
      Cookies.remove(cookieName);
    });
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (currentPlanData?.planDetails?.brandLimit > allBrandData?.length) {
      setUserPlanStatus(true);
    }
  };
  const createdBrandId = getCookie('created_brand_id');
  let brandId = '';
  if (userData?.accountType === 'SUPER_ADMIN') {
    if (createdBrandId === undefined) {
      brandId =
        allBrandData && allBrandData?.length > 0 && allBrandData[0]?._id;
    } else {
      brandId = createdBrandId;
    }
  } else {
    if (createdBrandId === undefined) {
      brandId =
        allBrandData && allBrandData?.length > 0 && allBrandData[0]?.brandId;
    } else {
      brandId = createdBrandId;
    }
  }

  if (brandId) {
    setCookie('created_brand_id', brandId);
  }
  useEffect(() => {
    dispatch(getBrandSettingData(brandId));
    dispatch(getAllBrandData(userData?.accountType));
  }, [brandId]);
  useEffect(() => {
    dispatch(getAllBrandData(userData?.accountType));
  }, []);
  useMemo(() => {
    dispatch(getAllBrandData(userData?.accountType));
  }, []);

  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  useEffect(() => {
    dispatch(getBrandSettingData(brandId));
    dispatch(getAllBrandData(userData?.accountType));
    console.log("test useEffect", allBrandData)
  }, [brandId]);
  // useEffect(() => {
  //   dispatch(getAllBrandData(userData?.accountType));
  //   console.log("test useEffect1", allBrandData)
  // }, []);
  // useMemo(() => {
  //   dispatch(getAllBrandData(userData?.accountType));
  //   console.log("test useMemo", allBrandData)
  // }, [])



  return (
    <>
      <Grid className={sidebarClass.sidebar_header} >
        <Grid className={sidebarClass.sidebar_grid}>
          <Box>
            <img src={isSmallScreen ? socilaXperia_small_logo : socialXperia_logo} alt="socialXperia_logo" className={sidebarClass.sidebar_logo} />
          </Box>
          <Box>
            <SelectSocialDropdown brandSettingData={brandSettingData} allBrandData={allBrandData} />
          </Box>
        </Grid>
        {/* <Grid className={sidebarClass.sidebar_grid}>
                    <Box >
                        <NotificationsIcon fontSize='large' className={sidebarClass.avatar} sx={{ color: '#7b7b7b', mr: "24px",mt:"7px" }} />
                    </Box>
                    <Box className={sidebarClass.sidebar_grid}>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            {accountSettingsData?.accountInformation?.profileImage ?
                                <Avatar src={accountSettingsData?.accountInformation?.profileImage} className={sidebarClass.avatar}></Avatar>
                                : <Avatar className={sidebarClass.avatar} >{userData?.emailId ? userData?.emailId[0] : 'M'}</Avatar>}

                        </IconButton>
                  </Box> */}
        <Grid xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} className={sidebarClass.sidebar_grid} >
          {!isSmallScreen && (
            <Box>
              <NotificationsIcon
                fontSize="large"
                className={sidebarClass.avatar}
                sx={{ color: '#7b7b7b', mr: '24px', mt: '7px' }}
              />
            </Box>
          )}
          {isSmallScreen ? (
            ''
          ) : (
            <Box className={sidebarClass.sidebar_grid}>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                {accountSettingsData?.accountInformation?.profileImage ? (
                  <Avatar
                    src={accountSettingsData?.accountInformation?.profileImage}
                    className={sidebarClass.avatar}
                  ></Avatar>
                ) : (
                  <Avatar className={sidebarClass.avatar}>
                    {userData?.emailId ? userData?.emailId[0] : 'M'}
                  </Avatar>
                )}
              </IconButton>
            </Box>
          )}
          {isSmallScreen ? (
            <Box>
              <IconButton
                sx={{ color: 'white', }}
                onClick={() => setOpenDrawer(!openDrawer)}
              >
                {openDrawer ? (
                  <CloseIcon color="primary" />
                ) : (
                  <MenuIcon color="primary" />
                )}
              </IconButton>
            </Box>
          ) : (
            ''
          )}
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                  '&:before': {
                  },
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleSettings}>
              <ListItemIcon>
                <ManageAccountsIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="h6">Account settings</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <Typography variant="h6">Logout</Typography>
            </MenuItem>
          </Menu>
          {isSmallScreen && <Drawer
            anchor="top"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            sx={{ zIndex: 2, mt: "3" }}
          >

            {[
              {},
              {},
              { name: 'Calendar', icon: <CalendarMonth /> },
              { name: 'Media Library', icon: <AddPhotoAlternateIcon /> },
              { name: 'Reports', icon: <AnalyticsIcon /> },
              { name: 'Conversations', icon: <TextsmsIcon /> },
              { name: 'Social Accounts', icon: <AccountCircleOutlinedIcon /> },
              { name: 'Members', icon: <PeopleIcon /> },
              { name: 'Brand settings', icon: <Settings /> },
              { name: 'Account settings', icon: <Settings /> },
              { name: 'Logout', icon: <Logout /> },
            ].map((item, index) => (
              <MenuItem key={item} onClick={handleCloseNavMenu} >
                <ListItem
                  key={index}
                  onClick={() => handleListData(item.name)}
                >
                  <ListItemButton
                    disableRipple
                    sx={{
                      backgroundColor:
                        sidebar_activeItem === item.name
                          ? '#7a40ed'
                          : 'transparent',
                      '&:hover': {
                        backgroundColor:
                          sidebar_activeItem === item.name
                            ? '#7a40ed'
                            : 'transparent',
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 35,

                        color:
                          sidebar_activeItem === item.name
                            ? 'white'
                            : 'inherit',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <Typography
                      variant="h5"
                      sx={{
                        color:
                          sidebar_activeItem === item.name
                            ? 'white'
                            : '#383333',
                      }}
                    >
                      {item.name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </MenuItem>
            ))}
            {/* </Box> */}
          </Drawer>}
        </Grid>
        {/* </Grid> */}
      </Grid>
    </>
  );
}

export default SideBar;
