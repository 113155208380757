import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './collectData.css';
import * as api from '../../../redux/Api';
import { useSelector, useDispatch } from 'react-redux';
import {
  handleUserBrandInfo,
  updateActiveStep,
  updateUser,
} from '../../../redux/Slice/user';
import SelectDropDown from '../../../components/selectDropDown';
import jwt_decode from 'jwt-decode';
import { makeStyles } from '@mui/styles';
import Loader from '../../../components/loader';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import {useStyles} from '../../../Themes/theme';
import CustomTextField from '../../../components/customTextField';

// const useStyles = makeStyles({
  
//   input: {
//     width: '100%',
//     fontSize: 20,
//     fontStyle: 'normal',
//     lineHeight: 'normal',
//     letterSpacing: 'normal',
//     '& input::placeholder': {
//       fontSize: '16px',
//       padding: '10px 0px 0px 0px',
//     },
//   },
//   title: {
//     display: 'flex',
//     justifyContent: 'start',
//     alignItems: 'center',
//     mb: '10px',
//     fontWeight: 800,
//     ml: 64,
//   },
//   subTitle: {
//     display: 'flex',
//     justifyContent: 'start',
//     alignItems: 'center',
//     mb: '50px',
//   },
// });

function CollectData() {
  // console.log(handleNext,"handle");
  const classes = useStyles();
  const { activeStep } = useSelector((state) => state.user);
  const [categoryList, setCategoryList] = React.useState([]);
  const [continueLoader, setContinueLoader] = useState(false);
  const [error, setError] = useState('');
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // Fetching Category List api
  const dataFetchedRef = React.useRef(false);
  const getCategoryData = async () => {
    await api.getBusinessCategory('business_category').then((res) => {
      setCategoryList(res.data);
    });
  };
  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // category list api
    getCategoryData();
  }, []);

  const handleNext = async (data) => {
    console.log('collect', data);
    setContinueLoader(true);
    await api
      .userBrandInfo({
        firstName: data.firstName,
        lastName: data.lastName,
        brandName: data.brandName,
        category: data.category,
      })
      .then(async (res) => {
        if (res.status === 200) {
          setCookie('created_brand_id', res?.data?._id);
          console.log('created_brand_id',res?.data?._id)
          setCookie('created_brand_name',data.brandName)
          await api.brandCreationFinalStep({ onboardingStatus: 'SOCIAL_REQ' });
          dispatch(handleUserBrandInfo(res));
          dispatch(updateActiveStep(parseInt(activeStep + 1)));
          setContinueLoader(false);
        }
      })
      .catch((err) => {
        setContinueLoader(false);
        if (err.response) {
          setError(err.response.data);
        } else {
          setError({ message: 'Network Error' });
        }
      });
  };

  return (
    <>
      <Grid p={{xs:2,sm:4}}>
        <Typography variant="h3" mb={1} fontWeight={550}>
          Let’s set up your account!
        </Typography>
        <Typography variant="h5" mb={2} alignItems="center">
          Tell us about yourself and your organization so we can customize your
          account:
        </Typography>

        <form onSubmit={handleSubmit(handleNext)}>
          <Grid item container xs={12} sm={12} md={10} lg={12}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mb={4}
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5} mt={2}>
              <Typography variant="h5" >
                First Name <span className='require'>*</span>
              </Typography>
                {/* <TextField
                  // defaultValue={user.firstName}
                  className={classes.stepperInput}
                  inputProps={{ style: { fontSize: 16, padding: '10px 12px' } }}
                  placeholder="First Name"
                  variant="outlined"
                  {...register('firstName', { required: true, maxLength: 80 })}
                /> */}
                <CustomTextField
                fieldName="firstName" // Renamed prop from `name` to `fieldName`
                placeholder="First Name"
                {...register('firstName', { required: true, maxLength: 80 })}
              />
                {errors.firstName?.type === 'required' && (
                  <Typography className={classes.err}>First Name is required</Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                mt={2}
                justifyContent="flex-end"
              >
                <Typography variant="h5" >
                Last Name <span className='require'>*</span>
              </Typography>
              <CustomTextField
                fieldName="lastName" // Renamed prop from `name` to `fieldName`
                placeholder="Last Name"
                {...register('lastName', { required: true, maxLength: 80 })}
              />
                {/* <TextField
                  // defaultValue={user.lastName}
                  className={classes.stepperInput}
                  inputProps={{ style: { fontSize: 16, padding: '10px 12px' } }}
                  placeholder="Last Name"
                  variant="outlined"
                  {...register('lastName', { required: true, maxLength: 80 })}
                /> */}
                {errors.lastName?.type === 'required' && (
                  <Typography className={classes.err}>Last Name is required</Typography>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={4}>
              <Typography variant="h5">
                Brand Name <span className='require'>*</span>
              </Typography>
              <CustomTextField
                fieldName="brandName" // Renamed prop from `name` to `fieldName`
                placeholder="Enter Brand Name"
                {...register('brandName', { required: true, maxLength: 80 })}
              />
              {/* <TextField
                className={classes.stepperInput}
                inputProps={{ style: { fontSize: 16, padding: '10px 12px' } }}
                sx={{ width: 1 }}
                {...register('brandName', { required: true, maxLength: 80 })}
                placeholder="Enter Brand Name"
              /> */}
              {errors.brandName?.type === 'required' && (
                <p className="form_err">Brand Name is required</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={5}>
              <Typography variant="h5" >
                What Category describes your Business <span className='require'>*</span>
              </Typography>
              {/* <TextField
                className="input_form2"
                {...register('business_category', { required: true })}
                placeholder="Category of Business"
              /> */}
              <SelectDropDown
                className={classes.dropDown}
                Placeholder="Select Your Business Category"
                name="category"
                control={control}
                menuList={categoryList}
                {...register('category', { required: true })}
              />
              {errors.category?.type === 'required' && (
                <p className="form_err">Business category is required</p>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="flex-end"
            >
              {continueLoader ? (
                <Button>
                  <Loader color="inherit" value={100} size={25} />
                </Button>
              ) : (
                <button type="submit" className={classes.Blue_color_Btn}>
                  continue
                </button>
              )}
              {/* <button type="submit" className="Btttttn1">
                continue
              </button> */}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}

export default CollectData;
