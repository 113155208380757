import { Grid, Typography, Button } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import ColorPicker from "../../../../components/colorPicker";
import "../style.css";
import * as api from "../../../../redux/Api"
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Loader from '../../../../components/loader';
import { handleNotesData } from '../../../../redux/Slice/user';

function UpdateNotesDialog({ updateOpen, setUpdateOpen, notes }) {
    const { selectedSocialAccountId,updatedNoteData } = useSelector((state) => state.user)
    const [color, setColor] = useState(updatedNoteData?.color);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [updateError, setUpdateError] = React.useState('');

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
        reset
    } = useForm();
    const onUpdateSubmit = async (data) => {
        let Obj = {
            ...data,
            color: color,
            social_id: selectedSocialAccountId,
            note_id: notes._id
        };
        await api.updateNotes(Obj).then((res) => {
            if (res.status === 200) {
                getNotesData()
                setUpdateLoader(false);
                handleClose();
                reset()
            }
        }).catch((err) => {
            setUpdateLoader(false);
            if (err.response) {
                setUpdateError(err.response?.data);
            } else {
                setUpdateError({ message: 'Network Error' });
            }
        });

    }
    const getNotesData = async () => {
        await api.getNotes(selectedSocialAccountId).then((res) => {
            dispatch(handleNotesData(res.data))
        })
    }
    const handleClose = () => {
        setUpdateOpen(false);
        getNotesData()
    };
    return (
        <Dialog open={updateOpen} onClose={handleClose}>
            <DialogTitle className="notes-dialogue">
                update Notes
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="notes-body">
                <form onSubmit={handleSubmit(onUpdateSubmit)}>
                    <Grid>
                        <Typography className="notes-body-title">Color</Typography>
                        <ColorPicker color={color || updatedNoteData?.color} setColor={setColor} />
                    </Grid>
                    <Grid>
                        <Typography className="notes-body-title">Name</Typography>
                        <TextField
                            className="form-input2"
                            type="text"
                            inputProps={{ style: { fontSize: 16, padding: '8px 12px' } }}
                            fullWidth
                            defaultValue={updatedNoteData && updatedNoteData?.title} 
                            {...register('title')}
                        />
                    </Grid>
                    <Grid>
                        <Typography className="notes-body-title">
                            Notes
                        </Typography>
                        <TextField
                            multiline
                            rows={4}
                            maxRows={8}
                            inputProps={{ style: { fontSize: 16 } }}
                            defaultValue={updatedNoteData && updatedNoteData?.note} 
                            {...register('note')}
                            InputProps={{
                                style: { width: '400px' },
                            }}
                        />
                    </Grid>
                    {updateError && (
                        <p className="data_err">
                            {updateError && updateError?.message}
                        </p>
                    )}

                    <DialogActions className='notes-button'>
                        <Button onClick={handleClose} color="primary" className="cancel-Button">
                            Cancel
                        </Button>
                        {updateLoader ? (
                            <button className="mail_btn">
                                <Loader color="inherit" value={100} size={25} />
                            </button>
                        ) : (
                            <button type="submit" className="create-button">
                                Update
                            </button>
                        )}

                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateNotesDialog