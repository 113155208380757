import {
  Grid,
  Typography,
  Divider,
  Button,
  Box,
  TextField,
  Dialog,
  Switch,
} from '@mui/material';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import '../style.css';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/loader';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import * as api from '../../../redux/Api/index';
import SuccessModel from '../../../components/successModel';
import {buttonStyles} from '../../../Themes/buttonTheme'
const maxWidth = 'md';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#131bea' : '#131bea',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#131bea',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function AddMemberDialog({ open, onClose, fetchMembersData }) {
  const selected_brand_id = getCookie('created_brand_id');
  const { allSocialAccountData } = useSelector((state) => state.user);
  const buttons=buttonStyles();
  // local states
  const [updateLoader, setUpdateLoader] = React.useState(false);
  const [updateError, setUpdateError] = React.useState('');
  const [updateSuccess, setUpdateSuccess] = React.useState('');
  const [successOpen, setSuccessOpen] = React.useState(false);

  const [selectedValues, setSelectedValues] = React.useState([]);

  // Form handlers
  const {
    register,
    handleSubmit,
    setError,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setUpdateError('');
    let addMemberData = {
      name: data.name,
      emailId: data.emailId,
      brands: {
        brandId: selected_brand_id,
        role: data.role,
        channels: selectedValues,
      },
    };
    await api
      .inviteMemberApi(addMemberData)
      .then((res) => {
        if (res.status === 200) {
          setUpdateLoader(false);
          setUpdateSuccess(
            'Member Added Successfully and Invitation sent to email please sign up to activate account.'
          );
          reset();
          //fetching updated Account Settings data to display
          handleContinueDialogOpen();
          reset();
          // fetchMembersData();
        }
      })
      .catch((err) => {
        setUpdateLoader(false);
        if (err.response) {
          setUpdateError(err.response?.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      });
  };

  const handleContinueDialogOpen = () => {
    setSuccessOpen(true);
  };
  const handleContinueDialogClose = () => {
    handleOnClose();
    fetchMembersData();
    setSuccessOpen(false);
  };

  const handleOnChange = (e) => {};
  const handleSwitchChange = (event, item, socailName) => {
    let newObj = {
      socialId: item._id,
      name: socailName,
    };
    setSelectedValues((prev) =>
      event.target.checked
        ? [...prev, newObj]
        : prev.filter((value) => value.socialId !== item._id)
    );
  };

  const handleOnClose = () => {
    onClose();
    reset();
    setSelectedValues([]);
    setUpdateError('');
    setUpdateSuccess('');
  };

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        onClose={handleOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={maxWidth}
        sx={{width:'60%',ml:'20%'}}

      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={2}
          >
            <Typography variant="h4"  fontWeight={600}>
              Invite Team Member
            </Typography>
            <ClearIcon onClick={handleOnClose} sx={{ cursor: 'pointer' }} />
          </Grid>

          <Divider marginBottom={2} />

          <Grid p={2}>
            <Grid container mb={4} display="flex">
              <Grid ml='32px' lg={6}>
                <Typography variant="h5" marginTop={2} mb={1}>
                  Name
                </Typography>
                <TextField
                  inputProps={{ style: { fontSize: 16, padding: '8px 12px' } }}
                  sx={{ width: 280 }}
                  {...register('name', {
                    required: true,
                    maxLength: 80,
                    onChange: (e) => handleOnChange(e),
                  })}
                  error={!!errors.name}
                />
                {errors.name?.type === 'required' && (
                  <p className="form_err_msg">Name is required</p>
                )}
              </Grid>
              <Grid lg={5}>
                <Typography variant="h5" marginTop={2} mb={1}>
                  Email
                </Typography>
                <TextField
                  inputProps={{ style: { fontSize: 16, padding: '8px 12px' } }}
                  sx={{ width: 280 }}
                  {...register('emailId', {
                    pattern:
                      /^[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                    required: true,
                    onChange: (e) => handleOnChange(e),
                  })}
                  error={!!errors.emailId}
                />
                {errors.emailId?.type === 'required' && (
                  <p className="form_err_msg">Email address is required</p>
                )}
                {errors.emailId?.type === 'pattern' && (
                  <p className="form_err_msg">Enter a valid email address</p>
                )}
              </Grid>
            </Grid>

            

            <Grid container p='8px' mb={2}>
              <Grid display="flex" alignItems="center" ml='26px' lg={3}>
                <Typography variant="h5" fontWeight={500}>Member Role</Typography>
              </Grid>
              <Grid lg={7.5} >
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    name="role"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        {...field}
                      >
                        <Grid
                          mr={2}
                          container
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid display="flex"
                          alignItems="center">
                            <FormControlLabel
                            value="BrandOwner"
                            control={
                              <Radio
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#131bea',
                                  },
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 28,
                                  },
                                }}
                              />
                            }
                          />
                          <Typography variant="h5" ml={-2}>Brand Owner</Typography>
                          
                          </Grid>

                          
                          
                          <Grid display="flex"
                          alignItems="center" >
                            <FormControlLabel
                            value="Editor"
                            control={
                              <Radio
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#131bea',
                                  },
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 28,
                                  },
                                }}
                              />
                            }
                          />
                          <Typography variant="h5" ml={-2}>Editor</Typography>
                          
                            </Grid>
                          
                          <Grid display="flex"
                          alignItems="center">
                            <FormControlLabel
                            value="Guest"
                            control={
                              <Radio
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#131bea',
                                  },
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 28,
                                  },
                                }}
                              />
                            }
                          />
                          <Typography variant="h5" ml={-2}>Guest</Typography>
                          
                            </Grid>
                          
                        </Grid>
                      </RadioGroup>
                    )}
                  />
                  {errors.role?.type === 'required' && (
                    <p className="form_err_msg">Member Role is required.</p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {/* --------------------- */}

            <Divider />
            <Grid container p='16px 16px 0px 16px' mt={2}>
              <Grid display="flex" alignItems="center" ml='20px' lg={4} >
                <Typography variant="h5" fontWeight={500}>Social Profiles</Typography>
              </Grid>
            </Grid>
            {allSocialAccountData &&
              Object.keys(allSocialAccountData).map((key, index) => {
                return (
                  allSocialAccountData[key] !== null &&
                  key !== 'count' && (
                    <>
                      <Grid container p={2}>
                        <Grid display="flex" ml='20px' alignItems="center" lg={4}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                onChange={(event) =>
                                  handleSwitchChange(
                                    event,
                                    allSocialAccountData[key],
                                    key
                                  )
                                }
                                sx={{ m: 1 }}
                              />
                            }
                            {...register('socialId', {
                              required: true,
                            })}
                          />
                          <Typography variant="h5">{key}</Typography>
                        </Grid>
                      </Grid>
                    </>
                  )
                );
              })}
            {errors.socialId?.type === 'required' && (
              <p className="form_err_msg">Social Account is required.</p>
            )}

            {updateError && (
              <p className="data_err">{updateError && updateError?.message}</p>
            )}

            {/* {updateSuccess && (
              <p className="success_msg">{updateSuccess && updateSuccess}</p>
            )} */}
            <Grid container mt={4}>
              <Grid md={8} />

              <Grid md={4} display="flex" justifyContent="space-evenly">
                <Button className={buttons.blackOutLinedBTn} onClick={handleOnClose}>
                  Cancel
                </Button>
                {updateLoader ? (
                  <Button className="Invite_Btn">
                    <Loader color="inherit" value={100} size={25} />
                  </Button>
                ) : (
                  <Button type="submit" className={buttons.Blue_color_Btn}>
                    Invite
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Dialog>
      <SuccessModel
        content={updateSuccess}
        open={successOpen}
        onClose={handleContinueDialogClose}
      />
    </>
  );
}

export default AddMemberDialog;
