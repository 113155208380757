import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBrandSettings,
  getBrandAll,
  getAllSocialAccounts,
  getSocialAccountsSettings,
  getUser,
  getCurrentPlanDetailsApi,
  getMembersApi,
  getUserBrands,
  getAllUnselectedSocialAccounts,
  getTempSocialAccounts
} from '../Api';

export const getBrandSettingData = createAsyncThunk(
  'Brand/brand-settings',
  async (id) => await getBrandSettings(id)
);

export const getAllBrandData = createAsyncThunk(
  'Brand/get-all-brands',
  async (accountType) => {
    if (accountType === 'USER') {
      return await getUserBrands();
    } else {
      return await getBrandAll();
    }
  }
);

export const getAllSocialAccountData = createAsyncThunk(
  'Brand/social-accounts',
  async (id) => await getAllSocialAccounts(id)
);
export const getTempSocialAccountsData = createAsyncThunk(
  'Brand/temp-social-accounts',
  async (id) => await getTempSocialAccounts(id)
);
export const getSocialAccountsSettingsData = createAsyncThunk(
  'Brand/social-account-settings',
  async (data) => await getSocialAccountsSettings(data)
);

export const getUserData = createAsyncThunk(
  'User/user',
  async () => await getUser()
);

export const getCurrentPlanDetailsApiData = createAsyncThunk(
  'User/get-current-plan',
  async () => await getCurrentPlanDetailsApi()
);

export const getMembersApiData = createAsyncThunk(
  'Member/get-Members',
  async (data) => await getMembersApi(data)
);

export const getAllUnselectedSocialAccountData = createAsyncThunk(
  'social-account/get-all-social-accounts',
  async (data) => await getAllUnselectedSocialAccounts(data)
);