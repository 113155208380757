import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Grid, Typography } from '@mui/material';
import './styles.css';

export default function SuccessModel({ content, onClose, open }) {
  return (
    <>
      <Dialog
        open={open}
        // onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h5" fontWeight={700} p={2}>
          Success Message.
        </Typography>

        <Divider />
        <DialogContent p={2}>
          <Grid
            width={350}
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5" sx={{ color: 'green' }}>
              {content}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} className="continueBtn" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
