import React from 'react'
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/system';
const StyledTextField = styled(TextField)(({ theme }) => ({
    marginTop:'8px',
    marginBottom:'8px',
    width: '100%',
    "& .MuiOutlinedInput-root": {
        height: "56px",
        '& fieldset': {
            borderRadius: `8px`,
        },
    },

}));

const CustomTextField = React.forwardRef(({ fieldName,placeholder, onChange, error,maxLength }, ref) => {
    const { register } = useForm();
    return (
        <>
            <StyledTextField
            maxLength={maxLength}
                {...register(fieldName, { required: true })} 
                placeholder={placeholder}
                onChange={onChange}
                error={error}
                inputProps={{ style: { padding: '0px 16px', fontSize: '16px' },
                ref: ref, }}
                id="outlined-basic" variant="outlined" />
        </>
    )
})

export default CustomTextField
