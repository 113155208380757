import React, { useState } from 'react';
import { Avatar, Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { useNavigate } from 'react-router-dom';
import '../style.css';
import { GreetKaroIcon } from '../../../assets';

export default function Success() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const handleRedirect = async () => {
    setCookie('billing_tab_value', 4);
    navigate('/account-settings');
  };
  return (
    <Box className="main_block">
      <Avatar
        src={GreetKaroIcon}
        variant="square"
        sx={{ height: 200, width: 200 }}
      />

      <Button onClick={handleRedirect} className="goBack">
        Go Back to Billing
      </Button>
    </Box>
  );
}
