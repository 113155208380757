import { Grid } from '@mui/material';
import React,{useEffect} from 'react';
import { leftSvg } from '../../../assets';
import { useStyles } from '../../../Themes/theme';
import {useNavigate} from "react-router-dom";
export default function NotFoundPage() {
    const classes = useStyles();
    const navigate = useNavigate();
    useEffect(() => {
        if (navigate.action === "POP") {
          navigate("/home");
        }
      }, [navigate]);
  return (
    <Grid container spacing={0} display='flex' flexDirection='row' height='100vh' >
      <Grid
        xs={6}
        md={6}
        lg={6}
        flex={1}
        className={classes.leftGrid}
      >
        
        <img src={leftSvg} alt="left" className={classes.leftImage} />
      </Grid>
      <Grid 
        xs={6}
        md={6}
        lg={6}
        flex={1}
        className={classes.rightGrid}
      >
         
         <h1>404 Not Found</h1>
      
      </Grid>
    </Grid>
  );
}
