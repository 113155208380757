import React from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

const StyledPassword = styled(OutlinedInput)(({ theme }) => ({
    marginTop:'8px',
    marginBottom:'8px',
    width: '100%',
    height: "56px",
    '& fieldset': {
        borderRadius: `8px`,
    },

}));
const CustomPassWordTextField= React.forwardRef(({ fieldName, onChange, error, placeholder, onClick, onMouseDown, showPassword }, ref) => {
    const { register } = useFormContext(); // Access register from useFormContext

    return (
        <>
            <FormControl sx={{ width: '100%' }}
                variant="standard">
                <StyledPassword
                     placeholder={placeholder}
                    {...register(fieldName, { required: true })} // Register the field with useFormContext                    placeholder={placeholder}
                    inputProps={{ style: { fontSize: '16px', padding: '0px 16px', lineHeight: '10px' } ,ref: ref,}}
                    onChange={onChange}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={onClick}
                                onMouseDown={onMouseDown}
                                edge="end"
                            >
                                {showPassword ? < Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </>
    )
})

export default CustomPassWordTextField