import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/loader';
import {
  setCookie,
  getCookie,
  removeCookie,
} from '../../../helpers/cookie/cookie';
import { useStyles } from '../../../Themes/theme';
import { buttonStyles } from '../../../Themes/buttonTheme';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#7a40ed' : '#308fe8',
  },
}));

const socialChannelsList = [
  'Facebook',
  'Instagram',
  'Linkedin',
  'Pinterest',
  'Youtube',
  'Google My Business',
];
function Brands({
  currentPlanData,
  currentPlanLoader,
  allSocialAccountLoading,
  allSocialAccountData,
  allBrandData,
  allBrandLoading,
  setValue,
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    activeStep,
    brandSettingLoading,
    brandSettingData,
    membersLoader,
    membersData,
  } = useSelector((state) => state.user);
  const classes = useStyles();
  const buttons = buttonStyles();
  const handleAddbrands = () => {
    navigate('/social-brand-accounts');
  };

  const handleUpgradePlan = () => {
    setValue(3);
    // console.log('billing');
    setCookie('billing_tab_value', 3);
    // navigate('/account-settings');
  };
  return (
    <>
      {/* <Grid
        sx={{
          p: { xs: '16px 8px', md: '32px' },
          ml: { xs: '16px', md: '35px' },
          mr: { xs: '16px', md: '35px' },
          width: { xs: '90%', sm: '100%', md: '120%', lg: '140%' },
        }}
        bgcolor="#ffffff"
        borderRadius={1}
      >
        {currentPlanLoader ||
        allSocialAccountLoading ||
        allBrandLoading ||
        brandSettingLoading ||
        membersLoader ? (
          <Box className="loader_div">
            <Loader color="inherit" value={100} size={40} />
          </Box>
        ) : (
          <>
            <Grid
              container
              sx={{ display: { xs: 'block', sm: 'flex', md: 'flex' } }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid xs={12} md={4} lg={4}>
                <Typography
                  mb="5px"
                  variant="h5"
                  sx={{ fontSize: '20px' }}
                  fontWeight={600}
                >
                  Brands
                </Typography>
                <Typography variant="h6">
                  Brands and the user information
                </Typography>
              </Grid>
              <Grid xs={12} md={4} lg={3}>
                <Typography
                  mb="7px"
                  sx={{ fontSize: '20px' }}
                  variant="h6"
                  fontWeight={600}
                >
                  {/* 1/3 Brands */}
      {/* {`${allBrandData && allBrandData?.length}/${
                    currentPlanData?.planDetails?.brandLimit
                  } Brands`}
                </Typography>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    (allBrandData?.length /
                      (allBrandData &&
                        currentPlanData?.planDetails?.brandLimit)) *
                    100
                  }
                />
              </Grid>
              {currentPlanData?.planDetails?.brandLimit >
              allBrandData?.length ? (
                <Grid
                  xs={12}
                  md={4}
                  lg={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    className={buttons.Blue_color_Btn}
                    onClick={handleAddbrands}
                  >
                    Add Brand
                  </Button>
                </Grid>
              ) : (
                <Grid
                  lg={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    className={buttons.Blue_color_Btn}
                    onClick={handleUpgradePlan}
                    sx={{ mt: 2 }}
                  >
                    Upgrade Plan
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid
                xs={12}
                sm={12}
                lg={6}
                sx={{ p: { xs: '16px 8px', md: '32px 48px 32px 48px' } }}
              >
                <Box border="1px solid #e2e2e2" borderRadius="8px">
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p="10px 25px"
                  >
                    <Grid>
                      <Typography variant="h6">BRAND NAME</Typography>
                      <Typography variant="h3" fontWeight={600}>
                        {brandSettingData?.name}
                      </Typography>
                    </Grid>
                    <Grid>
                      {brandSettingData?.logo ? (
                        <Avatar src={brandSettingData?.logo} />
                      ) : (
                        <Avatar
                          sx={{
                            width: 44,
                            height: 44,
                          }}
                        >
                          {brandSettingData?.name
                            ? brandSettingData?.name[0]
                            : 'B'}
                        </Avatar>
                      )}
                    </Grid>
                  </Grid>
                  <Divider /> */}
      {/* {socialChannelsList.map((item) => {
                    return (
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                        p="10px 25px"
                      >
                        <Grid lg={4}>
                          <Typography variant="h5">{item}</Typography>
                        </Grid>
                        <Grid
                          sx={{
                            height: '20px',
                            width: '50px',
                            bgcolor: '#5ef750',
                            borderRadius: '10px',
                          }}
                        ></Grid>
                      </Grid>
                    );
                  })} */}
      {/* {Object.keys(allSocialAccountData).map((key, index) => {
                    // console.log(`${key}: ${courses[key]}`);
                    // console.log(
                    //   'keyssssssssssssss',
                    //   key,
                    //   allSocialAccountData[key]
                    // );
                    return (
                      key !== 'count' && (
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-between"
                          p="10px 25px"
                        >
                          <Grid lg={4}>
                            <Typography variant="h5">{key}</Typography>
                          </Grid>
                          <Grid
                            sx={{
                              height: '22px',
                              width: '65px',
                              bgcolor:
                                allSocialAccountData[key] !== null
                                  ? '#5ef750'
                                  : 'grey',
                              borderRadius: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {allSocialAccountData[key] !== null ? (
                              <Typography
                                color="#000"
                                variant="p"
                                fontWeight={500}
                              >
                                Active
                              </Typography>
                            ) : (
                              <Typography
                                color="#fff"
                                variant="p"
                                fontWeight={500}
                              >
                                Inactive
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      )
                    );
                  })} */}
      {/* <Divider />
                  <Grid p={4} container>
                    <Grid
                      lg={12}
                      display="flex"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <Typography variant="h5">USERS</Typography>
                      {membersData &&
                        membersData?.length > 0 &&
                        membersData?.map((item, index) => {
                          return item?.profileImg &&
                            item?.profileImg !== null ? (
                            <Tooltip
                              title={
                                // <h2 className="tool_tip_styles">
                                item?.name
                                // </h2>
                              }
                            // placement="top"
                            >
                              <Avatar
                                sx={{ height: 56, width: 56 }}
                                src={item.profileImg}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={item?.name}>
                              <Avatar
                                sx={{
                                  width: 50,
                                  height: 50,
                                  fontSize: 30,
                                }}
                              >
                                {item?.name ? item?.name[0] : ''}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                    </Grid>
                  </Grid> */}
      {/* </Box>
              </Grid>
            </Grid> */}{' '}
      {/* */}
      <Box>
      <Grid
        sx={{
          p: { xs: '24px 24px', md: '32px' },
          ml: { xs: '16px', md: '35px' },
          mr: { xs: '16px', md: '35px' },
          width: { xs: '100%', sm: '90%', md: '100%', lg: '180%' },
        }}
        fullWidth
        bgcolor="#ffffff"
        borderRadius="16px"
      >
        {currentPlanLoader ||
        allSocialAccountLoading ||
        allBrandLoading ||
        brandSettingLoading ||
        membersLoader ? (
          <Box className="loader_div">
            <Loader color="inherit" value={100} size={40} />
          </Box>
        ) : (
          <>
            <Grid
              container
              sx={{ display: { xs: 'block', sm: 'flex', md: 'flex' } }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid xs={12} md={8} lg={8}>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: '20px', mb: { xs: '28px', sm: '48px' } }}
                    fontWeight={600}
                  >
                    Current Plan
                  </Typography>

                  <Box>
                    <Typography
                      variant="h2"
                      color="#0284fe"
                      sx={{ mb: '16px', fontWeight: '600', textTransform:"capitalize" }}
                    >
                       {`${currentPlanData?.planDetails?.plan} Plan`}
                    </Typography>
                    
                  </Box>
                </Box>
              </Grid>
              {currentPlanData?.planDetails?.brandLimit >
              allBrandData?.length ? (
                <Grid
                  xs={12}
                  md={4}
                  lg={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    className={buttons.Blue_color_Btn}
                    onClick={handleAddbrands}
                  >
                    Add Brand
                  </Button>
                </Grid>
              ) : (
                <Grid
                  lg={4}
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                >
                  <Button
                    className={buttons.Blue_color_Btn}
                    onClick={handleUpgradePlan}
                  
                    sx={{ mt: { xs: '40px', lg: '60px', }, width:{xs:"100%" , sm:"100%", lg:"170px"},  }}
                  >
                    Upgrade Plan
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        sx={{
          p: { xs: '16px 8px', md: '32px' },
          ml: { xs: '16px', md: '35px' },
          mr: { xs: '16px', md: '35px' },
          mt: '16px',
          width: { xs: '100%', sm: '100%', md: '100%', lg: '180%' },
        }}
        fullWidth
        bgcolor="#ffffff"
        borderRadius="16px"
      >
        <Box>
          <Grid>
            {/* {socialChannelsList.map((item) => {
                    return (
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                        sx={{borderRadius: "10px",
                        border: "solid 0.6px #282828",  padding: "24px", marginBottom:"16px"}}
                      >
                        <Grid lg={4}>
                          <Typography variant="h5" sx={{textTransform:"capitalize", fontWeight:"600"}}>{item}</Typography>
                          <Typography variant='h6' sx={{fontWeight:"500", color: "#aeaeae"}}>Acconut Name</Typography>
                        </Grid>
                        <Grid
                          sx={{
                            height: '20px',
                            width: '50px',
                            bgcolor: '#5ef750',
                            borderRadius: '10px',
                          }}
                        >Active</Grid>
                      </Grid>
                    );
                  })} */}
            {Object.keys(allSocialAccountData).map((key, index) => {
              // console.log(`${key}: ${courses[key]}`);
              // console.log(
              //   'keyssssssssssssss',
              //   key,
              //   allSocialAccountData[key]
              // );
              return (
                key !== 'count' && (
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      borderRadius: '10px',
                      padding: '24px',
                      border: 'solid 0.6px #6f767e',
                      marginBottom: '16px',
                    }}
                  >
                    <Grid lg={4}>
                      <Typography
                        variant="h5"
                        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
                      >
                        {key}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: '500', color: '#aeaeae' }}
                      >
                        Acconut Name
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        height: '38px',
                        width: '97px',
                        bgcolor:
                          allSocialAccountData[key] !== null
                            ? '#5ef750'
                            : 'grey',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt:"3px",
                      
                      }}
                    >
                      {allSocialAccountData[key] !== null ? (
                        <Typography color="#000" variant="p" fontWeight={500} >
                          Active
                        </Typography>
                      ) : (
                        <Typography color="#fff" variant="p" fontWeight={500} >
                          Inactive
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )
              );
            })}
          </Grid>
        </Box>
      </Grid>
      </Box>
    </>
  );
}

export default Brands;
