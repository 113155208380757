import React from 'react';
import { Grid, CssBaseline, Toolbar, Box } from '@mui/material';

function StepperContainer({ children }) {
  return (
    <>
      <Grid
        container
        sx={{
          width: '100%',
          maxWidth:'100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {children}
      </Grid>
    </>
  );
}

export default StepperContainer;
