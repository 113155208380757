import openAuthWindow from '../openAuthWindow/openAuthWindow';

// window.location.origin

const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.socialxperia.ai' : 'http://localhost:8080';



export function handleFacebookPageLogin(path) {
  openAuthWindow(`${baseUrl}/api/facebook/page`, path);
}

export function handleFacebookGroupLogin(path) {
  openAuthWindow(`${baseUrl}/api/facebook/group`, path);
}

export function handleInstagramLogin(path) {
  openAuthWindow(`${baseUrl}/api/instagram`, path);
}

export function handleLinkedinLogin(path) {
  openAuthWindow(`${baseUrl}/api/linkedin`, path);
}

export function handlePinterestLogin(path) {
  openAuthWindow(`${baseUrl}/api/pinterest`, path);
}

export function handleMyBusinessLogin(path) {
  openAuthWindow(`${baseUrl}/api/mybusiness`, path);
}

export function handleYoutubeLogin(path) {
  openAuthWindow(`${baseUrl}/api/youtube`, path);
}
