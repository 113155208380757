import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import '../personalForm/personalForm.css';
import { useForm } from 'react-hook-form';
import Loader from '../../../../components/loader';
import {
  handleAccountSettingsLoader,
  handleAccountSettingsData,
} from '../../../../redux/Slice/user';
import { setCookie, getCookie, removeCookie } from '../../../../helpers/cookie/cookie';
import * as api from '../../../../redux/Api/index';
import ValidateOtpForm from '../validateOtpDialog/ValidateOtp';
import { useSelector, useDispatch } from 'react-redux';
import {
  handleValidateOtp,
  handleValidateOtpDialog,
} from '../../../../redux/Slice/user';
import ValidateOtpDialog from '../validateOtpDialog/ValidateOtpDialog';
import { useStyles } from '../../../../Themes/theme';
import { buttonStyles } from '../../../../Themes/buttonTheme';
function UpdateEmail({
  personalData,
  fetchAccountSettingsData,
  isVerifyEmailMobileOtp,
}) {
  const classes = useStyles();
  const buttons = buttonStyles();
  //form handlers
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  //local states
  const [updateLoader, setUpdateLoader] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [updateSuccessMsg, setUpdateSuccessMsg] = useState('');
  const [active, setActive] = useState(false)

  const { validateOtpDialog } = useSelector((state) => state.user);
  // Form on Submit handler
  const onSubmit = async (data) => {
    const emailId =
      personalData && personalData?.emailId ? personalData.emailId : '';
    const newEmailId = data.emailId;
    const isUpdateEmail = true;
    const emailData = { emailId, newEmailId, isUpdateEmail };
    if (emailId !== newEmailId) {
      setUpdateLoader(true);
      try {
        await api.updateEmail(emailData).then((res) => {
          setUpdateLoader(false);
          setUpdateSuccessMsg('Email updated successfully.');
          // after updating fetching updated data to display
          fetchAccountSettingsData();
        });
      } catch (err) {
        setUpdateLoader(false);
        if (err.response) {
          setUpdateError(err.response.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      }
    }
    let type='email'
    let updateObj = { isEmail: false, isMobile: false };
    if (type === 'email') {
      updateObj = { isEmail: true, isMobile: false };
    }
    dispatch(handleValidateOtp(updateObj));
    dispatch(handleValidateOtpDialog(true));
  };

  const handleOnChange = (e) => {
    if (personalData?.emailId !== e.target.value) {
      setActive(true)
    } else {
      setActive(false)
    }
    setUpdateError('');
    setUpdateSuccessMsg('');
  };

  return (
    <>
      {/* <Box> */}
      <ul className="personal_info-upadte-form">
        <form onSubmit={handleSubmit(onSubmit)} className="mobile_form">
          <li>
            <Typography display="flex" variant="h6" className="label-name">
              Email<span className='red'>*</span>
            </Typography>
            <TextField
              className="form-input"
              placeholder="Enter email"
              inputProps={{ style: { fontSize: 16, padding: '12px 16px' } }}
              //   disablex`d={true}
              defaultValue={personalData && personalData?.emailId}
              {...register('emailId', {
                pattern:
                  /^[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                required: true,
                onChange: (e) => handleOnChange(e),
              })}
              error={!!errors.emailId}
            />
            {errors.emailId?.type === 'required' && (
              <p className="form_err_msg">Email address is required</p>
            )}
            {errors.emailId?.type === 'pattern' && (
              <p className="form_err_msg">Enter a valid email address</p>
            )}
            <p className="data_err"> {updateError && updateError?.message}</p>
            <p className="success_msg">
              {' '}
              {updateSuccessMsg && updateSuccessMsg}
            </p>
          </li>

          <li className="personal_info-li">
            {updateLoader ? (
              <Button className="personal-info-button" variant="contained">
                <Loader color="inherit" value={100} size={25} />
              </Button>
            ) : (
              
              active ?
                <Button
                  sx={{mt:'32px'}}
                  type="submit"
                  className={buttons.Blue_color_Btn}
                  variant="contained"
                >
                  Update New Email
                </Button>
                :
                <Button
                sx={{mt:'32px'}}
                disabled
                  type="submit"
                  className={buttons.Blue_color_disabled_Btn}
                  variant="contained"
                >
                  Update New Email
                </Button>


            )}
          </li>
        </form>
        {validateOtpDialog && (
        <ValidateOtpDialog
          fetchAccountSettingsData={fetchAccountSettingsData}
        />
      )}
        {/* {!isVerifyEmailMobileOtp?.emailId && (
          <li>
            <ValidateOtpForm
              isEmail={true}
              fetchAccountSettingsData={fetchAccountSettingsData}
            />
          </li>
        )} */}
      </ul>
    </>
  );
}

export default UpdateEmail;
