import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Button, Icon, Typography, Box, Grid } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

function TableComponent({
  Billing,
  Billingheading,
}) {



  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ height: 200, }} size="small"  aria-label="a dense table">
          <TableHead  >
            {Billingheading?.map((item, index) => {
              return (
                <TableRow key={index} sx={{ height: 60, }}>
                  <TableCell align="center">
                    <Typography variant="h4"  fontWeight={600}  mt="42px">
                      {item.Date}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h4" fontWeight={600}  mt="42px">
                      {item.Amount}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h4" fontWeight={600}  mt="42px">
                      {item.Button}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableHead>

          <TableBody>
            {Billing &&
              Billing?.length > 0 &&
              Billing?.map((item, id) => {
                return (
                  <TableRow key={id}>
                    <TableCell align="center" component="th" scope="row">
                      <Typography variant="h5" mb={3}>
                        {/* {item.Date} */}
                        {item.receiptId}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="h5" mb={3}>
                        {item.amount}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h5" mb={3} >
                        {
                          item.invoicePdf?.length > 0 ? <Button
                            variant="contained"
                            sx={{cursor:"pointer",}}
                            onClick={() => window.location.href = item.invoicePdf}
                          >
                            Download
                          </Button> : <Button disabled>Download</Button>
                        }
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableComponent;
