import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, Box, Button, Divider, Grid, Typography } from '@mui/material';
import { GreetKaroIcon } from '../../../../assets';
import ClearIcon from '@mui/icons-material/Clear';
import '../Billing.css';
function PauseSubscription({ open, onClose, fetchCurrentPlanDetailsData }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Typography variant="h5" marginBottom={1} fontWeight={600}>
            Pause Subscription
          </Typography>
          <ClearIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
        </Grid>

        <Divider mb={2} />
        <DialogContent p={2}>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid lg={5} p={2}>
              <Avatar
                src={GreetKaroIcon}
                variant="square"
                sx={{ height: 200, width: 200 }}
              />
            </Grid>
          </Grid>
          <Typography variant="h5" fontWeight={600} mb={2}>
            You will be able to use the paid version of SocialXperia
            <br /> until the renewal date.
          </Typography>
          <Typography variant="h6" mb={2}>
            On The end of the date, all paid features will be locked and you
            will no longer charged.
          </Typography>
          <Typography variant="h6" mb={2}>
            You also wont be able to make changes to your plan, unless you
            restart it <br />
            and pay the renewal price first.
          </Typography>
          <Box
            width={'95%'}
            sx={{
              bgcolor: 'beige',
              borderRadius: '8px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" mr={1} ml={2}>
              Automatic pause date:
            </Typography>
            <Typography variant="h6" fontWeight={700}>
              1/4/2023
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container display="flex" justifyContent="space-around" p={2}>
            <Button className="pauseBtn" onClick={onClose}>
              Go Back
            </Button>
            <Button className="cancelBtn">Pause Subscription</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PauseSubscription;
