import { Grid, Radio, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import './confirmotp.css';
import { Link } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@mui/material';
import {
  verifyOtp,
  updateEmail,
  updatePhoneNumber,
  getUser,
  resentEmailOtp,
  resentMobileOtp,
  brandCreationFinalStep,
} from '../../../redux/Api/index';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateUser,
  handleChangeOtpModal,
  handleChangeEmailInfo,
  updateUserLoader,
  handleToken,
} from '../../../redux/Slice/user';
import OtpModal from './otpModal';
import jwt_decode from 'jwt-decode';
import Loader from '../../../components/loader';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { useStyles } from '../../../Themes/theme';
import { FinalImg } from '../../../assets';
import { getUserData } from '../../../redux/reduxThunk';
import { headerStyles } from '../../../Themes/headerTheme';
import { inputStyles } from '../../../Themes/InputTheme';
import { buttonStyles } from '../../../Themes/buttonTheme';
import CustomTextField from '../../../components/customTextField';

export default function ConformOtp() {
  const classes = useStyles();
  const [newEmail, setNewEmail] = useState('');
  const [number, setNumber] = useState('');
  const [error, setError] = useState('');
  const [loginLoader, setLoginLoader] = useState(false);
  let { userData, userLoader, isOtpModal } = useSelector((state) => state.user);
  userLoader = false;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();

  const [val, setVal] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openNumber, setOpenNumber] = useState(false);
  const [resentOtpLoader, setResentOtpLoader] = useState(false);
  const [resentSuccessMsg, setResentSuccessMsg] = useState('');
  const [resentMobileOtpLoader, setResentMobileOtpLoader] = useState(false);
  const [resentMobileSuccessMsg, setResentMobileSuccessMsg] = useState('');

  const headerClass = headerStyles();
  const inputClass = inputStyles();
  const buttonClass = buttonStyles()
  const handleClickOpen = (mode) => {
    let updateObj = { isEmail: false, isMobile: false };
    if (mode === 'email') {
      updateObj = { isEmail: true, isMobile: false };
    } else if (mode === 'mobile') {
      updateObj = { isEmail: false, isMobile: true };
    }
    dispatch(handleChangeOtpModal(true));
    dispatch(handleChangeEmailInfo(updateObj));
  };

  // on reload fetching data from local storage and assigning to redux
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    dispatch(getUserData());
  }, []);

  const handleResentOtp = async () => {
    setResentOtpLoader(true);
    setResentSuccessMsg('');
    setError('');
    try {
      await resentEmailOtp().then((res) => {
        setResentSuccessMsg(res?.data);
        setResentOtpLoader(false);
      });
    } catch (err) {
      setResentOtpLoader(false);
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const handleMobileResentOtp = async () => {
    setResentMobileOtpLoader(true);
    setResentMobileSuccessMsg('');
    setError('');
    try {
      await resentMobileOtp().then((res) => {
        setResentMobileSuccessMsg(res?.data);
        setResentMobileOtpLoader(false);
      });
    } catch (err) {
      setResentMobileOtpLoader(false);
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const onSubmit = async (data) => {
    let userToken = getCookie('token');
    // console.log('resssssssssssssss userToken', userToken);
    setLoginLoader(true);
    await verifyOtp(data)
      .then(async (res) => {
        // console.log('resssssssssssssss', res, userToken);
        if (res.status === 200) {
          setLoginLoader(false);
          if (res?.data?.accountType === 'USER') {
            // await brandCreationFinalStep({ onboardingStatus: 'COMPLETED' });
            dispatch(handleToken(true));
            setCookie('token', userToken);
            navigate('/home');
            dispatch(getUserData());
          } else {
            await brandCreationFinalStep({ onboardingStatus: 'WORKSPACE_REQ' });
            dispatch(handleToken(true));
            localStorage.setItem('activeStep', 0);
            navigate('/social-brand-accounts');
          }
        }
      })
      .catch((err) => {
        setLoginLoader(false);
        if (err.response) {
          setError(err.response?.data);
        } else {
          setError({ message: 'Network Error' });
        }
      });
  }; //
  // console.log('user', userData);
  return (
    <Grid
      container
      height="100vh"
    >
      <Grid xs={0}
        sm={6}
        md={7} className={classes.leftGrid}>
        <img src={FinalImg} alt="left" className={classes.leftImage} />
      </Grid>
      {/**** Page Loader ******/}
      {userLoader ? (
        <Grid item xs={12} >
          <Box className="loader_sec">
            <Loader color="inherit" value={100} size={40} />
          </Box>
        </Grid>
      ) : (
        <Grid
          xs={12}
          sm={6}
          md={5}
          className={classes.rightGrid}
          container
        >
          <Grid xs={10} sm={9} mt={4} mb={4}>
            <Typography
              className={headerClass.forgotPasswordHeading}
            >
              Verify your Email & Mobile  Number
            </Typography>
            <Typography className={headerClass.subheading}>
              Enter the OTP sent to your mobile number and
              Email.
            </Typography>
            <Grid display="flex" alignItems="center" >
              <Typography variant="h5" sx={{ fontWeight: 600 }} >
                {userData && userData?.emailId ? userData?.emailId : ''}
              </Typography>
              <Button
                variant='text'
                size="small"
                onClick={() => handleClickOpen('email')}
              >
                Edit
              </Button >
            </Grid>
            <Grid display="flex" alignItems="center" >
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {userData && userData?.phoneNumber
                  ? userData?.phoneNumber
                  : ''}
              </Typography>
              <Button
                variant='text'
                size="small"
                onClick={() => handleClickOpen('mobile')}
              >
                Edit
              </Button>
            </Grid>
            {/* <img src={logox} alt="logo" className={classes.logo} /> */}
            <form onSubmit={handleSubmit(onSubmit)} >
                <Typography variant='h5' className={headerClass.label}> 
                 Email OTP <span className={headerClass.require}>*</span>
                 </Typography>

              <CustomTextField
                fieldName="emailIdOtp" // Renamed prop from `name` to `fieldName`
                placeholder="Enter email otp"
                classes={inputClass.input}
                {...register('emailIdOtp', {
                  required: true,
                })}
              />
              <Grid display="flex" justifyContent="space-between" alignItems={'center'}>
                <Grid>
                  {errors.emailIdOtp && (
                    <Typography className={classes.err}>This field is required</Typography>
                  )}
                </Grid>
                <Grid mr='4px'>
                  {resentOtpLoader ? (
                    <Box className="resent_loader">
                      <Loader color="blue" value={100} size={15} />
                    </Box>
                  ) : (
                    <Box className="resent_div">
                      <Typography
                        variant='body1'
                        onClick={() => handleResentOtp()}
                        fontWeight={500}
                      >
                        <span className="resend_otp">Resend OTP</span>
                      </Typography>
                      {resentSuccessMsg && (
                        <Typography
                          variant="p"
                          component="h6"
                          className="resend_otp_success"
                          marginLeft={2}
                        >
                          {resentSuccessMsg}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Typography variant='h5' className={headerClass.label}>
                Mobile Number OTP <span className='require'>*</span>
              </Typography>
              <CustomTextField
                fieldName="phoneNumberOtp" // Renamed prop from `name` to `fieldName`
                placeholder="Phone number otp"
                classes={inputClass.input}
                {...register('phoneNumberOtp', {
                  required: true,
                })}
              />
              <Grid display="flex" justifyContent="space-between" alignItems={'center'} >
                <Grid>
                  {errors.phoneNumberOtp && (
                    <Typography className={classes.err}>This field is required</Typography>
                  )}
                </Grid>
                <Grid>
                  {resentMobileOtpLoader ? (
                    <Box className="resent_loader">
                      <Loader color="blue" value={100} size={15} />
                    </Box>
                  ) : (
                    <Box >
                      <Typography
                        variant='body1'
                        onClick={() => handleMobileResentOtp()}
                      >
                        <span className="resend_otp">Resend OTP</span>
                      </Typography>
                      {resentMobileSuccessMsg && (
                        <Typography
                          variant='body1'
                          className="resend_otp_success"
                          marginLeft={2}
                        >
                          {resentMobileSuccessMsg}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Typography variant='body1' className={classes.err}> {error && error?.message}</Typography>
              <Box  >

                {loginLoader ? (
                  <Button
                    sx={{ width: '100%', mt: 4 }}
                    variant='primary'
                    size='large'
                    disableRipple >
                    <Loader color="inherit" value={100} size={25} />
                  </Button>
                ) : (
                  <Button
                    sx={{ width: '100%', mt: 6 }}
                    variant='primary'
                    size='large'
                    disableRipple
                    type="submit"
                  >
                    Verify
                  </Button>
                )}
              </Box >
            </form>
          </Grid>
        </Grid>
      )}

      {isOtpModal && <OtpModal />}
    </Grid>
  );
}
