import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Divider, Typography } from '@mui/material';
import '../personalForm/personalForm.css';
import { removeAccountAPI } from '../../../../redux/Api/index';
import * as api from '../../../../redux/Api/index';
import Loader from '../../../../components/loader';
import { useNavigate } from 'react-router-dom';
import { setCookie, getCookie, removeCookie } from '../../../../helpers/cookie/cookie';
import { handleToken } from '../../../../redux/Slice/user';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

export default function HandleDeleteDialog({ open, onClose }) {
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState('');
  const [deleteSuccess, setDeleteSuccess] = React.useState('');

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeleteAccount = async () => {
    setDeleteLoader('true');
    await api
      .removeAccountAPI()
      .then((res) => {
        if (res.status === 200) {
          setDeleteLoader(false);
          setDeleteSuccess('Account Deleted Successfully.');
          navigate('/sign-in');
          dispatch(handleToken(false));
          removeCookie('token');
          // Cookies.remove('token')
          //fetching updated Account Settings data to display
          // fetchAccountSettingsData();
        }
      })
      .catch((err) => {
        setDeleteLoader(false);
        if (err.response) {
          setDeleteError(err.response?.data);
        } else {
          setDeleteError({ message: 'Network Error' });
        }
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h4" fontWeight={700} p="16px 24px">
          Confirm to Delete Account
        </Typography>
        <Divider />
        <DialogContent>
          <Typography variant="h6" p={1}>
            Are you sure you want to delete the Account <br />
            If you delete the Account all Progress and changes will be gone.
          </Typography>
        </DialogContent>
        {deleteError && (
          <p className="data_err">{deleteError && deleteError?.message}</p>
        )}

        {deleteSuccess && (
          <p className="success_msg">{deleteSuccess && deleteSuccess}</p>
        )}
        <DialogActions>
          <Button onClick={onClose} className="cancelDialogbtn">
            Cancel
          </Button>
          {deleteLoader ? (
            <Button className="deleteDialogbtn">
              <Loader color="inherit" value={100} size={25} />
            </Button>
          ) : (
            <Button
              onClick={handleDeleteAccount}
              className="deleteDialogbtn"
              autoFocus
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
