import React, { useState } from 'react';
import { Grid } from '@mui/material';
import '../pages/homepage/style.css';
import SideBar from '../components/sideBar/header';
import LeftBar from '../components/sideBar';
import BrandSetting from './brandSetting/BrandSettingTabs';
import SocialAccountSetting from './socialAccountSettings/SocialAccountSettingTabs';
import Member from './members/Member';
import Homepage from '../pages/homepage/Home';
import {
  handleAccountSettingsData,
  handleAccountSettingsLoader,
  handleGetNotificationsLoader,
  handleNotificationsData,
  handleDefaultBrand,
  handleCurrentPlanLoader,
  handleCurrentPlanData,
  handleBillingPlanData,
  handleBillingPlanLoader,
  handleInvoiceReceiptsLoader,
  handleInvoiceReceiptsData,
  handleMembersLoader,
  handleMembersData,
  handleSelectedSocialAccountId
} from '../redux/Slice/user';
import {
  getAllBrandData,
  getAllSocialAccountData,
  getSocialAccountsSettingsData,
  getBrandSettingData
} from '../redux/reduxThunk';
import { useSelector, useDispatch } from 'react-redux';
import { setCookie, getCookie, removeCookie } from '../helpers/cookie/cookie';
import * as api from '../redux/Api';
import AddSocialChannel from './auth/addSocialChannel';
import SuccessPage from './successPage/Success';
import CancelPage from './CancelPage/cancelPage';
import { useLocation } from "react-router-dom";
import './style.css'
import AccountSetting from './accountSetting/AccountSettingTabs';

function Navigation({ title }) {
  console.log("title",title)
  const {
    accountSettingsData,
    accountSettingsLoader,
    notificationsData,
    notificationsLoader,
    currentPlanData,
    currentPlanLoader,
    selectedSocialAccountId,
    allSocialAccountLoading,
    allSocialAccountData,
    allBrandData,
    allBrandLoading,
    invoiceData,
    invoiceLoader,
    userData,
    CurrentPlanDetailsData
  } = useSelector((state) => state.user);
  const selected_brand_id = getCookie('created_brand_id');
  // local states
  const [error, setError] = useState('');
  const [is_update, setIs_update] = useState(false);
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const socialAccounts = params.get("socialAccounts");

  const fetchAccountSettingsData = async () => {
    dispatch(handleAccountSettingsLoader(true));
    try {
      await api.accountSettingsApi().then((res) => {
        dispatch(handleAccountSettingsData(res?.data));
        dispatch(handleAccountSettingsLoader(false));
      });
    } catch (err) {
      dispatch(handleAccountSettingsLoader(false));
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const fetchNotificationsData = async () => {
    dispatch(handleGetNotificationsLoader(true));
    try {
      await api.getNotificationsApi().then((res) => {
        dispatch(handleNotificationsData(res?.data));
        dispatch(handleGetNotificationsLoader(false));
      });
    } catch (err) {
      dispatch(handleGetNotificationsLoader(false));
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const fetchCurrentPlanDetailsData = async () => {
    dispatch(handleCurrentPlanLoader(true));
    try {
      await api.getCurrentPlanDetailsApi().then((res) => {
        dispatch(handleCurrentPlanData(res?.data));
        dispatch(handleCurrentPlanLoader(false));
      });
    } catch (err) {
      dispatch(handleCurrentPlanLoader(false));
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const fetchBillingPlanDetailsData = async () => {
    dispatch(handleBillingPlanLoader(true));
    try {
      await api.getPlanDetailsApi().then((res) => {
        dispatch(handleBillingPlanData(res?.data));
        dispatch(handleBillingPlanLoader(false));
      });
    } catch (err) {
      dispatch(handleBillingPlanLoader(false));
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const fetchInvoiceReceiptsData = async () => {
    dispatch(handleInvoiceReceiptsLoader(true));
    try {
      await api.getInvoiceReceiptsApi().then((res) => {
        dispatch(handleInvoiceReceiptsData(res?.data));
        dispatch(handleInvoiceReceiptsLoader(false));
      });
    } catch (err) {
      dispatch(handleInvoiceReceiptsLoader(false));
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const fetchMembersData = async () => {
    const data = {
      brand_id: selected_brand_id,
    };
    dispatch(handleMembersLoader(true));
    try {
      await api.getMembersApi(data).then((res) => {
        dispatch(handleMembersData(res?.data));
        dispatch(handleMembersLoader(false));
      });
    } catch (err) {
      dispatch(handleMembersLoader(false));
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const fetchBrandSettingsData = () => {
    const socialAccountSettingsData = {
      social_id: socialAccounts,
      brand_id: selected_brand_id,
    };
    dispatch(getAllBrandData(userData?.accountType));
    dispatch(getAllSocialAccountData(selected_brand_id));
    dispatch(getBrandSettingData(selected_brand_id))
    dispatch(getSocialAccountsSettingsData(socialAccountSettingsData));
    dispatch(handleSelectedSocialAccountId(socialAccounts));
  };
setCookie("socialAccountCount",allSocialAccountData?.count)
setCookie("userPlanDetails",CurrentPlanDetailsData?.planDetails?.socialAccountLimit)
  React.useMemo(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchAccountSettingsData();
    fetchNotificationsData();
    fetchBrandSettingsData();
    fetchCurrentPlanDetailsData();
    fetchInvoiceReceiptsData();
    fetchMembersData();
    fetchBillingPlanDetailsData();
    if (title === 'Social Accounts') {
      setIs_update(true);
    }
  }, []);

  return (
    <>
      <SideBar  />
      <Grid
        container-fluid
        columns={12}
        spacing={2}
        // display="flex"
        className="account-setting-tab"
      >
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2} className='left-bar'>
          <LeftBar />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>                                                                                         
          {title === 'Account Settings' ? (
            <>
           
            <AccountSetting
              accountSettingsData={accountSettingsData}
              accountSettingsLoader={accountSettingsLoader}
              notificationsData={notificationsData}
              notificationsLoader={notificationsLoader}
              currentPlanData={currentPlanData}
              currentPlanLoader={currentPlanLoader}
              fetchAccountSettingsData={fetchAccountSettingsData}
              fetchNotificationsData={fetchNotificationsData}
              fetchCurrentPlanDetailsData={fetchCurrentPlanDetailsData}
              allSocialAccountLoading={allSocialAccountLoading}
              allSocialAccountData={allSocialAccountData}
              allBrandData={allBrandData}
              allBrandLoading={allBrandLoading}
              invoiceData={invoiceData}
              invoiceLoader={invoiceLoader}
            />
            </>
          ) : null}
          {title === 'Brand Settings' ? <BrandSetting /> : null}
          {title === 'Social Account Settings' ? (
            <SocialAccountSetting />
          ) : null}
          {title === 'Brand Members' ? (
            <Member fetchMembersData={fetchMembersData} />
            // <ComingSoonComp />
          ) : null}
          {title === 'Dashboard' ? <Homepage /> : null}
          {title === 'Social Accounts' ? (
            <AddSocialChannel is_update={is_update} />
          ) : null}
          {title === 'Success Page' ? <SuccessPage /> : null}
          {title === 'Cancel Page' ? <CancelPage /> : null}
        </Grid>
      </Grid>
    </>
  );
}

export default Navigation;
