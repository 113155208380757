import { Divider, Grid, Typography, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import './style.css';
import { useForm } from 'react-hook-form';
import {
  updatePasswordApi,
  temporaryAccountAccessApi,
  twoFactorAuthenticationApi,
} from '../../../redux/Api/index';
import Checkbox from '@mui/material/Checkbox';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { handleAccountSettingsData } from '../../../redux/Slice/user';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from '../../../Themes/theme';
import { buttonStyles } from '../../../Themes/buttonTheme';
import Loader from '../../../components/loader';
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'sky-blue',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #000',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    color: '#000', //color change added here
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ChangePassword = ({ securityInfo }) => {
  //local states
  const [matchPassword, setMatchPassword] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [updateLoader, setUpdateLoader] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState('');

  const [accountAccessError, setAccountAccessError] = useState('');
  const [accountAccessLoader, setAccountAccessLoader] = useState(false);
  const [accountAccessSuccess, setAccountAccessSuccess] = useState('');
  const [accountAccessCheck, setAccountAccessCheck] = useState(
    securityInfo?.temporaryAccessEnabled
  );
  const [twoFactorActive, setTwoFactorActive] = useState(
    securityInfo?.twoFactorAuth?.isEnabled
  );
  const [twoFactorCheck, setTwoFactorCheck] = useState(
    securityInfo?.twoFactorAuth?.twoFactorAuthEnabled
  );
  const classes = useStyles();
  const buttons = buttonStyles();
  // form handlers
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  // Form on Submit
  const onSubmit = async (data) => {
    setUpdateSuccess('');
    setUpdateError('');
    if (data.newPassword !== data.confirmPassword) {
      setMatchPassword(true);
    } else {
      setMatchPassword(false);
      // api call here
      let updateObj = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      setUpdateLoader(true);
      await updatePasswordApi(updateObj)
        .then((res) => {
          if (res.status === 200) {
            setUpdateLoader(false);
            setUpdateSuccess('Password Updated Successfully.');
            reset();
          }
        })
        .catch((err) => {
          setUpdateLoader(false);
          if (err.response) {
            setUpdateError(err.response?.data);
          } else {
            setUpdateError({ message: 'Network Error' });
          }
        });
    }
  };

  const handleActiveTwoFactor = async (event) => {
    console.log('handleSwitchChange', event.target.checked);
    setTwoFactorActive(event.target.checked);
    let data = {
      isEnabled: event.target.checked,
      twoFactorAuthEnabled: twoFactorCheck,
    };
    await twoFactorAuthenticationApi(data)
      .then((res) => {
        if (res.status === 200) {
          setAccountAccessSuccess(
            'Temporary Account Access Updated Successfully.'
          );
          dispatch(handleAccountSettingsData(res?.data));
        }
      })
      .catch((err) => {
        // setAccountAccessCheck(!event.target.checked)
        if (err.response) {
          setAccountAccessError(err.response?.data);
        } else {
          setAccountAccessError({ message: 'Network Error' });
        }
      });
  };

  const handleCheckTwoFactor = async (event) => {
    setTwoFactorCheck(event.target.checked);
    let data = {
      isEnabled: twoFactorActive,
      twoFactorAuthEnabled: event.target.checked,
    };
    await twoFactorAuthenticationApi(data)
      .then((res) => {
        if (res.status === 200) {
          setAccountAccessSuccess(
            'Temporary Account Access Updated Successfully.'
          );
          dispatch(handleAccountSettingsData(res?.data));
        }
      })
      .catch((err) => {
        // setAccountAccessCheck(!event.target.checked)
        if (err.response) {
          setAccountAccessError(err.response?.data);
        } else {
          setAccountAccessError({ message: 'Network Error' });
        }
      });
  };

  const handleSwitchChange = async (event) => {
    setAccountAccessError('');
    console.log('handleSwitchChange', event.target.checked);
    setAccountAccessCheck(event.target.checked);
    let data = {
      temporaryAccessEnabled: event.target.checked,
    };
    setAccountAccessLoader(true);
    await temporaryAccountAccessApi(data)
      .then((res) => {
        if (res.status === 200) {
          setAccountAccessLoader(false);
          setAccountAccessSuccess(
            'Temporary Account Access Updated Successfully.'
          );
          dispatch(handleAccountSettingsData(res?.data));
          // reset();
        }
      })
      .catch((err) => {
        setAccountAccessLoader(false);
        // setAccountAccessCheck(!event.target.checked)
        if (err.response) {
          setAccountAccessError(err.response?.data);
        } else {
          setAccountAccessError({ message: 'Network Error' });
        }
      });
  };

  const handleOnChange = (e) => {
    setUpdateError('');
    setUpdateSuccess('');
    setMatchPassword(false);
    setAccountAccessError('');
  };

  return (
    <div className="security-tab1">
      <Grid sx={{
        p: { xs: '16px 8px', md: '32px' },
        ml: { xs: '16px', md: '35px' },
        mr: { xs: '16px', md: '35px' },
        mt: '16px',
        width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
      }}
        fullWidth
        bgcolor="#ffffff"
        borderRadius="16px">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <ul className="security-ul">
            <li>
              {' '}
              <Typography
                variant="h4"
                color="#060606"
                marginBottom={2}
                marginTop={2}
                fontWeight={600}
              >
                Change Password
              </Typography>
              <Typography
                variant="h6"
                className="ChangePassword_text"
                marginBottom={1}
              >
                Current Password
              </Typography>
              <TextField
                // placeholder="Password *"
                maxLength={15}
                className="ChangePassword_textfield"
                inputProps={{
                  style: { fontSize: 16, padding: '12px 16px' },
                }}
                {...register('oldPassword', {
                  required: true,
                  onChange: (e) => handleOnChange(e),
                })}
              />
              {errors.oldPassword?.type === 'required' && (
                <p className="errorMsg">Current password is required</p>
              )}
            </li>
          </ul>

          <ul className="security-ul">
            <li>
              {' '}
              <Typography
                variant="h6"
                className="ChangePassword_text"
                marginBottom={1}
                marginTop={2}
              >
                New Password
              </Typography>
              <TextField
                // placeholder="Password *"
                maxLength={15}
                className="ChangePassword_textfield"
                // {...register('newPassword', { required: true })}
                inputProps={{
                  style: { fontSize: 16, padding: '12px 16px' },
                }}
                {...register('newPassword', {
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  required: true,
                  onChange: (e) => handleOnChange(e),
                })}
              />
              {errors.newPassword?.type === 'required' && (
                <p className="errorMsg">New password is required</p>
              )}
              {errors.newPassword?.type === 'pattern' && (
                <p className="errorMsg">
                  Password should contain atleast 8 charaters with
                  uppercase, lowercase and numbers
                </p>
              )}
            </li>
            <li className="personal_info-li">
              <Typography
                variant="h5"
                className="ChangePassword_text"
                marginBottom={1}
                marginTop={2}
              >
                Confirm Password
              </Typography>
              <TextField
                // placeholder="Password *"
                maxLength={15}
                className="ChangePassword_textfield"
                inputProps={{
                  style: { fontSize: 16, padding: '12px 16px' },
                }}
                // {...register('confirmPassword', { required: true })}
                {...register('confirmPassword', {
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  required: true,
                  onChange: (e) => handleOnChange(e),
                })}
              />
              {errors.confirmPassword?.type === 'required' && (
                <p className="errorMsg">Confirm password is required</p>
              )}
              {errors.confirmPassword?.type === 'pattern' && (
                <p className="errorMsg">
                  Password should contain atleast 8 charaters with
                  uppercase, lowercase and numbers
                </p>
              )}
              {matchPassword && (
                <p className="errorMsg">
                  New Password and Confirm Password should be same
                </p>
              )}
            </li>
          </ul>
          <div>
            <p className="data_err">
              {' '}
              {updateError && updateError?.message}
            </p>
            <p className="success_Message">
              {' '}
              {updateSuccess && updateSuccess}
            </p>
          </div>

          <Grid marginBottom={2}>
            {updateLoader ? (
              <Loader className={buttons.Blue_color_Btn} />
            ) : (
              <Button type="submit" className={buttons.Blue_color_Btn}>
                Change Password
              </Button>
            )}
          </Grid>
        </form>
      </Grid>
      <Grid sx={{
        p: { xs: '16px 8px', md: '32px' },
        ml: { xs: '16px', md: '35px' },
        mr: { xs: '16px', md: '35px' },
        mt: '16px',
        mb: '16px',
        width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
      }}
        fullWidth
        bgcolor="#ffffff"
        borderRadius="16px">
        <div className="two-factor-authentication">
          <Typography
            className="two-factor-title"
            marginBottom={2}
            marginTop={3}
          >
            Two Factor Authentication
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={twoFactorActive}
                sx={{ mt: 1 }}
                className="two-factor-toggle-switch"
                onChange={(event) => handleActiveTwoFactor(event)}
              />
            } //default Checked removed
          />
        </div>
        <div>
          <Typography variant="h6" className="two-factor-subTitle" mb={2}>
            For enhanced security, we recommand enabling two-factor
            authentication (2FA) with your mobile phone:
          </Typography>
          <Typography variant="p" className="two-factor-desc" mb={2}>
            Two-Step Verification requires you to enter a six-digit code
            while logging into Sprout Social.
            <br /> The setup and code retrieval process require Google
            Authenticator, a free mobile app available for iOS <br />
            and Android devices. Once enabled, you will be prompted to set
            up your phone immediately.
          </Typography>
        </div>
        <div className="two-step-checkbox">
          <Checkbox
            className="checkBox"
            checked={twoFactorCheck}
            onChange={handleCheckTwoFactor}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
          <Typography variant="h7" className="ChangePassword_text">
            Requires two-step verification for all users in this account
            Password
          </Typography>
        </div>
      </Grid>
    </div>
  );
};

export default ChangePassword;
