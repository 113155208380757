import React from 'react';
import { Grid, Button, Typography, Divider, Link } from '@mui/material';
import { Box } from '@mui/system';
import SocialsAddedCard from '../../addSocialChannel/socialsAddedCard';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateActiveStep,
  handleDefaultBrand,
} from '../../../../redux/Slice/user';
import { makeStyles } from '@mui/styles';
import StepperContainer from '../stepperContainer';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../../../redux/reduxThunk';
import * as api from '../../../../redux/Api';
import {useStyles} from '../../../../Themes/theme';
import {setCookie} from "../../../../helpers/cookie/cookie";

// const useStyles = makeStyles({
//   stepperButton: {
//     height:'48px',
//     padding: '8px 24px',
//     borderRadius: '8px',
//     color: 'white',
//     backgroundColor: 'black',
//     cursor: 'pointer',
//     fontSize: 'large',
//     fontWeight: 'bold',
//   },
//   stepperBackButton: {
//     height:'48px',
//     padding: '8px 24px',
//     borderRadius: '8px',
//     color: '#696767 !important',
//     backgroundColor: 'white',
//     cursor: 'pointer',
//     fontSize: 'large',
//     fontWeight: 'bold',
//     border:'1px solid #696767 !important'
//   },
// });

function ConfirmationStepper() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { activeStep,userData, allBrandData,allSocialAccountData,CurrentPlanDetailsData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const Number_of_Profiles = 5;
 
  const socialMediaData = [
    {
      name: 'Facebook Page',
      id: allSocialAccountData?.facebookPage
        ? allSocialAccountData?.facebookPage
        : null,
    },
    {
      name: 'Facebook Group',
      id: allSocialAccountData?.facebookGroup
        ? allSocialAccountData?.facebookGroup
        : null,
    },
    {
      name: 'Instagram',
      id: allSocialAccountData?.instagram
        ? allSocialAccountData?.instagram
        : null,
    },
    {
      name: 'Pinterest',
      id: allSocialAccountData?.pinterest
        ? allSocialAccountData?.pinterest
        : null,
    },
    {
      name: 'Google My Business',
      id: allSocialAccountData?.google
        ? allSocialAccountData?.google
        : null,
    },
    {
      name: 'LinkedIn',
      id: allSocialAccountData?.linkedIn
        ? allSocialAccountData?.linkedIn
        : null,
    },
    {
      name: 'Youtube',
      id: allSocialAccountData?.youtube
        ? allSocialAccountData?.youtube
        : null,
    },
  ];


  let filterSocialMediaData =
    socialMediaData && socialMediaData?.filter((item) => item.id !== null);

  const handleContinue = async (data) => {
    localStorage.setItem('activeStep', 0);

    await api.brandCreationFinalStep({ 
      onboardingStatus: 'COMPLETED' ,
      emailId:userData?.emailId,
      socialMediaData:filterSocialMediaData
    });
    // navigate('/home');
    setCookie('sidebar_activeItem', 'Calendar');
    window.location.href = `${api.scheduleUrl}/calendar`
    dispatch(getUserData());
  };
  const handleBack = async () => {
    dispatch(updateActiveStep(parseInt(activeStep - 1)));
    window.location.reload();
  };
  const continueButton = CurrentPlanDetailsData?.data?.planDetails?.plan==="free";
console.log("free",CurrentPlanDetailsData?.data?.planDetails?.plan==="free")
  return (
    <>
      <Grid
      container
      display="flex"
      flexDirection="row"
      justifyContent="space-between">
        <Grid
          xs={12} sm={7} md={7} lg={7.5} xl={7} p='35px 20px 0px 20px'
        >
          <Grid height="85%">
            <Typography
              variant="h3"
              display="flex"
              textAlign="start"
              fontWeight={600}
              mb={2}
            >
              Sucessfully conncted your social profiles!
            </Typography>
            <Typography
              variant="h5"
              display="flex"
              textAlign="start"
              mt={4}
              mb={2}
              color='green'
              sx={{fontSize:'20px'}}
            >
              Congratulations! You successfully added {allSocialAccountData?.count} social profile(s).
            </Typography>
            <Typography variant="h5" display="flex" textAlign="start" mb={2}>
              Don’t worry, if you need to add more social profiles, you will be
              able to do it directly in your settings.
            </Typography>
            <Typography variant="h5" display="flex" textAlign="start" mb={2}>
              You can start your 14-day free trial now.
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="space-between">
            <Button onClick={handleBack} className={classes.Grey_small_Btn}>
              Back
            </Button>
            
           { allSocialAccountData?.count > 0 ?
             <Grid>
             {continueButton ? (
               <button
                 onClick={handleContinue}
                 className={classes.Blue_color_Btn}
               >
                 {' '}
                 Start your 14 day trail
                
               </button>
             ) : (
              <Button
              sx={{width:'100%'}}
                variant='primary'
                size='large'
                disableRipple
                 onClick={handleContinue}
               >
                 {' '}
                 Continue
               </Button>
             )}
            </Grid>
           :
         null
           }
            
          </Grid>
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          className="Add_socials_right"
          p='24px 24px 0px 20px  '
        >
          <Grid display="flex" justifyContent="flex-end" >
            <Typography
              variant="h6"
              display="flex"
              justifyContent="flex-end"
              textAlign="flex-end"
            >
              Need help? 
              <a className="link" href="mailto:example@gmail.com">
                   Contact Us
                </a>
            </Typography>
          </Grid>
          <Grid container mt={4} mb={4}>
            <Grid container display="flex" justifyContent="start">
              <Typography variant="h4" fontWeight={500} >
                Connected Social Profiles
              </Typography>
              <Typography variant="h5" m='4px 0px 8px'>
                You have connected the following social profiles:
              </Typography>
            </Grid>
          </Grid>

          <Grid  ml={3} height={400}>
            <SocialsAddedCard />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ConfirmationStepper;
