import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Typography, Grid, TextField } from '@mui/material';
import '../Billing.css';
import { useForm } from 'react-hook-form';
import * as api from '../../../../redux/Api/index';
import Loader from '../../../../components/loader';
import { handlePaymentPriceModel } from '../../../../redux/Slice/user';
import { useSelector, useDispatch } from 'react-redux';

export default function CreditCardFields(
  {
    //   register,
    //   handleSubmit,
    //   onCardSubmit,
    //   errors,
    //   watch,
    //   setValue,
  }
) {
  const dispatch = useDispatch();
  //form handlers
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const expirationDate = watch('expirationDate');
  const [userLoader, setUserLoader] = React.useState(false);
  const [userError, setUserErrorr] = React.useState('');

  const handleExpirationDateChange = (event) => {
    const value = event.target.value;
    if (value.length === 2 && value.indexOf('/') === -1) {
      setValue('expirationDate', value + '/');
    } else if (value.length === 2 && value.indexOf('/') !== -1) {
      setValue('expirationDate', value.slice(0, -1));
    } else {
      setValue('expirationDate', value);
    }
  };
  const onCardSubmit = async (data) => {
    console.log('card dataaaaa', data);
    //
    setUserLoader(true);
    let expiryDate = data.expirationDate?.split('/');
    console.log('dataaaaa', data);
    let paymentObj = {
      name: data.name,
      cardNumber: data.cardNumber,
      cardExpMonth: expiryDate[0],
      cardExpYear: expiryDate[1],
      cardCvc: data.cvc,
    };
    console.log('dataaaaa paymentObj', paymentObj);
    // Create Stripe User API
    await api
      .createStripePaymentMethodApi(paymentObj)
      .then((res) => {
        if (res.status === 200) {
          setUserLoader(false);
          dispatch(handlePaymentPriceModel(true));
        }
      })
      .catch((err) => {
        setUserLoader(false);
        if (err.response) {
          setUserErrorr(err.response?.data);
        } else {
          setUserErrorr({ message: 'Network Error' });
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onCardSubmit)}>
        <DialogTitle id="alert-dialog-title" p={1}>
          <Typography variant="h5" color="#1d1d1d" fontWeight={600}>
            Enter your credit or debit card details here
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent p={2}>
          <Grid container>
            <ul className="creditcard_ul">
              <li className="creditcard_li">
                <Typography
                  display="flex"
                  variant="h5"
                  className="creditcard_label"
                >
                  Name on the Card
                </Typography>
                <TextField
                  inputProps={{
                    style: { fontSize: 16, padding: '12px 12px', width: 200 },
                  }}
                  placeholder="Card name"
                  {...register('name', {
                    required: true,
                    maxLength: 30,
                  })}
                  error={!!errors.name}
                />
              </li>
              <li>
                <Typography
                  display="flex"
                  variant="h5"
                  className="creditcard_label"
                >
                  Card Number
                </Typography>
                <TextField
                  inputProps={{
                    style: { fontSize: 16, padding: '12px 16px', width: 200 },
                  }}
                  placeholder="Card number"
                  {...register('cardNumber', {
                    required: true,
                    maxLength: 30,
                  })}
                  error={!!errors.cardNumber}
                />
              </li>
            </ul>
            <ul className="creditcard_ul">
              <li className="creditcard_li">
                <Typography
                  display="flex"
                  variant="h5"
                  className="creditcard_label"
                >
                  CVC number
                </Typography>
                <TextField
                  inputProps={{
                    style: { fontSize: 16, padding: '12px 12px', width: 200 },
                  }}
                  placeholder="CVC"
                  {...register('cvc', {
                    required: true,
                    maxLength: 4,
                  })}
                  error={!!errors.cvc}
                />
              </li>
              <li>
                <Typography
                  display="flex"
                  variant="h5"
                  className="creditcard_label"
                >
                  Expiry Date
                </Typography>
                <TextField
                  inputProps={{
                    style: { fontSize: 16, padding: '12px 16px', width: 200 },
                  }}
                  placeholder="MM/YYYY"
                  {...register('expirationDate', {
                    required: true,
                  })}
                  value={expirationDate}
                  onChange={handleExpirationDateChange}
                  error={!!errors.expirationDate}
                />
              </li>
            </ul>
          </Grid>
        </DialogContent>
        {userError && (
          <p className="data_err">{userError && userError?.message}</p>
        )}

        <DialogActions>
          <Grid p={2}>
            {userLoader ? (
              <Button
                className="AgreeBtn"
                sx={{ mr: 2, ml: 2, width: 110, height: 45 }}
              >
                <Loader color="inherit" value={100} size={25} />
              </Button>
            ) : (
              <Button type="submit" className="AgreeBtn" sx={{ mr: 2, ml: 2 }}>
                Continue
              </Button>
            )}
          </Grid>
        </DialogActions>
      </form>
    </>
  );
}
