import { createSlice } from '@reduxjs/toolkit';
import {
  getBrandSettingData,
  getAllBrandData,
  getAllSocialAccountData,
  getSocialAccountsSettingsData,
  getUserData,
  getCurrentPlanDetailsApiData,
  getMembersApiData,
  getAllUnselectedSocialAccountData,
  getTempSocialAccountsData,
} from '../reduxThunk';
const getCurrentStep = () => {
  const currentStep = localStorage.getItem('activeStep');
  if (currentStep === undefined) {
    localStorage.setItem('activeStep', 0);
    return 0;
  } else if (currentStep < 0 || currentStep > 2) {
    return 0;
  } else {
    return currentStep;
  }
};

const initialState = {
  user: {},
  userLoader: false,
  activeStep: getCurrentStep() || 0,
  isOtpModal: false,
  validateOtpDialog: false,
  isEmailChange: false,
  isMobileChange: false,
  validateEmail: false,
  validateMobile: false,
  signedUserData: {},
  signedUserLoader: false,

  // Account setting states
  accountSettingsData: {},
  accountSettingsLoader: false,
  accountSettingsError: '',
  notificationsData: {},
  notificationsLoader: false,
  currentPlanData: {},
  currentPlanLoader: false,
  billingPlanData: {},
  billingPlanLoader: false,
  invoiceData: [],
  invoiceLoader: false,
  paymentPriceModel: false,

  // members
  membersLoader: false,
  membersData: [],

  userBrandInfo: {},
  // Brand:
  brandSettingLoading: false,
  brandSettingData: [],
  brandSettingError: '',

  allBrandLoading: false,
  allBrandData: [],
  allBrandError: '',

  brandId: '',
  defaultSelectBrand: '',

  token: false,

  allSocialAccountLoading: false,
  allSocialAccountError: '',
  allSocialAccountData: [],

  tempSocialAccountsLoading: false,
  tempSocialAccountsError: '',
  tempSocialAccountsData: {
    account_type: "",
    pagesInfo:[]
  },
  tempSocialAccounts: false,

  selectedSocialAccountId: '',
  selectedSocialAccountType: '',

  // social account settings
  socialAccountSettingsLoading: false,
  socialAccountSettingsError: '',
  socialAccountSettingsData: [],

  //user data
  userDataLoading: false,
  userDataError: '',
  userData: [],

  // current plan data:-
  CurrentPlanDetailsDataLoading: false,
  CurrentPlanDetailsDataError: '',
  CurrentPlanDetailsData: {},

  // notes data
  notesData: [],
  updatedNoteData: {},

  // members data
  memberDataLoading: false,
  memberDataError: '',
  memberData: [],

  // unselected social account data
  allUnselectedSocialAccountLoading: false,
  allUnselectedSocialAccountError: '',
  allUnselectedSocialAccountData: [],

  // no data
  SocialAccountNoData:false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserLoader: (state, action) => {
      state.userLoader = action.payload;
    },
    updateActiveStep: (state, action) => {
      localStorage.setItem('activeStep', parseInt(action.payload));
      state.activeStep = parseInt(action.payload);
    },
    handleChangeOtpModal: (state, action) => {
      state.isOtpModal = action.payload;
    },
    handleChangeEmailInfo: (state, action) => {
      state.isEmailChange = action.payload.isEmail;
      state.isMobileChange = action.payload.isMobile;
    },
    handleValidateOtp: (state, action) => {
      state.validateEmail = action.payload.isEmail;
      state.validateMobile = action.payload.isMobile;
    },
    handleValidateOtpDialog: (state, action) => {
      state.validateOtpDialog = action.payload;
    },
    handleSignedUserData: (state, action) => {
      state.signedUserData = action.payload;
    },
    handleSignedUserLoader: (state, action) => {
      state.signedUserLoader = action.payload;
    },
    handleAccountSettingsData: (state, action) => {
      state.accountSettingsData = action.payload;
    },
    handleAccountSettingsLoader: (state, action) => {
      state.accountSettingsLoader = action.payload;
    },
    handleGetNotificationsLoader: (state, action) => {
      state.notificationsLoader = action.payload;
    },
    handleNotificationsData: (state, action) => {
      state.notificationsData = action.payload;
    },
    handleCurrentPlanData: (state, action) => {
      state.currentPlanData = action.payload;
    },
    handleCurrentPlanLoader: (state, action) => {
      state.currentPlanLoader = action.payload;
    },
    handleBillingPlanData: (state, action) => {
      state.billingPlanData = action.payload;
    },
    handleBillingPlanLoader: (state, action) => {
      state.billingPlanLoader = action.payload;
    },
    handleInvoiceReceiptsData: (state, action) => {
      state.invoiceData = action.payload;
    },
    handleInvoiceReceiptsLoader: (state, action) => {
      state.invoiceLoader = action.payload;
    },
    handlePaymentPriceModel: (state, action) => {
      state.paymentPriceModel = action.payload;
    },

    handleUserBrandInfo: (state, action) => {
      state.userBrandInfo = action.payload;
    },
    handleSelectedBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    handleDefaultBrand: (state, action) => {
      state.defaultSelectBrand = action.payload;
    },
    handleToken: (state, action) => {
      state.token = action.payload;
    },
    handleSelectedSocialAccountId: (state, action) => {
      state.selectedSocialAccountId = action.payload;
    },
    handleSelectedSocialAccountType: (state, action) => {
      state.selectedSocialAccountType = action.payload;
    },
    handleNotesData: (state, action) => {
      state.notesData = action.payload;
    },

    handleMembersLoader: (state, action) => {
      state.membersLoader = action.payload;
    },
    handleMembersData: (state, action) => {
      state.membersData = action.payload;
    },
    handleUpdateNoteData: (state, action) => {
      state.updatedNoteData = action.payload;
    },
    handleSocialAccountNoData: (state, action) => {
      state.tempSocialAccounts = action.payload;
      // state.tempSocialAccounts = true;
    },
    handleRestRedux: (state = initialState, action) => {
      state.allBrandData = [];
      state.userData = [];
      state.brandId = '';
      state.brandSettingData = [];
    },
  },
  // To handle Async data through redux toolkit

  extraReducers: (builder) => {
    builder.addCase(getBrandSettingData.pending, (state, action) => {
      state.brandSettingLoading = true;
    });
    builder.addCase(getBrandSettingData.fulfilled, (state, action) => {
      state.brandSettingLoading = false;
      state.brandSettingData = action.payload.data;
    });
    builder.addCase(getBrandSettingData.rejected, (state, action) => {
      state.brandSettingLoading = false;
      state.brandSettingError = 'error data';
      state.brandSettingData = [];
    });

    builder.addCase(getAllBrandData.pending, (state, action) => {
      state.allBrandLoading = true;
    });
    builder.addCase(getAllBrandData.fulfilled, (state, action) => {
      state.allBrandLoading = false;
      state.allBrandData = action.payload.data;
    });
    builder.addCase(getAllBrandData.rejected, (state, action) => {
      state.allBrandLoading = false;
      state.allBrandError = 'error data';
      state.allBrandData = [];
    });

    builder.addCase(getAllSocialAccountData.pending, (state, action) => {
      state.allSocialAccountLoading = true;
    });
    builder.addCase(getAllSocialAccountData.fulfilled, (state, action) => {
      state.allSocialAccountLoading = false;
      state.allSocialAccountData = action.payload.data;
    });
    builder.addCase(getAllSocialAccountData.rejected, (state, action) => {
      state.allSocialAccountLoading = false;
      state.allSocialAccountError = 'error data';
      state.allSocialAccountData = [];
    });
    builder.addCase(getTempSocialAccountsData.pending, (state, action) => {
      state.tempSocialAccountsLoading = true;
    });
    builder.addCase(getTempSocialAccountsData.fulfilled, (state, action) => {
      state.tempSocialAccounts = true;
      state.tempSocialAccountsLoading = false;
      state.tempSocialAccountsData = action.payload.data;
    });
    builder.addCase(getTempSocialAccountsData.rejected, (state, action) => {
      state.tempSocialAccountsLoading = false;
      state.tempSocialAccountsError = 'error data';
      state.tempSocialAccountsData = {
        account_type: "",
        pagesInfo:[]
      };
    });

    builder.addCase(
      getAllUnselectedSocialAccountData.pending,
      (state, action) => {
        state.allUnselectedSocialAccountLoading = true;
      }
    );
    builder.addCase(
      getAllUnselectedSocialAccountData.fulfilled,
      (state, action) => {
        state.allUnselectedSocialAccountLoading = false;
        state.allUnselectedSocialAccountData = action.payload.data;
      }
    );
    builder.addCase(
      getAllUnselectedSocialAccountData.rejected,
      (state, action) => {
        state.allUnselectedSocialAccountLoading = false;
        state.allUnselectedSocialAccountError = 'error data';
        state.allUnselectedSocialAccountData = [];
      }
    );

    builder.addCase(getSocialAccountsSettingsData.pending, (state, action) => {
      state.socialAccountSettingsLoading = true;
    });
    builder.addCase(
      getSocialAccountsSettingsData.fulfilled,
      (state, action) => {
        state.socialAccountSettingsLoading = false;
        state.socialAccountSettingsData = action.payload.data;
      }
    );
    builder.addCase(getSocialAccountsSettingsData.rejected, (state, action) => {
      state.socialAccountSettingsLoading = false;
      state.socialAccountSettingsError = 'error data';
      state.socialAccountSettingsData = [];
    });

    builder.addCase(getUserData.pending, (state, action) => {
      state.userDataLoading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.userDataLoading = false;
      state.userData = action.payload.data;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.userDataLoading = false;
      state.userDataError = 'error data';
      state.userData = [];
    });

    builder.addCase(getCurrentPlanDetailsApiData.pending, (state, action) => {
      state.CurrentPlanDetailsLoading = true;
    });
    builder.addCase(getCurrentPlanDetailsApiData.fulfilled, (state, action) => {
      state.CurrentPlanDetailsDataLoading = false;
      state.CurrentPlanDetailsData = action.payload?.data;
    });
    builder.addCase(getCurrentPlanDetailsApiData.rejected, (state, action) => {
      state.CurrentPlanDetailsDataLoading = false;
      state.CurrentPlanDetailsDataError = 'error data';
      state.CurrentPlanDetailsData = {};
    });

    builder.addCase(getMembersApiData.pending, (state, action) => {
      state.memberDataLoading = true;
    });
    builder.addCase(getMembersApiData.fulfilled, (state, action) => {
      state.memberDataLoading = false;
      state.memberData = action.payload.data;
    });
    builder.addCase(getMembersApiData.rejected, (state, action) => {
      state.memberDataLoading = false;
      state.memberDataError = 'error data';
      state.memberData = [];
    });
  },
  // extraReducers: {
  // [getBrandSettingData.pending]: (state, action) => {
  //   state.brandSettingLoading = true;
  // },
  // [getBrandSettingData.fulfilled]: (state, action) => {
  //   state.brandSettingLoading = false;
  //   state.brandSettingData = action.payload;
  // },
  // [getBrandSettingData.rejected]: (state, action) => {
  //   state.brandSettingLoading = false;
  //   state.brandSettingError = 'error data';
  //   state.brandSettingData = [];
  // },

  // [getAllBrandData.pending]: (state, action) => {
  //   state.allBrandLoading = true;
  // },
  // [getAllBrandData.fulfilled]: (state, action) => {
  //   state.allBrandLoading = false;
  //   state.allBrandData = action.payload;
  // },
  // [getAllBrandData.rejected]: (state, action) => {
  //   state.allBrandLoading = false;
  //   state.allBrandError = 'error data';
  //   state.allBrandData = [];
  // },
  // [getAllSocialAccountData.pending]: (state, action) => {
  //   state.allSocialAccountLoading = true;
  // },
  // [getAllSocialAccountData.fulfilled]: (state, action) => {
  //   state.allSocialAccountLoading = false;
  //   state.allSocialAccountData = action.payload;
  // },
  // [getAllSocialAccountData.rejected]: (state, action) => {
  //   state.allSocialAccountLoading = false;
  //   state.allSocialAccountError = 'error data';
  //   state.allSocialAccountData = [];
  // },
  // [getSocialAccountsSettingsData.pending]: (state, action) => {
  //   state.socialAccountSettingsLoading = true;
  // },
  // [getSocialAccountsSettingsData.fulfilled]: (state, action) => {
  //   state.socialAccountSettingsLoading = false;
  //   state.socialAccountSettingsData = action.payload;
  // },
  // [getSocialAccountsSettingsData.rejected]: (state, action) => {
  //   state.socialAccountSettingsLoading = false;
  //   state.socialAccountSettingsError = 'error data';
  //   state.socialAccountSettingsData = [];
  // },

  // [getUserData.pending]: (state, action) => {
  //   state.userDataLoading = true;
  // },
  // [getUserData.fulfilled]: (state, action) => {
  //   state.userDataLoading = false;
  //   state.userData = action.payload;
  // },
  // [getUserData.rejected]: (state, action) => {
  //   state.userDataLoading = false;
  //   state.userDataError = 'error data';
  //   state.userData = [];
  // },
  // [getCurrentPlanDetailsApiData.pending]: (state, action) => {
  //   state.CurrentPlanDetailsLoading = true;
  // },
  // [getCurrentPlanDetailsApiData.fulfilled]: (state, action) => {
  //   state.CurrentPlanDetailsDataLoading = false;
  //   state.CurrentPlanDetailsData = action.payload;
  // },
  // [getCurrentPlanDetailsApiData.rejected]: (state, action) => {
  //   state.CurrentPlanDetailsDataLoading = false;
  //   state.CurrentPlanDetailsDataError = 'error data';
  //   state.CurrentPlanDetailsData = [];
  // },
  // },
});

// Action creators are generated for each case reducer function
export const {
  updateUser,
  updateUserLoader,
  updateActiveStep,
  handleChangeOtpModal,
  handleChangeEmailInfo,
  handleSignedUserData,
  handleSignedUserLoader,
  handleAccountSettingsData,
  handleAccountSettingsLoader,
  handleGetNotificationsLoader,
  handleNotificationsData,
  handleCurrentPlanData,
  handleCurrentPlanLoader,
  handleInvoiceReceiptsLoader,
  handleInvoiceReceiptsData,
  handleUserBrandInfo,
  handleSelectedBrandId,
  handleDefaultBrand,
  handleToken,
  handleValidateOtp,
  handleValidateOtpDialog,
  handleSelectedSocialAccountId,
  handleSelectedSocialAccountType,
  handleNotesData,
  handleMembersLoader,
  handleMembersData,
  handleUpdateNoteData,
  handleBillingPlanData,
  handleBillingPlanLoader,
  handlePaymentPriceModel,
  handleRestRedux,
  handleSocialAccountNoData
} = userSlice.actions;

export default userSlice.reducer;
