import { Grid, Radio, Typography, Box, IconButton, Button } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { FinalImg } from '../../../assets';
import './signin.css';
import { Link } from 'react-router-dom';
import * as api from '../../../redux/Api/index';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../../components/loader';
import { useStyles } from '../../../Themes/theme';
import { headerStyles } from "../../../Themes/headerTheme";
import { useDispatch, useSelector } from 'react-redux';
import { handleToken } from '../../../redux/Slice/user';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { getAllBrandData } from '../../../redux/reduxThunk';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import Cookies from 'js-cookie';
import { inputStyles } from "../../../Themes/InputTheme";
import { buttonStyles } from "../../../Themes/buttonTheme";
import CustomTextField from '../../../components/customTextField';
import CustomPassWordTextField from '../../../components/customPasswordTextField';

export default function SignIn() {
  const classes = useStyles();
  const header = headerStyles();
  const inputClass = inputStyles()
  const buttonClass = buttonStyles();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const methods = useForm();

  const [show, setShow] = useState(false);
  const [val, setVal] = useState(false);
  const [error, setError] = useState('');
  const [loginLoader, setLoginLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  // On submit Login
  const onSubmit = async (data) => {
    let loginObj = {
      password: data.password,
    };
    console.log('loginObj',loginObj);
    let emailOrMobile = Number(data.emailId);
    setVal(data.emailId)
    if (emailOrMobile) {
      loginObj.phoneNumber = data.emailId;
    } else {
      loginObj.emailId = data.emailId;
    }
    // console.log('dataaaaaaaaaaa loginObj', loginObj);

    setLoginLoader(true);

    await api
      .logIn(loginObj)
      .then((res) => {
        if (res?.data?.data?.isAccountActive === true) {
          if (res.status === 200) {
            if (res.data.data.twoFactorAuth) {
              navigate('/two-factor-auth');
              dispatch(handleToken(true));
            } else if (res.data.data.onboardingStatus === 'CONFIRM_OTP') {
              navigate('/verify-otp');
              dispatch(handleToken(true));
            } else if (res.data.data.onboardingStatus === 'WORKSPACE_REQ') {
              dispatch(handleToken(true));
              localStorage.setItem('activeStep', 0);
              navigate('/social-brand-accounts');
            } else if (res.data.data.onboardingStatus === 'SOCIAL_REQ') {
              console.log("accountType", res.data);
              dispatch(getAllBrandData(userData?.accountType));
              dispatch(handleToken(true));
              localStorage.setItem('activeStep', 1);
              navigate('/social-brand-accounts');
            } else {
              // clear router history and redirect to dashboard
              // window.history.pushState(null, '', window.location.href);
              // navigate('/home');
              setCookie('sidebar_activeItem', 'Calendar', 'created_brand_name');
              window.location.href = `${api.scheduleUrl}/calendar`
              dispatch(handleToken(true));
              dispatch(getAllBrandData(userData?.accountType));
            }
            setLoginLoader(false);
            // Store JWT token in local storage
            // localStorage.setItem('token', res.data.token);
            setCookie('token', res.data.data.token);
            removeCookie('forgotOtpSentInfo');
            // Cookies.remove('forgotOtpSentInfo')
          }
        }
        else {
          setLoginLoader(false);
          setError({
            message: res?.data.message,
          });
        }
      })
      .catch((err) => {
        setLoginLoader(false);
        if (err.response) {
          setError(err.response.data);
        } else {
          setError({ message: 'Network Error' });
        }
      });

  };

  // to fetch client IP Address
  const getClientIpAddress = async () => {
    let ipAddr = '';
    await fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        ipAddr = data.ip;
      });
    return ipAddr;
  };

  // const getClientLocation = async () => {
  //   let ipAddr = '';
  //   await fetch('https://ipinfo.io/json?token=ed1ce99956a407')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log('navigatorrrrrrrr getClientLocation', data);
  //     });
  // };

  // to get client login time os details, browser details & geolocation details
  const getClientDetails = async () => {
    console.log('navigatorrrrrrrr', navigator);
    // user device os details
    let os = navigator.userAgent;
    let finalOs = '';
    let clientBrowser = '';
    if (os.search('Windows') !== -1) {
      finalOs = 'Windows';
    } else if (os.search('Mac') !== -1) {
      finalOs = 'MacOS';
    } else if (os.search('X11') !== -1 && !(os.search('Linux') !== -1)) {
      finalOs = 'UNIX';
    } else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
      finalOs = 'Linux';
    }
    console.log('navigatorrrrrrrr finalOs', finalOs);
    //user browser os details
    var ua = navigator.userAgent;
    var tem;
    var M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    clientBrowser = M.join(' ');
    console.log('navigatorrrrrrrr browserrrr', clientBrowser);

    let clientLoggedTime = moment(new Date()).format('d/m/YY HH:mm');
    // let clientIp = ip.address();
    console.log('navigatorrrrrrrr clientLoggedTime', clientLoggedTime);

    let clientIpAddress = await getClientIpAddress();
    console.log('navigatorrrrrrrr clientIpAddress', clientIpAddress);

    const request = await fetch(api.ClientGeoLocationUrl);
    const clientLocationInfo = await request.json();
    // let clientLocation = await getClientLocation();
    console.log('navigatorrrrrrrr clientLocation', clientLocationInfo);
  };

  // useEffect(() => {
  //   getClientDetails();
  // }, []);
  const handleForgotPassword = () => {
    navigate('/forgot-password', { state: val });
  }
  return (
    <Grid
      container
      spacing={0}
      display="flex"
      height="100vh"
    >
      <Grid
        sm={6}
        md={6}
        lg={7}
        className={classes.leftGrid}>
        <img src={FinalImg} alt="left" className={classes.leftImage} />
      </Grid>
      <Grid
      xs={12}
        sm={6}
        md={6}
        lg={5}
        container
        className={classes.rightGrid}>
        <Grid xs={10} sm={9}>
          <Typography variant="h2" className={header.heading}>
            Sign in to SocialXperia
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <Typography className={header.label}>Email address <span className={header.require}>*</span></Typography>
              {/* <input
              placeholder="Business email addresss *"
              className={inputClass.input}
              {...register('emailId', {
                // pattern:
                //   /^[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                required: true,
              })}
            /> */}
              <CustomTextField
                fieldName="emailId" // Renamed prop from `name` to `fieldName`
                placeholder="Business email addresss "
                classes={inputClass.input}
                {...register('emailId', {
                  required: true,
                })}
              />
              {errors.emailId?.type === 'required' && (
                <Typography className={classes.err}>This field is required</Typography>
              )}

              <Typography sx={{ mt: 4 }} className={header.label}>Password <span className={header.require}>*</span></Typography>
              {/* <Box className="input-wrapper">
              <Box>
                <input
                  type={show ? 'text' : 'password'}
                  placeholder="Password *"
                  maxLength={15}
                  className={inputClass.input1}
                  {...register('password', {
                    required: true,
                  })}
                />
              </Box>
              <Box sx={{ marginTop: "14px" }}>
                <IconButton
                  onClick={() => setShow(!show)}
                  className="toggle-icon"
                >
                  {show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Box>
            </Box> */}

              <CustomPassWordTextField
                
                fieldName="password" // Provide the name prop for registration
                placeholder='password '
                className={inputClass.input1}
                showPassword={showPassword}
                {...register('password', {
                  required: true,
                })}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword} />

              {errors.password?.type === 'required' && (
                <p className={classes.err}>Password is required</p>
              )}
              <Typography variant="h6" className="span-1 r" sx={{ display: 'flex', justifyContent: 'end'}}>
                <Button to="/forgot-password" className='forgot-button' onClick={handleForgotPassword}>Forgot your password?</Button>
              </Typography>

              <Typography className={classes.data_err}> {error && error?.message}</Typography >

              <Box >
                {loginLoader ? (
                  <Button
                  sx={{width:'100%'}}
                    variant='primary'
                    size='large'
                    disableRipple >
                    <Loader color="inherit" value={100} size={25} />
                  </Button>
                ) : (
                  <Button
                  sx={{width:'100%'}}
                    variant='primary'
                    size='large'
                    disableRipple
                    type="submit"
                  >
                    Log In
                  </Button>
                )}

              </Box>
            </form>
          </FormProvider>
          <Typography className={header.footerHeading}>
            New to SocialXperia?
            <Link className={header.terms} to="/sign-up"> Register</Link>
          </Typography>
          <Typography variant="h5" component="p" sx={{ mt: 8, textAlign: 'center' }}>
            By continuing you agree to SocialXperia's{' '}
            <span ><a className={header.terms} href='https://socialxperia.ai/dev/terms/' target="_blank">Terms of Service</a></span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
