import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Icon, Box } from '@mui/material';
import AddMemberDialog from './addMemberDialog';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader';
import { buttonStyles } from '../../Themes/buttonTheme'
import * as api from '../../redux/Api/index';
import {
  FacebookIcon,
  InstagramIcon,
  MyBusinessIcon,
  PinterestIcon,
  LinkedinIcon,
  YoutubeIcon,
} from '../../assets';
import { setCookie, getCookie, removeCookie } from '../../helpers/cookie/cookie';
import { useNavigate } from 'react-router-dom';

function Member({ fetchMembersData }) {
  const navigate = useNavigate();
  const buttons = buttonStyles();
  // redux states
  const { membersLoader, membersData, currentPlanData } = useSelector(
    (state) => state.user
  );

  //local states
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [loading, setLoading] = useState([]);
  const [deleteError, setDeleteError] = React.useState('');
  const [deleteSuccess, setDeleteSuccess] = React.useState('');
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteMemer = async (item, index) => {
    let data = {
      brand_member_id: item._id,
    };
    // setDeleteLoader('true');
    setLoading((prevLoading) => ({
      ...prevLoading,
      [index]: true,
    }));
    await api
      .deleteMembersApi(data)
      .then((res) => {
        if (res.status === 200) {
          // setDeleteLoader(false);
          setLoading((prevLoading) => ({
            ...prevLoading,
            [index]: false,
          }));
          setDeleteSuccess('Account Deleted Successfully.');
          //fetching updated Account Settings data to display
          fetchMembersData();
        }
      })
      .catch((err) => {
        // setDeleteLoader(false);
        setLoading((prevLoading) => ({
          ...prevLoading,
          [index]: false,
        }));
        if (err.response) {
          setDeleteError(err.response?.data);
        } else {
          setDeleteError({ message: 'Network Error' });
        }
      });
  };

  const handleUpgradePlan = () => {
    // console.log("billing")
    setCookie('billing_tab_value', 4);
    navigate('/account-settings');
  };

  // console.log(
  //   'membersData',
  //   membersData,
  //   currentPlanData,
  //   currentPlanData?.planDetails?.users,
  //   membersData?.length
  // );
  return (
    <>
      <Grid className="member-tab" sx={{ p: { xs: '16px 0px', md: '32px 0px 32px 0px' } }} borderRadius={"16px"}>
        <Grid className="member-tab-title">
          <Grid>
            <Typography className="member-tab-heading">Team Members</Typography>
            <Typography className="member-tab-subHeading">
              Users associated with your account
            </Typography>
          </Grid>
          <Grid display='flex' alignItems='center' textAlign='center'>
            {membersLoader ? (
              <Button className={buttons.Blue_color_Btn}>
                <Loader color="inherit" value={100} size={20} />
              </Button>
            ) : (
              <>
                {membersData &&
                  membersData?.length < currentPlanData?.planDetails?.users ? (
                  <Button className={buttons.Blue_color_Btn} onClick={handleOpen}>
                    Add Member
                  </Button>
                ) : (
                  <Button
                    className="add-member-button"
                    onClick={handleUpgradePlan}
                  >
                    Upgrade Plan
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid>
          <TableContainer>
            <Table sx={{ height: 200 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow className="member-table-row">
                  <TableCell className="member-table-cel">
                    <Typography variant="h6" fontWeight={500}>
                      Member
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="member-table-cel">
                    <Typography variant="h6" fontWeight={500}>
                      Role
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="member-table-cel">
                    <Typography variant="h6" fontWeight={500}>
                      Channels
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="member-table-cel">
                    <Typography variant="h6" fontWeight={500}>
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {membersLoader ? (
                  <>
                    <TableCell></TableCell>
                    <TableCell>
                      <Box className="loader_div">
                        <Loader color="inherit" value={100} size={40} />
                      </Box>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </>
                ) : (
                  <>
                    {membersData && membersData.length > 0 ? (
                      membersData.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              className="member-table-cel"
                            >
                              <Box display="flex">
                                <Box mr={1}>
                                  <Avatar
                                    alt="J"
                                    sx={{ height: 56, width: 56 }}
                                  />
                                </Box>
                                <Box>
                                  <Typography variant="h6">
                                    {item?.name}
                                  </Typography>
                                  <Typography variant="p">
                                    {item?.emailId}
                                  </Typography>
                                  <br />
                                  <Typography
                                    variant="p"
                                    color={
                                      item?.isActive ? '#45d321' : '#d15033'
                                    }
                                  >
                                    {item?.isActive ? 'Active' : 'Inactive'}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell
                              align="left"
                              className="member-table-cel"
                            >
                              <Typography variant="h6">
                                {item.brands?.role}
                              </Typography>
                            </TableCell>

                            <TableCell
                              align="left"
                              className="member-table-cel"
                            >
                              {/* <Button>All Channels</Button> */}
                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                {item?.brands?.channels?.map((channelItem) => {
                                  return (
                                    <Box style={{ padding: '2px' }}>
                                      {channelItem?.name === 'facebookPage' && (
                                        <Avatar
                                          sx={{ height: 20, width: 20 }}
                                          src={FacebookIcon}
                                        />
                                      )}
                                      {channelItem?.name ===
                                        'facebookGroup' && (
                                          <Avatar
                                            sx={{ height: 20, width: 20 }}
                                            src={FacebookIcon}
                                          />
                                        )}
                                      {channelItem?.name === 'instagram' && (
                                        <Avatar
                                          sx={{ height: 20, width: 20 }}
                                          src={InstagramIcon}
                                        />
                                      )}
                                      {channelItem?.name === 'pinterest' && (
                                        <Avatar
                                          sx={{ height: 20, width: 20 }}
                                          src={PinterestIcon}
                                        />
                                      )}
                                      {channelItem?.name === 'google' && (
                                        <Avatar
                                          sx={{ height: 20, width: 20 }}
                                          src={MyBusinessIcon}
                                        />
                                      )}
                                      {channelItem?.name === 'linkedIn' && (
                                        <Avatar
                                          sx={{ height: 20, width: 20 }}
                                          src={LinkedinIcon}
                                        />
                                      )}
                                      {channelItem?.name === 'youtube' && (
                                        <Avatar
                                          sx={{ height: 20, width: 20 }}
                                          src={YoutubeIcon}
                                        />
                                      )}
                                    </Box>
                                  );
                                })}
                              </Box>
                            </TableCell>

                            <TableCell
                              align="left"
                              className="member-table-cel"
                            >
                              <Box display="flex">
                                <Button className="iconBtn">
                                  <CachedIcon sx={{ color: '#000000' }} />
                                </Button>
                                {loading[index] ? (
                                  <Button className="iconBtn">
                                    <Loader
                                      color="inherit"
                                      value={100}
                                      size={18}
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    className="iconBtn"
                                    onClick={() =>
                                      handleDeleteMemer(item, index)
                                    }
                                  >
                                    <DeleteOutlineOutlinedIcon
                                      sx={{ color: '#000000' }}
                                    />
                                  </Button>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} className="no_data">No Members</TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* </>
        )} */}
      </Grid>

      <AddMemberDialog
        open={open}
        onClose={handleClose}
        fetchMembersData={fetchMembersData}
      />
    </>
  );
}

export default Member;
