import { Grid, Typography, Button } from '@mui/material'
import React, { useState, useEffect } from 'react';
import "./style.css";
import * as api from "../../../redux/Api"
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HandleDeleteDialog from './handleDeleteDialog';
import { handleNotesData ,handleUpdateNoteData} from '../../../redux/Slice/user';
import AddNotesDialog from './addNotesDialog';
import UpdateNotesDialog from './updateNotesDialog';
import { buttonStyles } from '../../../Themes/buttonTheme';

function Notes() {
    const buttons = buttonStyles();
    const [addOpen, setAddOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [notes, setNoteData] = useState()
    const [noteId,setNoteId]= useState()
    const dispatch = useDispatch()
    const { selectedSocialAccountId, notesData } = useSelector((state) => state.user)
    const [dopen, setDopen] = React.useState(false);


    const handleUpdateOpen = (id) => {
        getNotesData()
        const updatedNoteData = notesData?.note?.find((note) => note._id === id)
        setNoteData(updatedNoteData)
        dispatch(handleUpdateNoteData(updatedNoteData))
        setUpdateOpen(true);
       
    };
    useEffect(() => {
        const getNotesData = async () => {
            await api.getNotes(selectedSocialAccountId).then((res) => {
                dispatch(handleNotesData(res.data))
            })
        }
        getNotesData()
    }, [])
    const getNotesData = async () => {
        await api.getNotes(selectedSocialAccountId).then((res) => {
            dispatch(handleNotesData(res.data))
        })
    }
    const handleAddOpen = () => {
        setAddOpen(true);
    };

    const handleDeleteDialogOpen = (id) => {
        setNoteId(id)
        setDopen(true);
    };
    const handleDeleteDialogClose = () => {
        setDopen(false);
        getNotesData()
    };
    return (
        <>
            <Grid className='notes-tab'>
                <Grid>
                    <Typography className='notes-title'>
                        Shortcodes
                    </Typography>
                </Grid>
                <Grid  sx={{display:{xs:"block",sm:"block", md:"flex"}, mt:{xs:"40px", sm:"40px", md:"8px"}}} >
                    <Typography className='notes-subtitle'>
                        Create shortcodes up to 100 shortcodes that consist of anything you like such<br /> as hashtags, business info, etc.
                    </Typography>
                    <Button sx={{ml:2}} className={buttons.Blue_color_Btn} onClick={handleAddOpen}>Add Notes</Button>

                </Grid>
                <Grid container >
                    {notesData?.note?.map((note) =>
                        <Grid className='note-card' spacing={4} item xs={12} sm={6} md={4} lg={4} xl={4} style={{ backgroundColor: `${note.color}` }}>
                            <Grid display="flex" justifyContent="right">
                                <EditIcon onClick={() => handleUpdateOpen(note._id)} className="icon-hover" />
                                <DeleteIcon onClick={()=>handleDeleteDialogOpen(note._id)} className="icon-hover" />
                             
                            </Grid>
                            <Typography className='note-card-title'>
                                {note.title}
                            </Typography>

                            <Typography className='note-card-subtitle'>
                                {note.note}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {/* Add dialogue */}
            <AddNotesDialog addOpen={addOpen} setAddOpen={setAddOpen} />
            {/* Update dialogue */}
            <UpdateNotesDialog updateOpen={updateOpen} setUpdateOpen={setUpdateOpen} notes={notes} />
            {/* delete dialogue */}
            <HandleDeleteDialog open={dopen} onClose={handleDeleteDialogClose} variant="h5" id={noteId}/>
        </>
    )
}

export default Notes;
