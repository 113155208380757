import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Divider, Typography } from '@mui/material';
import * as api from '../../../../../redux/Api';
import Loader from '../../../../../components/loader';
import { useDispatch ,useSelector} from 'react-redux';
import { setCookie, getCookie, removeCookie } from '../../../../../helpers/cookie/cookie';
import {getAllSocialAccountData} from "../../../../../redux/reduxThunk"

export default function HandleDeleteDialog({ open, onClose, onClick ,id,account,pageName}) {
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState('');
  const [deleteSuccess, setDeleteSuccess] = React.useState('');
  const created_brand_id = getCookie("created_brand_id")
  const dispatch =useDispatch()
  const { userData} = useSelector((state) => state.user);
  console.log("id",id,account)
  const handleDeleteAccount = async () => {
    setDeleteLoader('true');
    const data = {
      "social_id": created_brand_id,
      "account": account,
      "emailId":userData?.data?.emailId,
      "pageName":pageName
  }
    await api
      .deleteSocialAccounts(data)
      .then((res) => {
        if (res.status === 200) {
          setDeleteLoader(false);
          dispatch(getAllSocialAccountData(created_brand_id));
          onClose()
        }
      })
      .catch((err) => {
        setDeleteLoader(false);
        if (err.response) {
          setDeleteError(err.response?.data);
        } else {
          setDeleteError({ message: 'Network Error' });
        }
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h4" fontWeight={700} p="16px 24px">
          Confirm to Delete Social Account
        </Typography>
        <Divider />
        <DialogContent>
          <Typography variant="h6" p={1}>
            Are you sure you want to delete the social Account <br />
            If you delete the Account all Progress and changes will be gone.
          </Typography>
        </DialogContent>
        {deleteError && (
          <p className="data_err">{deleteError && deleteError?.message}</p>
        )}

        <DialogActions>
          <Button onClick={onClose} className="cancelDialogbtn">
            Cancel
          </Button>
          {deleteLoader ? (
            <Button className="deleteDialogbtn">
              <Loader color="inherit" value={100} size={25} />
            </Button>
          ) : (
            <Button
              onClick={handleDeleteAccount}
              className="deleteDialogbtn"
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
