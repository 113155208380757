import { Grid, Typography, Box, IconButton, Button } from '@mui/material';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import './login.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { regestorUser } from '../../../redux/Api/index';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { handleToken } from '../../../redux/Slice/user';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { headerStyles } from '../../../Themes/headerTheme';
import { inputStyles } from '../../../Themes/InputTheme';
import { buttonStyles } from "../../../Themes/buttonTheme";
import { FinalImg } from '../../../assets';
import Loader from '../../../components/loader';
import CustomTextField from '../../../components/customTextField';
import CustomPassWordTextField from '../../../components/customPasswordTextField';

const useStyles = makeStyles({
  leftImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  right_section: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    overflow: 'auto',

  },

  err: {
    // marginBottom: '20px',
    marginTop: '-1px',
    fontSize: 'small',
    font: 100,
    color: 'brown',
  },
  data_err: {
    // marginBottom: '20px',
    color: 'brown',
    textAlign: 'center',
    fontSize: '16px',
    whiteSpace: 'break-spaces',
  },
  leftGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#FFF',
    height: '100%',
    '@media (max-width: 768px)': {
      display: 'none'
    },
  },

});

export default function SignUp() {
  const classes = useStyles();
  const header = headerStyles();
  const inputClass = inputStyles();
  const buttonClass = buttonStyles();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const methods = useForm();

  const [isValidNumber, setIsValidNumber] = useState(true);
  const [error, setError] = useState('');
  const [signupLoader, setSignupLoader] = useState(false);
  let navigate = useNavigate();
  const [state, setState] = useState({
    name: '',
    type: '',
    location: '',
    contact: '',
    MACoy: '',
    equipManu: '',
  });
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleInputChange = (e) => {
  //   setInputValue(e.target.value);
  // };

  const onPhoneNumberChange = (value, country) => {
    setValue('phoneNumber', value.slice(country.dialCode.length));
    setValue('countryCode', country.dialCode);
  };

  // on Signup Submit
  const onSubmit = async (data) => {
    setSignupLoader(true);
    setError('');
    let registorData = {
      ...data,
      onboardingStatus: 'CONFIRM_OTP',
    };
    // console.log('registorData', registorData);
    await regestorUser(registorData)
      .then((res) => {
        if (res.status === 200) {
          console.log("res.data.data", res?.data.message === undefined)
          if (res?.data.message === undefined) {
            setSignupLoader(false);
            const userToken = res?.data?.data;
            console.log("userToken", userToken)
            setCookie('token', userToken);
            // localStorage.setItem('token', userToken);
            navigate('/verify-otp');
            dispatch(handleToken(true));
          } else {
            setSignupLoader(false);
            setError({
              message: res?.data.message,
            });
          }

        }
      })
      .catch((err) => {
        setSignupLoader(false);
        if (err.response) {
          setError(err.response.data);
        } else {
          setError({ message: 'Network Error' });
        }
      });
  };
  const handleOnChange = (...args) => {
    console.log(args);
  };

  return (
    <Grid
      container
      height="100vh"
    >
      <Grid
        item
        xs={0}
        sm={6}
        md={7}
        className={classes.leftGrid}
      >
        <img src={FinalImg} alt="left" className={classes.leftImage} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        bgcolor="#fff"
        className={classes.right_section}
      >
        <Grid xs={10} sm={9} sx={{ mt: 7 }}>
          <Typography variant="h2" className={header.heading}>
            Sign up to SocialXperia
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} >
              <Grid container className="radio-label">
                <Grid xs={12} sm={12} md={6} lg={6} display='flex' alignItems='center'>
                  <input
                    type="radio"
                    id="business"
                    name="fav_language"
                    {...register('userType')}
                    value="BUSINESS"
                    defaultChecked
                    className="radio"
                  />{' '}
                  <label htmlFor="business"
                    className="label">
                    For Businesses
                  </label>{' '}
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} display='flex' alignItems='center'>
                  <input
                    type="radio"
                    id="agencies"
                    name="fav_language"
                    {...register('userType')}
                    value="AGENCIES"
                    className="radio"
                  />{' '}
                  <label htmlFor="agencies" className="label">
                    For Agencies
                  </label>
                </Grid>
              </Grid>
              <Typography className={header.label}>Email address <span className={header.require}>*</span></Typography>
              <CustomTextField
                fieldName="emailId" // Renamed prop from `name` to `fieldName`
                placeholder="Business email addresss "
                classes={inputClass.input}
                {...register('emailId', {
                  required: true,
                })}
              />
              {errors.emailId?.type === 'required' && (
                <Typography className={classes.err}>This field is required</Typography>
              )}
              {/* {errors.emailId?.type === 'pattern' && (
              <Typography className="err_msg">Enter a valid email address</Typography>
            )} */}

              <Typography className={header.label}>Company Name <span className={header.require}>*</span></Typography>
              
              <CustomTextField
                fieldName="companyName" // Renamed prop from `name` to `fieldName`
                placeholder="Company name "
                maxLength={30}
                classes={inputClass.input}
                {...register('companyName', {
                  pattern: /^[a-zA-Z \s]+$/,
                  required: true,
                })}
              />
              {errors.companyName?.type === 'required' && (
                <Typography className={classes.err}>Company name is required</Typography>
              )}
              {errors.companyName?.type === 'pattern' && (
                <Typography className={classes.err}>Company name contains only letters</Typography>
              )}
              <Typography className={header.label}>Password<span className={header.require}>*</span></Typography>
              
              <CustomPassWordTextField

                fieldName="password" // Provide the name prop for registration
                placeholder='password '
                className={inputClass.input1}
                showPassword={showPassword}
                {...register('password', {
                  required: true,
                })}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword} />
              {errors.password?.type === 'required' && (
                <Typography className={classes.err}>Password is required</Typography>
              )}
              <Typography className={header.label}>Phone Number <span className={header.require}>*</span></Typography>
              <ReactPhoneInput
                country={'in'}
                countryCodeEditable={false}
                enableSearch
                disableSearchIcon
                searchStyle={{ margin: '0', width: '97%', height: '30px' }}
                className={inputClass.signUp_phInput}
                placeholder="Enter your phone number"
                inputStyle={{
                  height: 47,
                  width: 550,
                  fontSize: '16px',
                  padding: '15px 0px 10px 70px',
                  borderRadius: '0px',
                  border: 'none',
                }}
                buttonStyle={{
                  borderRadius: '8px 0px 0px 8px',
                  border: 'none',
                  borderRight: '1px solid grey',
                  backgroundColor: '#fff',
                  width: '58px',
                }}
                dropdownStyle={{ width: '380px' }}
                inputProps={{ name: 'phoneNumber', required: true }}
                onChange={onPhoneNumberChange}
              />
              {errors.phone?.type === 'required' && (
                <Typography className={classes.err}>Phone number is required</Typography>
              )}
              {/* {!isValidNumber && <Typography>Please enter a valid phone number.</Typography>} */}
              <input type="hidden" {...register('countryCode')} />
              <label htmlFor="muhRadio1">
                <Box className="termsAndCondition">
                  <Box>
                    <input
                      className="terms"
                      type="checkbox"
                      {...register('isAcceptedTerms', { required: true })}
                    />
                  </Box>
                  <Typography variant='body'   >
                    I agree to
                    <span ><a className={header.terms} href='https://socialxperia.ai/dev/terms/' target="_blank">  Terms of Service</a></span> and{' '}
                    <span ><a className={header.terms} href='https://socialxperia.ai/dev/privacy-policy/' target="_blank">  Privacy Policy</a></span>
                  </Typography>
                </Box>
              </label>
              {errors.isAcceptedTerms && (
                <Typography variant='body'className={classes.err}>
                  Please agree to our Terms of Service & Privacy Policy.
                </Typography>
              )}
              <br />

              <Typography className="data_errr"> {error && error?.message}</Typography>
              <Box>
                {signupLoader ? (
                  <Button sx={{ width: '100%' }}
                  variant='primary'
                  size='large'
                  disableRipple>
                    <Loader color="inherit" value={100} size={25} />
                  </Button>
                ) : (
                  <Button
                    sx={{ width: '100%' }}
                    variant='primary'
                    size='large'
                    disableRipple
                    type="submit"
                  // className={buttonClass.submit_button}
                  >
                    Log In
                  </Button>
                )}
              </Box>

              <Typography
                pb={8}
                className={header.footerHeading}
              >
                Already have an account?{' '}
                <span className="span-1">
                  {' '}
                  <Link to="/sign-in">sign in</Link>
                </span>
              </Typography>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Grid>
  );
}
