import { Grid, Radio, Typography, Box, IconButton, Button } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import logox from '../../../assets/assets/logox.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as api from '../../../redux/Api';
import Loader from '../../../components/loader';
import { setCookie } from '../../../helpers/cookie/cookie';
import { FinalImg, leftSvg } from '../../../assets';
import { useStyles } from '../../../Themes/theme';
import '../signin/signin.css'

function TwoFactorAuth() {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [resetError, setResetError] = useState('');
  const [dataError, setDataErrors] = useState('');
  const [resetLoader, setResetLoader] = useState(false);
  const [val, setVal] = useState(false);
  const [matchPassword, setMatchPassword] = useState(false);
  const [resentSuccessMsg, setResentSuccessMsg] = useState('');
  const [resentOtpLoader, setResentOtpLoader] = useState(false);
  const [resentOtpSuccessMsg, setResentOtpSuccessMsg] = useState('');

  let navigate = useNavigate();
  // on submit Forgot password
  const onSubmit = async (data) => {
    if (data && data?.resendOtp) {
      let updateObj = {
        twoFactorAuthOtp: data.resendOtp,
      };
      setResetLoader(true);
      setResentSuccessMsg('');
      setDataErrors('');
      await api
        .conformTwoFactorAuth(updateObj)
        .then((res) => {
          if (res.status === 200) {
            setResetLoader(false);
            setCookie('token', res.data);
            setResentSuccessMsg('user login succssfully');
            reset();
            navigate('/home');
          }
        })
        .catch((err) => {
          setResetLoader(false);
          if (err.response) {
            setDataErrors(err.response.data);
          } else {
            setDataErrors({ message: 'Network Error' });
          }
        });
    }
  };

  const handleOnChange = (e) => {
    setResentSuccessMsg('');
    setDataErrors('');
    setMatchPassword(false);
    setResentOtpSuccessMsg('');
  };

  const handleResentOtp = async () => {
    setResentOtpLoader(true);
    setResentOtpSuccessMsg('');
    setResetError('');
    try {
      await api.resendTwoFactorAuth().then((res) => {
        setResentOtpSuccessMsg('OTP sent successfully.');
        setResentOtpLoader(false);
      });
    } catch (err) {
      setResentOtpLoader(false);
      if (err.response) {
        setResetError(err.response.data);
      } else {
        setResetError({ message: 'Network Error' });
      }
    }
  };

  return (
    <>
      <Grid container spacing={0} display='flex' flexDirection='row' height='100vh'>
        <Grid item
          xs={7}
          md={7}
          lg={7}
          flex={1}
          className={classes.leftGrid}
        >
          <img src={FinalImg} alt="left" className={classes.leftImage} />
        </Grid>
        <Grid
          item
          xs={5}
          md={5}
          lg={5}
          flex={1}
          className={classes.rightGrid}
        >
          <div>
            <img src={logox} alt="logo" className={classes.logo} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Typography
                  variant="h2"
                  sx={{ marginBottom: 2, marginTop: 2 }}
                  fontWeight={700}
                >
                  Confirm Two Factor <br/>Authentication
                </Typography>
                <Typography
                  variant="h5"
                  component="h6"
                  sx={{ fontWeight: 100 }}
                >
                  Enter OTP sent to your email or Phone number
                </Typography>
                <input
                  placeholder=" Enter OTP"
                  className={classes.input}
                  {...register('resendOtp', { required: true })}
                />
                <br />
                <Grid
                  onClick={() => handleResentOtp()}
                  className="resend_otp_link"
                >
                  Resend OTP
                </Grid>
                <br />
                {errors.resendOtp?.type === 'required' && (
                  <p className="err_msg">This field is required</p>
                )}
                {resentOtpLoader ? (
                  <Box className="resent_loader">
                    <Loader color="blue" value={100} size={20} />
                  </Box>
                ) : (
                  <Button type="submit" className={classes.submit_button}>
                    Confirm OTP
                  </Button>
                )}
              </div>
            </form>
            <Typography variant="h6" sx={{ marginTop: 1 }}>
              Take me back to
              <Link to="/sign-in"> log in</Link>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}


export default TwoFactorAuth;
