import React, { useState, useMemo } from 'react';
import { Avatar, Box, Button, Grid, TextField, Typography } from '@mui/material';
import './personalForm.css';
import { useForm } from 'react-hook-form';
import Loader from '../../../../components/loader';
import { resentOtp, getAccountSettingsApi } from '../../../../redux/Api/index';
import {
  handleAccountSettingsLoader,
  handleAccountSettingsData,
} from '../../../../redux/Slice/user';
import { useSelector, useDispatch } from 'react-redux';
import * as api from '../../../../redux/Api/index';
import HandleDeleteDialog from '../handleDeleteDialog';
import UpdateMobileForm from '../updateMobile';
import UpdateEmailForm from '../updateEmail';
import { useStyles } from '../../../../Themes/theme';
import { buttonStyles } from '../../../../Themes/buttonTheme';
function PersonalForm({
  personalData,
  companyName,
  isVerifyEmailMobileOtp,
  accountSettingsLoader,
  fetchAccountSettingsData,
  signedUserLoader,
  signedUserData,
}) {
  const classes = useStyles();
  const buttons = buttonStyles();
  //local sates
  const [selectedImage, setSelectedImage] = useState(null);
  const [personalInfo, setPersonalInfo] = useState(false)
  const [imageUrl, setImageUrl] = useState(
    personalData && personalData?.profileImage
  );
  const [formData, setFormData] = useState(personalData ? personalData : {});
  const [updateLoader, setUpdateLoader] = React.useState(false);
  const [updateError, setUpdateError] = React.useState('');
  const [updateSuccess, setUpdateSuccess] = React.useState('');
  const [settingsError, setSettingsError] = React.useState('');
  // const [open, setOpen] = React.useState(false);
  const [dopen, setDopen] = React.useState(false);

  //redux states
  const { accountSettingsData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useMemo(() => {
    setFormData(personalData ? personalData : {});
    setImageUrl(personalData && personalData?.profileImage);
  }, [personalData]);

  // Form handlers
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();

  // Profile Image onchange
  const handleprofileImgUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        if (width >= 300 || height >= 300) {
          setError('profileImage', {
            type: 'filetype',
            message: 'Image dimensions should be less than 250px x 250px.',
          });
        } else if (file && file.size > 1000000) {
          setError('profileImage', {
            type: 'filetype',
            message: 'Image size should not exceed 1MB.',
          });
        } else {
          if (personalData?.profileImage) {
            api.s3ImageDelete(personalData?.profileImage)
          }
          api.s3ImageUpload({ imageurl: reader.result }).then((res) => {
            setImageUrl(res.data);
            setError('profileImage', {
              type: 'filetype',
              message: '',
            });
          });
        }
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  // Form On submit handler
  const onSubmit = async (data) => {
    // formatting data into required format
    console.log("data", data)
    let updatedObj = {
      ...data,
      profileImage: imageUrl,
    };
    // Update Account Settings API
    await api
      .updateAccountSettingsApi(updatedObj)
      .then((res) => {
        if (res.status === 200) {
          setUpdateLoader(false);
          setUpdateSuccess('Account Information Updated Successfully.');
          //fetching updated Account Settings data to display
          fetchAccountSettingsData();
        }
      })
      .catch((err) => {
        setUpdateLoader(false);
        if (err.response) {
          setUpdateError(err.response?.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      });
  };

  const handleCompanyChange = (e) => {
    console.log('eeeeeeeeeee', e.target.value);
  };
  const handleInputNumberChange = (e) => {
    console.log('eeeeeeeeeee', e.target.value);
    setFormData({ ...formData, phone_number: e.target.value });
  };

  const handleResentOtp = async () => {
    const email =
      signedUserData && signedUserData?.email ? signedUserData?.email : '';
    const result = await resentOtp({ email: email });
  };
  // const handleValidateOtp = async () => {};
  // const handleValidateOtpOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const handleChangeInput = (e) => {
    console.log("e.target.value", e.target.value)
    if (e.target.value) {
      setPersonalInfo(true)
    } else {
      setPersonalInfo(false)
    }
    // setPersonalInfo(...personalInfo,{[e.target.name]:e.target.value})
  }
  const handleDeleteDialogOpen = () => {
    setDopen(true);
  };
  const handleDeleteDialogClose = () => {
    setDopen(false);
  };
  return (
    <>
      <div className="personal-info1">
        {accountSettingsLoader ? (
          <Box className="loader_div">
            <Loader color="inherit" value={100} size={40} />
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Grid sx={{
              p: { xs: '16px 8px', md: '32px' },
              ml: { xs: '16px', md: '35px' },
              mr: { xs: '16px' },
              mt: '16px',
              mb: '16px',
              width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
            }}
              fullWidth
              bgcolor="#ffffff"
              borderRadius="16px">
              <form onSubmit={handleSubmit(onSubmit)} >
                <ul className="personal_info-ul">
                  <li>
                    <Typography
                      display="flex"
                      variant="h6"
                      className="label-name"
                    >
                      Company Name<span className='red'>*</span>
                    </Typography>
                    <TextField
                      className="form-input"
                      inputProps={{
                        style: { fontSize: 16, padding: '12px 16px' },
                      }}
                      defaultValue={companyName}
                      placeholder="Eg:Tata motors"
                      // disabled={true}
                      name="companyName"
                      {...register('companyName', {
                        required: true,
                        maxLength: 80,
                      })}
                      onChange={handleChangeInput}
                      error={!!errors.companyName}
                    />
                    {errors.companyName?.type === 'required' && (
                      <p className="form_err_msg">Company Name is required</p>
                    )}
                  </li>
                  <li className="personal_info-li">
                    <Typography
                      display="flex"
                      variant="h6"
                      className="upload-label"
                    >
                      Profile Image
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="start"
                      justifyContent="left"
                      mb={3}
                    >
                      <Box>
                        {imageUrl ? (
                          <Avatar
                            src={imageUrl}
                            alt="logo"
                            className="avatar"
                          />
                        ) : (
                          <Avatar title="profile_image" className="avatar" />
                        )}
                      </Box>
                      <Box>
                        <Button
                          component="label"
                          display="block"
                          className="Upload_btn"
                          sx={{ height: 32 }}
                        >
                          Upload Picture
                          <input
                            accept="image/*"
                            type="file"
                            id="select-image"
                            style={{ display: 'none' }}
                            // onChange={(e) => setSelectedImage(e.target.files[0])}
                            // onChange={(e) => handleprofileImgUpload(e)}
                            {...register('profileImage', {
                              onChange: (e) => handleprofileImgUpload(e),
                            })}
                          />
                        </Button>
                        <Typography variant="body" mt={0} display="flex" ml={2}>
                          Recommended picture size : 250x250px
                        </Typography>
                      </Box>
                    </Box>
                    {errors.profileImage && (
                      <p className="err_message">{errors.profileImage?.message}</p>
                    )}
                  </li>
                </ul>
                <ul className="personal_info-ul">
                  <li>
                    {' '}
                    <Typography
                      display="flex"
                      variant="h5"
                      className="label-name"
                    >
                      First Name <span className='red'>*</span>
                    </Typography>
                    <TextField
                      className="form-input"
                      placeholder="First name"
                      inputProps={{
                        style: { fontSize: 16, padding: '12px 16px' },
                      }}
                      defaultValue={personalData && personalData?.firstName}
                      name="firstName"
                      {...register('firstName', {
                        required: true,
                        maxLength: 80,
                      })}
                      error={!!errors.firstName}
                      onChange={handleChangeInput}
                    />
                    {errors.firstName?.type === 'required' && (
                      <p className="form_err_msg">First Name is required</p>
                    )}
                  </li>
                  <li className="personal_info-li">
                    <Typography
                      display="flex"
                      variant="h5"
                      className="label-name2"
                    >
                      Last Name <span className='red'>*</span>
                    </Typography>
                    <TextField
                      name="lastName"
                      className="form-input2"
                      placeholder="Last name"
                      inputProps={{
                        style: { fontSize: 16, padding: '12px 16px' },
                      }}
                      defaultValue={personalData && personalData?.lastName}
                      {...register('lastName', { required: true, maxLength: 80 })}
                      error={!!errors.lastName}
                      onChange={handleChangeInput}
                    />
                    {errors.lastName?.type === 'required' && (
                      <p className="form_err_msg_last_name">
                        Last Name is required
                      </p>
                    )}
                  </li>
                </ul>
                {updateError && (
                  <p className="data_err">
                    {updateError && updateError?.message}
                  </p>
                )}

                {updateSuccess && (
                  <p className="success_msg">{updateSuccess && updateSuccess}</p>
                )}
                {personalInfo ?
                  <Grid>
                    {updateLoader ? (
                      <button className={classes.Small_sized_Btn}>
                        <Loader color="inherit" value={100} size={25} />
                      </button>
                    ) : (

                      <Button type="submit" variant="contained" sx={{ mt: -3, mb: 2 }} className={buttons.Blue_color_Btn}>
                        UPDATE PROFILE
                      </Button>

                    )}
                  </Grid>

                  :
                  <Grid>
                    <Button type="submit" variant="contained" sx={{ mt: -3, mb: 2 }} className={buttons.Blue_color_disabled_Btn}>
                      UPDATE PROFILE
                    </Button>
                  </Grid>
                }

              </form>
            </Grid>
            <Grid sx={{
              p: { xs: '16px 8px', md: '32px' },
              ml: { xs: '16px', md: '35px' },
              mr: { xs: '16px' },
              mt: '16px',
              mb: '16px',
              width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
            }}
              fullWidth
              bgcolor="#ffffff"
              borderRadius="16px">
              <UpdateEmailForm
                personalData={personalData}
                fetchAccountSettingsData={fetchAccountSettingsData}
                isVerifyEmailMobileOtp={isVerifyEmailMobileOtp}
              />
            </Grid>
            <Grid sx={{
              p: { xs: '16px 8px', md: '32px' },
              ml: { xs: '16px', md: '35px' },
              mr: { xs: '16px' },
              mt: '16px',
              mb: '16px',
              width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
            }}
              fullWidth
              bgcolor="#ffffff"
              borderRadius="16px">
              <UpdateMobileForm
                personalData={personalData}
                fetchAccountSettingsData={fetchAccountSettingsData}
                isVerifyEmailMobileOtp={isVerifyEmailMobileOtp}
              />
            </Grid>
          </Box>
        )}
      </div>
      <div className="personal-info-deleteAccount">
        <p className="personal-info-deleteAccount-para">
          Close this account and erase all my data from Socialxperia (posts,
          drafts, reports, etc.). This will delete your account permanently.
        </p>
        <Button
          className={classes.Red_color_Btn}
          onClick={handleDeleteDialogOpen}
          variant="contained"
        >
          Delete my Account
        </Button>
      </div>
      <HandleDeleteDialog
        open={dopen}
        // onClick={handleDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        variant="h5"
      />
    </>
  );
}

export default PersonalForm;
