import { Grid, Radio, Typography, Box, IconButton, Button } from '@mui/material';
import React, { useState } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import './forgotPassword.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as api from '../../../redux/Api';
import Loader from '../../../components/loader';
import { makeStyles } from '@mui/styles';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FinalImg } from '../../../assets';
import { useStyles } from '../../../Themes/theme';
import CustomTextField from '../../../components/customTextField';
import CustomPassWordTextField from '../../../components/customPasswordTextField';



function ResetPassword() {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const methods = useForm();
  const [resetError, setResetError] = useState('');
  const [dataError, setDataErrors] = useState('');
  const [resetLoader, setResetLoader] = useState(false);
  const [val, setVal] = useState(false);
  const [matchPassword, setMatchPassword] = useState(false);
  const [resentSuccessMsg, setResentSuccessMsg] = useState('');
  const [resentOtpLoader, setResentOtpLoader] = useState(false);
  const [resentOtpSuccessMsg, setResentOtpSuccessMsg] = useState('');

  const [show, setShow] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  let navigate = useNavigate();
  // on submit Forgot password
  const onSubmit = async (data) => {
    var isEmailOtp = getCookie('forgotPasswordEmailOtp');
    if (data && data?.resendOtp && data?.password && data?.confirmPassword) {
      if (data.password !== data.confirmPassword) {
        setMatchPassword(true);
      } else {
        setMatchPassword(false);

        let updateObj = {
          password: data.password,
          otp: data.resendOtp,
        };
        setResetLoader(true);
        setResentSuccessMsg('');
        setDataErrors('');
        await api
          .resetPassword(updateObj)
          .then((res) => {
            if (res.status === 200) {
              setResetLoader(false);
              setCookie('token', res.data);
              setResentSuccessMsg('Password reset completed successfully.');
              reset();
              navigate('/sign-in');
            }
          })
          .catch((err) => {
            setResetLoader(false);
            if (err.response) {
              setDataErrors(err.response.data);
            } else {
              setDataErrors({ message: 'Network Error' });
            }
          });
      }
    }
  };

  const handleOnChange = (e) => {
    setResentSuccessMsg('');
    setDataErrors('');
    setMatchPassword(false);
    setResentOtpSuccessMsg('');
  };

  const handleResentOtp = async () => {
    let data = getCookie('forgotOtpSentInfo');
    let parsedData = JSON.parse(data);
    setResentOtpLoader(true);
    setResentOtpSuccessMsg('');
    setResetError('');
    try {
      await api.forgotPassword(parsedData).then((res) => {
        setResentOtpSuccessMsg('OTP sent successfully.');
        setResentOtpLoader(false);
      });
    } catch (err) {
      setResentOtpLoader(false);
      if (err.response) {
        setResetError(err.response.data);
      } else {
        setResetError({ message: 'Network Error' });
      }
    }
  };

  return (
    <>
      <Grid container height='100vh'>
        <Grid
          sm={6}
          md={6}
          lg={7}
          className={classes.leftGrid}
        >
          <img
            src={FinalImg}
            alt="leftImage"
            className={classes.leftImage}
          />
        </Grid>
        <Grid
        xs={12}
          sm={6}
          md={6}
          lg={5}
          className={classes.rightGrid}
        >
          <Grid xs={10} sm={9} mt={6}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box >
                  <Typography
                    variant="h2"
                    sx={{ marginBottom: 2, fontWeight: 700 }}
                  >
                    Create new password
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ marginBottom: "32px" }}
                  >
                    Enter OTP sent to your email or Phone number
                  </Typography>
                </Box>
                <Typography variant="h5" >Enter OTP <span className='require'>*</span></Typography>
                <CustomTextField
                  fieldName="resendOtp" // Renamed prop from `name` to `fieldName`
                  placeholder="Enter OTP"
                  {...register('resendOtp', { required: true })}
                />
                {/* <input
                type="number"
                placeholder=" Enter OTP"
                className={classes.input}
                {...register('resendOtp', { required: true })}
              /> */}
                {errors.resendOtp?.type === 'required' && (
                  <p className="passWordErr">This field is required</p>
                )}
                {resentOtpLoader ? (
                  <Box className="resent_loader">
                    <Loader color="blue" value={100} size={20} />
                  </Box>
                ) : (
                  <Box className="resend_div">
                    <Button
                      variant="text"
                      disableRipple
                      marginBottom={3}
                      onClick={() => handleResentOtp()}
                      fontWeight={500}
                    >
                      <span className="resend_otp">Resend OTP</span>
                    </Button>
                    {resentOtpSuccessMsg && (
                      <Typography
                        variant="p"
                        component="h6"
                        className="resend_otp_success"
                        marginBottom={3}
                        marginLeft={2}
                      >
                        {resentOtpSuccessMsg}
                      </Typography>
                    )}
                  </Box>
                )}
                < Typography variant="h5" > New Password<span className='require'>*</span></Typography>
                <CustomPassWordTextField
                  fieldName='password'
                  placeholder="New Password "
                  showPassword={showNewPassword}
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                  {...register('password', {
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                    required: true,
                    onChange: (e) => handleOnChange(e),
                  })}
                />
                {/* <Box className="input-wrapper">
                
                <input
                  type={show ? 'text' : 'password'}
                  placeholder="New Password "
                  maxLength={15}
                  className={classes.input}
                  {...register('password', {
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                    required: true,
                    onChange: (e) => handleOnChange(e),
                  })}
                />
                <IconButton
                  onClick={() => setShow(!show)}
                  className="toggle-icon"
                >
                  {show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Box> */}

                {errors.password?.type === 'required' && (
                  <Typography variant='body1' className="passWordErr">New Password is required</Typography>
                )}
                {errors.password?.type === 'pattern' && (
                  <Typography variant='body1' className="passWordErr">
                    Password should contain atleast 8 charaters with uppercase,
                    lowercase and numbers
                  </Typography>
                )}
                <Typography variant="h5" mt={4}> Confirm Password<span className='require'>*</span></Typography>
                <CustomPassWordTextField
                  fieldName='confirmPassword'
                  placeholder="Confirm Password "
                  showPassword={showConfirmPassword}
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                  {...register('confirmPassword', {
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                    required: true,
                    onChange: (e) => handleOnChange(e),
                  })}
                />
                {/* <Box className="input-wrapper">
                <input
                  type={showReset ? 'text' : 'password'}
                  placeholder="Confirm Password "
                  maxLength={15}
                  className={classes.input}
                  {...register('confirmPassword', {
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                    required: true,
                    onChange: (e) => handleOnChange(e),
                  })}
                />
                <IconButton
                  onClick={() => setShowReset(!showReset)}
                  className="toggle-icon"
                >
                  {showReset ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Box> */}

                {errors.confirmPassword?.type === 'required' && (
                  <Typography variant='body1' className="passWordErr">Confirm Password is required</Typography>
                )}
                {errors.confirmPassword?.type === 'pattern' && (
                  <Typography variant='body1' className="passWordErr">
                    Password should contain atleast 8 charaters with uppercase,
                    lowercase and numbers
                  </Typography>
                )}
                {matchPassword && (
                  <p className="data_err">
                    New Password and Confirm Password should be same
                  </p>
                )}


                {resentSuccessMsg && (
                  <Typography
                    variant="p"
                    component="h6"
                    className="resend_otp_success"
                  >
                    {resentSuccessMsg}
                  </Typography>
                )}
                <p className="data_err"> {dataError && dataError?.message}</p>
                <Box sx={{ mt: 4 }}>
                  {resetLoader ? (
                    <Button
                      sx={{ width: '100%' }}
                      variant='primary'
                      size='large'
                      disableRipple >
                      <Loader color="inherit" value={100} size={25} />
                    </Button>
                  ) : (
                    <Button
                      sx={{ width: '100%' }}
                      variant='primary'
                      size='large'
                      disableRipple
                      type="submit"
                    >
                      Reset your password
                    </Button>
                  )}
                </Box>
              </form>
            </FormProvider>
            <Typography variant="h6" sx={{ marginTop: "24px", textAlign: "center",marginBottom: 4}}>
              Take me back to
              <Link className='resend_otp' to="/sign-in"> log in</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPassword;
