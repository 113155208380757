import axios from 'axios';
import { setCookie, getCookie, removeCookie } from '../cookie/cookie';
const openAuthWindow = async (targetUrl, path) => {
  try {
    const token = getCookie('token')
    const created_brand_id= getCookie("created_brand_id")
    console.log('token ', token);
    console.log('TT:::', token, path);
    window.open(`${targetUrl}?key=${token}&brand_id=${created_brand_id}&path=${path}`, '_self');
  } catch (error) {
    console.log('ERROR:::', error);
  }
};

export default openAuthWindow;
