import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SelectDropDown({
  Placeholder,
  className,
  name,
  control,
  menuList,
  defaultValue
}) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const renderOptions = () => {
    if (Array.isArray(menuList) && typeof menuList[0] !== 'object') {
      // If the options are an array of strings
      return menuList.map((option) => (
        <MenuItem
          key={option}
          value={option}
          style={getStyles(option, personName, theme)}
        >
          <Typography variant='h6'>
          {option}
          </Typography>
        </MenuItem>
      ));
    } else if (Array.isArray(menuList) && typeof menuList[0] === 'object') {
      // console.log('menuList', menuList);
      return menuList.map((option) => (
        <MenuItem
          key={option.name}
          value={option.value}
          // style={getStyles(option.name, personName, theme)}
        >
          {option.name}
        </MenuItem>
      ));
    } else {
      return null;
    }
  };

  return (
    <div>
      <FormControl
        className={className}
        sx={{ borderRadius: 8, mb: 4 }}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          // rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              // labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              displayEmpty
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              // renderValue={(selected) => {
              //   if (selected.length === 0) {
              //     return <Typography variant="h6"color='#818181'>{Placeholder}</Typography>;
              //   }
    
              //   return selected.join(', ');
              // }}
              sx={{ borderRadius: '8px',height:"56px",marginTop: "12px" }}
              inputProps={{ 'aria-label': 'Without label', height:"56px" }}
            >
              <MenuItem disabled value="">
                <Typography variant="h6"color='#818181'>{Placeholder}</Typography>
              </MenuItem>
              {renderOptions()}
              
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
}
