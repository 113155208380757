import React, { useState } from 'react';
import Navigation from '../../pages';
import { Avatar, Box, Button, Divider, Grid, Typography } from '@mui/material';
import * as api from '../../redux/Api';
import { useSelector, useDispatch } from 'react-redux';
import {
  handleCurrentPlanLoader,
  handleCurrentPlanData,
} from '../../redux/Slice/user';
import { setCookie, getCookie, removeCookie } from '../../helpers/cookie/cookie';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { GreetKaroIcon } from '../../assets';

export default function Success() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const handleRedirect = async () => {
    dispatch(handleCurrentPlanLoader(true));
    try {
      await api.getCurrentPlanDetailsApi().then((res) => {
        dispatch(handleCurrentPlanData(res?.data));
        dispatch(handleCurrentPlanLoader(false));
        setCookie('billing_tab_value', 3);
        navigate('/account-settings');
      });
    } catch (err) {
      dispatch(handleCurrentPlanLoader(false));
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };
  return (
    <Box className="main_block">
      <Avatar
        src={GreetKaroIcon}
        variant="square"
        sx={{ height: 200, width: 200 }}
      />
      <Button onClick={handleRedirect} className="goBack">
        Go Back to Billing
      </Button>
    </Box>
  );
}
