import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Divider, Link } from '@mui/material';
import { Box } from '@mui/system';
import SocialsAddedCard from './socialsAddedCard';
import SocialLoginComponent from '../../../components/socialLogin';
import './AddSocialChannel.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateActiveStep } from '../../../redux/Slice/user';
import { makeStyles } from '@mui/styles';
import Loader from '../../../components/loader';
import {
  getAllSocialAccountData,
  getAllBrandData,
  getCurrentPlanDetailsApiData,
  getAllUnselectedSocialAccountData,
  getTempSocialAccountsData,
} from '../../../redux/reduxThunk';
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie/cookie';
import * as api from '../../../redux/Api';
import SelectSocialAccount from '../../../components/addSocials/selectSocials';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import {handleSocialAccountNoData} from "../../../redux/Slice/user";
import {useStyles} from '../../../Themes/theme'
// const useStyles = makeStyles({
//   stepperButton: {
//     height:'48px',
//     padding: '8px 24px',
//     borderRadius: '8px',
//     color: 'white',
//     backgroundColor: 'black',
//     cursor: 'pointer',
//     fontSize: 'large',
//     fontWeight: 'bold',
//   },
//   stepperBackButton: {
//     padding: '10px',
//     color: '#696767 !important',
//     fontSize: 'large',
//   },
// });

function AddSocialChannel({ is_update }) {
  const [updateLoader, setUpdateLoader] = React.useState(false);
  const [updateError, setUpdateError] = React.useState('');
  const [updateSuccess, setUpdateSuccess] = React.useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const created_brand_id = getCookie('created_brand_id');
  // console.log('selectedAccount', selectedAccount);
  const [socialAccountNext, setSocialAccountNext] = useState(false)
  const [socialAccountSave, setSocialAccountSave] = useState(false)
  const [socialAccountNoData, setSocialAccountNoData] = useState(false)
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const classes = useStyles();
  const navigate = useNavigate();
  const {
    activeStep,
    allBrandData,
    userData,
    allSocialAccountData,
    CurrentPlanDetailsData,
    allUnselectedSocialAccountData,
    tempSocialAccountsData,
    tempSocialAccountsLoading,
    tempSocialAccounts,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();
 
  let socialAccounts = params.get("socialAccounts");


  const Number_of_Profiles = 5;
  const handleContinue = async (data) => {
    dispatch(updateActiveStep(parseInt(activeStep) + 1));
  };
  const handleBack = async (data) => {
    dispatch(updateActiveStep(parseInt(activeStep) - 1));
  };

  const createdBrandId = getCookie('created_brand_id');
  let brandId = '';
  if (userData?.accountType === 'SUPER_ADMIN') {
    if (createdBrandId === undefined) {
      brandId =
        allBrandData && allBrandData?.length > 0 && allBrandData[0]?._id;
    } else {
      brandId = createdBrandId;
    }
  } else {
    if (createdBrandId === undefined) {
      brandId =
        allBrandData && allBrandData?.length > 0 && allBrandData[0]?.brandId;
    } else {
      brandId = createdBrandId;
    }
  }

  if (brandId) {
    setCookie('created_brand_id', brandId);
  }

  const socialMediaData = [
    {
      name: 'Facebook Page',
      id: allSocialAccountData?.facebookPage
        ? allSocialAccountData?.facebookPage
        : null,
    },
    {
      name: 'Facebook Group',
      id: allSocialAccountData?.facebookGroup
        ? allSocialAccountData?.facebookGroup
        : null,
    },
    {
      name: 'Instagram',
      id: allSocialAccountData?.instagram
        ? allSocialAccountData?.instagram
        : null,
    },
    {
      name: 'Pinterest',
      id: allSocialAccountData?.pinterest
        ? allSocialAccountData?.pinterest
        : null,
    },
    {
      name: 'Google My Business',
      id: allSocialAccountData?.google
        ? allSocialAccountData?.google
        : null,
    },
    {
      name: 'LinkedIn',
      id: allSocialAccountData?.linkedIn
        ? allSocialAccountData?.linkedIn
        : null,
    },
    {
      name: 'Youtube',
      id: allSocialAccountData?.youtube
        ? allSocialAccountData?.youtube
        : null,
    },
  ];

  let filterSocialMediaData =
    socialMediaData &&
    socialMediaData
      ?.filter((item) => item.id !== null);

  const handleUpdate = async () => {
    // if (!socialAccountNext) {
    await api
      .brandCreationFinalStep({
        onboardingStatus: 'COMPLETED',
        emailId: userData?.emailId,
        socialMediaData: filterSocialMediaData,
      })
      .then((res) => {
        if (res.status === 200) {
          setUpdateLoader(false);
          navigate('/social-account-settings');
          // setUpdateSuccess('Account Information Updated Successfully.');
          dispatch(getCurrentPlanDetailsApiData());
          dispatch(getAllSocialAccountData(created_brand_id));
        }
      })
      .catch((err) => {
        setUpdateLoader(false);
        if (err.response) {
          setUpdateError(err.response?.data);
        } else {
          setUpdateError({ message: 'Network Error' });
        }
      });
    // }
  };
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    dispatch(getAllBrandData(userData?.accountType));
    dispatch(getCurrentPlanDetailsApiData());
    dispatch(getAllSocialAccountData(created_brand_id));
  }, []);
  setCookie(
    'userPlanDetails',
    CurrentPlanDetailsData?.planDetails?.socialAccountLimit
  );
  setCookie('socialAccountCount', allSocialAccountData?.count);

  useEffect(() => {
    dispatch(getTempSocialAccountsData(socialAccounts));
  }, []);

  useEffect(() => {
    brandId =
      allBrandData && allBrandData?.length > 0
        ? allBrandData[0]?._id
        : created_brand_id;
  }, [allBrandData]);

  useEffect(() => {
    if (socialAccounts === null && tempSocialAccountsData?.pagesInfo?.length === 0) {
      setSocialAccountNext(true) // show connected accounts
    } else if (socialAccounts && tempSocialAccountsData?.pagesInfo?.length === 0) {
      setSocialAccountNoData(true)
    } else {
      setSocialAccountNoData(false)
      setSocialAccountNext(false) //show data
    }
  }, [socialAccounts, tempSocialAccountsData]);

  const removeQueryParameter = (key) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    const newUrl = url.toString();
    window.history.pushState({}, '', newUrl);
  };
  const handleSubmit = async () => {
    await api.updateAllUnselectedSocialAccounts({
      id: selectedAccount._id,
      account_type: tempSocialAccountsData?.account_type,
      brand_id: created_brand_id,
      pageId: selectedAccount.pageId,
      pageName: selectedAccount.pageName,
      accessToken: selectedAccount.accessToken,
      profileImage: selectedAccount.profileImage
    }).then((res) => {
      if (res.status === 200) {
        dispatch(getAllSocialAccountData(created_brand_id))
        setSocialAccountNext(true)
        setSocialAccountSave(true)
        dispatch(handleSocialAccountNoData(false))
        removeQueryParameter('socialAccounts');
        // dispatch(getTempSocialAccountsData(""))
      }
    }).catch((err) => {
      setSocialAccountNext(false)
      setSocialAccountSave(false)
      if (err.response) {
        setUpdateError(err.response?.data);
      } else {
        setUpdateError({ message: 'Network Error' });
      }
    })
  };
  const handleNoData = () => {
    removeCookie('selectedSocialAccount');
    removeQueryParameter('socialAccounts');
    setSocialAccountNoData(false)
    setSocialAccountNext(true)
    dispatch(getAllSocialAccountData(created_brand_id))
    dispatch(handleSocialAccountNoData(false))
  }
 
  const backButton = allBrandData && allBrandData?.length > 0;
  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Grid xs={12} sm={7} md={7} lg={7.5} xl={7} p='35px 20px 0px 20px'>
          <Grid m={2}>
            <Typography variant="h3" textAlign="start" fontWeight={600} m={1}>
              Add Your Social Profiles
            </Typography>
            <Typography
              variant="h5"
              display="flex"
              alignItems="start"
              justifyContent="center"
              m={1}
            >
              One Brand (each of facebook, Intagram, Linkedin, Pinterest,
              Youtube and Google my Business)
            </Typography>

            <Grid display="flex" alignItems="center" mb={6} mt={4}>
              <SocialLoginComponent />
            </Grid>
            {updateError && (
              <p className="data_err">{updateError && updateError?.message}</p>
            )}

            {updateSuccess && (
              <p className="success_msg">{updateSuccess && updateSuccess}</p>
            )}
            <Grid display="flex" justifyContent="flex-end">
              {!is_update || is_update === undefined ? (
                <>
                  {allSocialAccountData?.count > 0 ? (
                    <button
                      onClick={handleContinue}
                      className={classes.Blue_color_Btn}
                    >
                      Continue
                    </button>
                  ) : null}
                </>
              ) : null}
            </Grid>
            {is_update && socialAccountSave ? (
              <Grid display="flex" justifyContent="end">
                {updateLoader ? (
                  <button className="mail_btn">
                    <Loader color="inherit" value={100} size={25} />
                  </button>
                ) : (
                  <button
                    onClick={handleUpdate}
                    className={classes.Blue_color_Btn}
                  >
                    Save
                  </button>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid />

        <Divider orientation="vertical" flexItem />

        <Grid
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          className="Add_socials_right"
          p='25px 25px 0px 20px  '
        >
          <Grid display="flex" justifyContent="flex-end"  >
            {!is_update ? (
              <Typography
                variant="h6"
                display="flex"
                justifyContent="flex-end"
                textAlign="flex-end"
              >
                Need help?
                {/* <Link> </Link> */}
                <a className="link" href="mailto:example@gmail.com">
                  Contact Us
                </a>
              </Typography>
            ) : null}
          </Grid>
          <Grid container mt={4} mb={4}>
            <Grid container display="flex" justifyContent="start">
              <Typography variant="h4" fontWeight={500} m='0px '>
                Connected Social Profiles
              </Typography>
              <Typography variant="h5" m='4px 0px 8px'>
                You have connected the following social profiles:
              </Typography>
            </Grid>
          </Grid>

          {tempSocialAccountsLoading ? (
            <Loader color="inherit" value={100} size={25} />
          ) : (
            <Grid m={1} mr={{ xs: 2, sm: 4, md: 2 }}>
              {socialAccountNext ? (
                <Grid m={1} mr={{ xs: 2, sm: 4, md: 2 }} height={300}>
                  <SocialsAddedCard />
                </Grid>
              ) : !socialAccountNoData && tempSocialAccountsData?.pagesInfo?.length > 0 ? (
                <>
                  <Grid sx={{ overflowY: 'scroll' }} height={300}>
                    <SelectSocialAccount
                      selectedAccount={selectedAccount}
                      setSelectedAccount={setSelectedAccount}
                    />
                  </Grid>
                  <Grid display='flex' justifyContent='center' alignItems='center'>
                  <Button
                    sx={{
                      padding: '10px 30px 10px 30px',
                      fontSize: '16px',
                      marginTop: '48px',
                    }}
                    variant="contained"
                    className={`${selectedAccount ? classes.Blue_color_Btn:
                  classes.Blue_color_disabled_Btn}`}
                    
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  </Grid>
                  
                </>
              ) : (
                <>
                  <Typography variant="h5">
                    Sorry, there are no accounts present at the moment. Please
                    click on the
                    <Button onClick={handleNoData}>Continue</Button> to added
                    for other accounts
                  </Typography>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default AddSocialChannel;
